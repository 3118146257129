import { authGet } from "../client";
import { Floor } from "../models";


type FloorResponse = {
  floor: Floor;
};

export async function getFloor(id: string) {
  const { data }: { data: FloorResponse } = await authGet(`floors/${id}`);
  return data.floor;
}
