export type Permission = {
  id: string
  role: string
  userId: string
  organizationId?: string
  facilityId?: string
};

export const PREDEFINED_PERMISSIONS: Permission[] = [
  {
    userId: 'USR_DEV', organizationId: 'ORG_SPACE_X', role: 'ADMIN'
  },
  {
    userId: 'USR_DEV', organizationId: 'ORG_TESLA', role: 'ADMIN'
  },
  {
    userId: 'USR_DEV', organizationId: 'ORG_LIGHTYEAR', role: 'ADMIN'
  },
  {
    userId: 'USR_TESLA', organizationId: 'ORG_TESLA', role: 'ADMIN'
  },
  {
    userId: 'USR_SPACE_X', organizationId: 'ORG_SPACE_X', role: 'ADMIN'
  },
  {
    userId: 'USR_SPACE_X_MGR', facilityId: 'FAC_ID_SOUTH', role: 'MANAGER'
  },
  {
    userId: 'USR_SPACE_X_MGR', facilityId: 'FAC_ID_NORTH', role: 'MANAGER'
  },
  {
    userId: 'USR_SPACE_X_OPR', facilityId: 'FAC_ID_SOUTH', role: 'OPERATOR'
  },
  {
    userId: 'USR_SPACE_X_OPR', facilityId: 'FAC_ID_NORTH', role: 'OPERATOR'
  },
  // TODO: refactor for use in other seed files
].map((data: any, index) => data.id ? data : {...data, id: `PER_ID_${index}`});
