import { Dispatch } from "redux";
import { authGet,  authPut, authDelete,authorization } from "./client";
import axios from "axios";
import { mapsActions } from "./mapsSlice";
import processError from "./processError";
import { Map, MapIncluded, Pagination } from "./models";
import { assignIncludedSpaces } from "./assignIncluded";

type MapsResponse = {
  maps: Map[];
  pagination: Pagination;
};

type MapResponse = {
  map: Map;
  included?: MapIncluded;
};

type SaveData = {
  [key: string]: any;
  id?: string;
  name: string;
  resolution: number;
  width: number;
  height: number;
  type: number;
  spaceId: string;
  transformMatrix?: number[];
  inverseMatrix?: number[];
  file: any;
};

export const index = (pageIndex: number, pageSize: number | undefined, spaceId: string, search?: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(mapsActions.loading());
    const { data }: { data: MapsResponse } = await authGet("maps", { spaceId, pageIndex, pageSize, search });
    dispatch(mapsActions.index({ maps: data.maps, pagination: data.pagination }));
  } catch (error: any) {
    processError(error, dispatch, mapsActions);
  } finally {
    dispatch(mapsActions.loaded());
  }
};

type viewOpt = { include?: string };
export const view =
  (id: string, { include }: viewOpt, onSuccess?: (map: Map) => void) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(mapsActions.loading());
      const { data }: { data: MapResponse } = await authGet(`maps/${id}`, { include });
      const { map, included } = data;
      dispatch(mapsActions.view(assignIncluded(map, included)));

      onSuccess && onSuccess(map);
    } catch (error: any) {
      processError(error, dispatch, mapsActions);
    } finally {
      dispatch(mapsActions.loaded());
    }
  };

function toTitleCase(str: string): string {
    return str.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}
export const save = (map: SaveData, onSuccess: (map: Map) => void,onProgress?: (percentage: number) => void) => async (dispatch: Dispatch) => {
  try {
    console.log(map)
    var client = axios.create({
      baseURL: `${process.env.REACT_APP_API_BACKEND}/`,
      headers: { "Content-Type": "multipart/form-data", accept: "*/*" },
      onUploadProgress: (event: ProgressEvent) => {
        const progress = Math.round((event.loaded / event.total) * 100);
        onProgress && onProgress(progress);
      },
    });
    if (map.transformMatrix?.some(element => element === undefined)) {
      map.transformMatrix=undefined
    }
    if (map.inverseMatrix?.some(element => element === undefined)) {
      map.inverseMatrix=undefined
    }

    const formData = new FormData();
    map.transformMatrix?.forEach((item, index) => {
      formData.append(`Map.TransformMatrix[${index}]`,`${item}`)
    })

    map.inverseMatrix?.forEach((item, index) => {
      formData.append(`Map.InverseMatrix[${index}]`,`${item}`)
    })

    for (const key in map) {
      if (map.hasOwnProperty(key)) {
        if (map[key] !== undefined) {
          if (!Array.isArray(map[key])) {
            formData.append(`Map.${toTitleCase(key)}`, map[key]);    
          }
        }
      }
    }

    if (map.file !== undefined) {
      formData.append("file", map.file.file);  
    }


    dispatch(mapsActions.loading());
    const { id } = map;
    const { data }: { data: MapResponse } = id
        ? await client.put(`maps/${id}`, formData, { ...authorization() })
        : await client.post(`maps`, formData, { ...authorization() });

    onSuccess(data.map);
  } catch (error: any) {
    processError(error, dispatch, mapsActions);
  } finally {
    dispatch(mapsActions.loaded());
  }
};

export const destroy = (id: string, onSuccess?: () => void) => async (dispatch: Dispatch) => {
  try {
    dispatch(mapsActions.loading());
    await authDelete(`maps/${id}`);

    onSuccess && onSuccess();
  } catch (error: any) {
    processError(error, dispatch, mapsActions);
  } finally {
    dispatch(mapsActions.loaded());
  }
};

function assignIncluded(map: Map, included?: MapIncluded): Map {
  assignIncludedSpaces(map, included);
  return map;
}

export const setCurrent = (id: string, onSuccess: () => void) => async (dispatch: Dispatch) => {
  try {
    dispatch(mapsActions.loading());
    await authPut(`maps/${id}/set`, {});
    onSuccess();
  } catch (error: any) {
    processError(error, dispatch, mapsActions);
  } finally {
    dispatch(mapsActions.loaded());
  }
};
