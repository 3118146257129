import { Dispatch } from "redux";
import { authGet, authPatch } from "./client";
import { deviceConfigurationsActions } from "./deviceConfigurationsSlice";
import processError from "./processError";
import { DeviceConfiguration } from "./models";

type DeviceConfigurationsResponse = {
  configurations: DeviceConfiguration[];
};

export const index = (deviceId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(deviceConfigurationsActions.loading());
    const { data }: { data: DeviceConfigurationsResponse } = await authGet(`devices/${deviceId}/configurations`);
    dispatch(deviceConfigurationsActions.index({ deviceConfigurations: data.configurations }));
  } catch (error: any) {
    processError(error, dispatch, deviceConfigurationsActions);
  } finally {
    dispatch(deviceConfigurationsActions.loaded());
  }
};

export const save =
  (deviceId: string, deviceConfigurations: DeviceConfiguration[], onSuccess: (deviceConfiguration: DeviceConfiguration) => void) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(deviceConfigurationsActions.loading());
      const { data }: { data: DeviceConfiguration } = await authPatch(`devices/${deviceId}/configurations`, { configurations: deviceConfigurations });
      onSuccess(data);
    } catch (error: any) {
      processError(error, dispatch, deviceConfigurationsActions);
    } finally {
      dispatch(deviceConfigurationsActions.loaded());
    }
  };
