/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, Empty, Image, Skeleton, Space, Table } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { isSystemAdmin } from "../../lib/permissions";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { AppDispatch, RootState } from "../../store";
import { getCleaningEpisodes } from "../../store/historyActions";
import { HistoryType } from "../../store/models";
import CleaningEpisodes from "./CleaningEpisodes";

type Row = {
  spaceId: string;
  title: string;
  startTimestamp: string;
  endTimestamp: string;
  trajectoryTime: number;
  chargeTime: number;
  drivenDistance: number;
  cleanedArea: number;
  cleanedOverlappingArea: number;
  battery: number;
  heatmapsBase64: string[];
};
type DataSource = Row[];

type HistoryParams = {
  type: HistoryType;
  id: string;
};

const HistorySum = (params: HistoryParams) => {
  const dispatch = useAppDispatch();
  const componentRef = useRef<any>();
  const histories = useAppSelector((state: RootState) => state.deviceHistory.spacesHistory);
  const episodes = useAppSelector((state: RootState) => state.deviceHistory.cleaningEpisodes);
  const loading = useAppSelector((state: RootState) => state.deviceHistory.spacesHistoryLoading);
  const columns = buildColumns(dispatch);
  let dataSource: DataSource = [];
  if (!loading && histories) {
    dataSource = histories.map((space) => {
      return {
        key: space.spaceId,
        title: space.title,
        trajectoryTime: space.trajectoryTime,
        battery: space.batteryDelta,
        chargeTime: space.chargingTime,
        cleanedArea: space.cleanedArea,
        cleanedOverlappingArea: space.cleanedOverlappingArea,
        drivenDistance: space.drivenDistance,
        spaceId: space.spaceId,
        heatmapsBase64: space.heatmapsBase64,
        endTimestamp: space.endTimestamp,
        startTimestamp: space.startTimestamp,
      };
    });
  }
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const getDetailedEpisodes = (startTime: string, endTime: string) => {
    if (params.type === HistoryType.Device) {
      dispatch(getCleaningEpisodes(moment(startTime), moment(endTime), params.id, undefined));
    } else if (params.type === HistoryType.Space) {
      dispatch(getCleaningEpisodes(moment(startTime), moment(endTime), undefined, params.id));
    }
  };

  function buildColumns(dispatch: AppDispatch) {
    return [
      {
        title: "Space",
        dataIndex: "title",
        key: "title",
        visible: true,
        render: (notes: string) => <div className="PreWrap">{notes}</div>,
      },
      {
        title: "Heatmap",
        dataIndex: "heatmapsBase64",
        key: "heatmapsBase64",
        visible: true,
        render: (base64: string[]) => (
          <Space direction="vertical">
            {base64.map((img, i) => {
              return <Image key={`${i}`} width={200} height={200} style={{ objectFit: "contain" }} src={`data:image/png;base64, ${img}`} />;
            })}
          </Space>
        ),
      },
      {
        title: "Start Time",
        dataIndex: "startTimestamp",
        key: "startTimestamp",
        visible: true,
        render: (time: string) => <div className="PreWrap">{dayjs(`${time}`).format("DD.MM.YYYY HH:mm")}</div>,
      },
      {
        title: "End Time",
        dataIndex: "endTimestamp",
        key: "endTimestamp",
        visible: true,
        render: (time: string) => <div className="PreWrap">{dayjs(`${time}`).format("DD.MM.YYYY HH:mm")}</div>,
      },
      {
        title: "Clean Time (HH:MM)",
        dataIndex: "trajectoryTime",
        key: "trajectoryTime",
        visible: true,
        render: (time: number) => <div className="PreWrap">{secondsToHoursMinutes(time / 1000)}</div>,
      },

      {
        title: (
          <div>
            Cleaned area (m<sup>2</sup>)
          </div>
        ),
        dataIndex: "cleanedOverlappingArea",
        key: "cleanedOverlappingArea",
        visible: true,
        render: (area: number) => <div className="PreWrap">{area.toFixed(2)}</div>,
      },
      {
        title: "Actions",
        dataIndex: "spaceId",
        key: "spaceId",
        visible: isSystemAdmin(),
        render: (area: number, row: Row) => (
          <div>
            {" "}
            <Button
              onClick={() => {
                getDetailedEpisodes(row.startTimestamp, row.endTimestamp);
              }}
              type="primary"
            >
              Details
            </Button>
          </div>
        ),
      },
    ];
  }

  function secondsToHoursMinutes(seconds: number): string {
    if (isNaN(seconds) || seconds < 0) {
      throw new Error("Invalid input. Seconds must be a non-negative number.");
    }

    const hours = Math.floor(seconds / 3600); // 3600 seconds in an hour (60 seconds/minute * 60 minutes/hour)
    const remainingSeconds = seconds % 3600;
    const minutes = Math.floor(remainingSeconds / 60);

    const HH = hours.toString().padStart(2, "0");
    const MM = minutes.toString().padStart(2, "0");

    return `${HH}:${MM}`;
  }
  return (
    <>
      <Card
        style={{ width: "100%" }}
        title="History"
        extra={
          histories &&
          histories.length > 0 && (
            <Button onClick={handlePrint} disabled={loading} type="primary">
              Export to PDF
            </Button>
          )
        }
      >
        <div ref={componentRef}>
          <Table
            dataSource={dataSource}
            columns={columns.filter((x) => x.visible)}
            pagination={false}
            locale={{
              emptyText: loading ? <Skeleton active={true} /> : <Empty />,
            }}
            summary={(pageData) => {
              let totalTrajectoryTime = 0;
              let totalCleanedOverlappingArea = 0;

              pageData.forEach(({ battery, chargeTime, cleanedArea, cleanedOverlappingArea, trajectoryTime, drivenDistance }) => {
                totalTrajectoryTime += trajectoryTime;
                totalCleanedOverlappingArea += cleanedOverlappingArea;
              });

              return (
                <>
                  {pageData.length > 0 && (
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                      <Table.Summary.Cell index={1}></Table.Summary.Cell>
                      <Table.Summary.Cell index={2}></Table.Summary.Cell>
                      <Table.Summary.Cell index={3}></Table.Summary.Cell>
                      <Table.Summary.Cell index={4}>{secondsToHoursMinutes(totalTrajectoryTime / 1000)}</Table.Summary.Cell>
                      <Table.Summary.Cell index={5}>{totalCleanedOverlappingArea.toFixed(2)}</Table.Summary.Cell>
                    </Table.Summary.Row>
                  )}
                </>
              );
            }}
          />
        </div>
      </Card>
      {episodes.length !== 0 && (
        <Card style={{ width: "100%" }} title="Details">
          <CleaningEpisodes />
        </Card>
      )}
    </>
  );
};

export default HistorySum;
