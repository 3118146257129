import _ from "lodash";
import {CleaningSessionsStatus} from "../../store/models";
import {PageHeader, Typography} from "antd";
import {ColumnConfig, Line} from "@ant-design/charts";
import {LineConfig} from "@ant-design/plots/es/components/line";
import {humanizedShortTimestamp} from "../../helpers/formatters";
import {Column} from '@ant-design/plots';

const {Title} = Typography;

function template(): string {
  return `
    <li class="g2-tooltip-list-item" data-index={index}>
      <span class="g2-tooltip-marker" style="background:{color}"></span>
      <span class="g2-tooltip-name">{name}</span>:
      <span class="g2-tooltip-value">{value} minutes, used power {customDataUsedPower} watts</span>
   </li>`;
}

const DurationStats = ({stats}: { stats: CleaningSessionsStatus }) => {
  const data = _.map(stats.data, ({startAt, duration, usedPower}) => {
    return {duration, usedPower, startAt: humanizedShortTimestamp(startAt)}
  });

  const config: LineConfig = {
    data,
    xField: 'startAt',
    yField: 'duration',
    label: {},
    slider: {
      start: 0.01,
      end: 0.99,
    },
    point: {
      size: 5,
      shape: 'circle',
      style: {
        fill: 'white',
        stroke: '#5B8FF9',
        lineWidth: 2,
      },
    },
    tooltip: {
      showMarkers: false,
      // This tooltip template is copied from antd charts and adopted
      itemTpl: template(),
      customItems: (originalItems: any[]) => {
        const transformed = [...originalItems]
        transformed[0].customDataUsedPower = originalItems[0].data.usedPower;
        return transformed;
      },
    },
    interactions: [{type: 'marker-active'}],
  };

  return <>
    <Title level={5}>Cleaing duration[minutes]</Title>
    {<Line {...config}/>}
  </>
}


const PowerUsed = ({stats}: { stats: CleaningSessionsStatus }) => {
  const data = _.map(stats.data, ({startAt, usedPower}) => {
    return {usedPower, startAt: humanizedShortTimestamp(startAt)}
  });
  const config: ColumnConfig = {
    data,
    xField: 'startAt',
    yField: 'usedPower',
    label: {},
    interactions: [{type: 'marker-active'}],
  };

  return <>
    <Title level={5}>Power used[watts]</Title>
    {<Column {...config}/>}
  </>
}

const CleaningSessionsStatsDeviceStatus = ({stats}: { stats: CleaningSessionsStatus }) => {
  return <>
    <PageHeader title="Cleaning sessions statistics"/>
    <DurationStats stats={stats}/>
    <PowerUsed stats={stats}/>
  </>
}

export default CleaningSessionsStatsDeviceStatus;
