import {Link} from "react-router-dom";
import {Facility} from "../../store/models";

type ModelWithFacility = {
  facilityId?: string
  facility?: Facility
}

const FacilityLink = ({model, withModelName}: { model: ModelWithFacility, withModelName?: boolean }) => {
  if (!model.facilityId) {
    return null
  }
  return <Link to={`/facilities/${model.facilityId}`}>
    {withModelName ? 'Facility ' : ''}
    {model.facility?.name ?? model.facilityId}
  </Link>
}

export default FacilityLink;
