import {PageHeader} from "antd";
import FacilityForm from "../../components/facilities/FacilityForm";
import {useNavigate} from "react-router-dom";
import {useAppSelector} from "../../hooks";
import {RootState} from "../../store";
import AuthorizePage from "../../components/authorize/AuthorizePage";
import OrganizationNotSelected from "../../components/OrganizationNotSelected";

const FacilitiesNew = () => {
  const navigate = useNavigate();
  const currentOrganization = useAppSelector((state: RootState) => state.selection.currentOrganization);

  if (!currentOrganization) {
    return <OrganizationNotSelected />;
  }

  return <>
    <PageHeader title="New Facility" onBack={() => navigate('/facilities')}/>
    <FacilityForm/>
  </>;
}

export default () => {
  const currentOrganization = useAppSelector((state: RootState) => state.selection.currentOrganization);
  return <AuthorizePage subject='Facility' parentId={currentOrganization?.id} action='create'>
    <FacilitiesNew/>
  </AuthorizePage>
}

