import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppError, Machine, Pagination} from "./models";

type MachinesState = {
  loading: boolean
  error?: AppError
  list?: Machine[]
  pagination: Pagination
  item?: Machine
}

const initialState: MachinesState = {
  loading: false,
  error: undefined,
  list: undefined,
  item: undefined,
  pagination: {index: 0, total: 0}
}

const machinesSlice = createSlice({
  name: 'machines',
  initialState,
  reducers: {
    loading(state) {
      state.loading = true;
    },
    loaded(state) {
      state.loading = false;
      state.error = undefined;
    },
    error(state, action: PayloadAction<AppError>) {
      state.loading = false;
      state.error = action.payload;
    },
    index(state, action: PayloadAction<{ machines: Machine[], pagination: Pagination }>) {
      state.list = action.payload.machines;
      state.pagination = action.payload.pagination;
    },
    view(state, action: PayloadAction<Machine>) {
      state.item = action.payload;
    },
  }
});

export const machinesActions = machinesSlice.actions;
export default machinesSlice;
