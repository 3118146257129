import Unauthorized from "./Unauthorized";
import {canCreate, canLink, canRead, canUpdate, Subjects} from "../../lib/permissions";
import PageLoading from "../PageLoading";

type Props = {
  subject: Subjects;
  subjectId?: string;
  parentId?: string;
  action: 'read' | 'create' | 'update' | 'link';
  loading?: boolean
  children: JSX.Element;
}
const AuthorizePage = ({subject, subjectId, parentId, action, loading, children}: Props) => {
  if (loading) {
    return <PageLoading/>
  }

  // todo: reuse common code from AuthorizeButton
  const can =
    (action === 'create' && canCreate(subject, parentId)) ||
    (action === 'read' && canRead(subject, subjectId, parentId)) ||
    (action === 'update' && canUpdate(subject, subjectId!, parentId)) ||
    (action === 'link' && canLink(subject, parentId))
  return can ? children : <Unauthorized/>;
}
export default AuthorizePage;
