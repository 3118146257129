import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { RootState } from "../../../store";
import { PageHeader, DatePicker, Button, Space } from "antd";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import AuthorizePage from "../../../components/authorize/AuthorizePage";
import { view } from "../../../store/spacesActions";
import ShowPageError, { isPageError } from "../../../components/ShowPageError";
import HistorySum from "../../../components/history/HistorySum";
import { historyActions } from "../../../store/historySlice";
import { getHistorySum } from "../../../store/historyActions";
import { HistoryType } from "../../../store/models";

const SpaceHistoryIndex = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const space = useAppSelector((state: RootState) => state.spaces.item);

  const [startDateTime, setStartDateTime] = useState(moment().subtract(1, "hour"));
  const [endDateTime, setEndDateTime] = useState(moment().add(1, "hour"));

  const startChanged = (dateString: string) => {
    setStartDateTime(moment(dateString, "DD.MM.YYYY HH:mm"));
  };

  const endChanged = (dateString: string) => {
    setEndDateTime(moment(dateString, "DD.MM.YYYY HH:mm"));
  };

  const loadEpisodes = (start: moment.Moment, end: moment.Moment) => {
    dispatch(historyActions.setCleaningEpisodes([]));
    dispatch(getHistorySum(start, end, undefined, id));
  };

  const getLastMonthPeriod = () => {
    const currentDate = moment();
    const start = currentDate.clone().subtract(1, "month").startOf("month");
    const end = currentDate.clone().subtract(1, "month").endOf("month");

    setStartDateTime(start);
    setEndDateTime(end);
    loadEpisodes(start, end);
  };

  const getCurrentMonthPeriod = () => {
    const currentDate = moment();
    const start = currentDate.clone().startOf("month");
    const end = currentDate.clone().endOf("month");

    setStartDateTime(start);
    setEndDateTime(end);
    loadEpisodes(start, end);
  };

  const getLastWeekPeriod = () => {
    const currentDate = moment();
    const start = currentDate.clone().subtract(1, "week").startOf("week");
    const end = currentDate.clone().subtract(1, "week").endOf("week");

    setStartDateTime(start);
    setEndDateTime(end);
    loadEpisodes(start, end);
  };

  const getThisWeekPeriod = () => {
    const currentDate = moment();
    const start = currentDate.clone().startOf("week");
    const end = currentDate.clone().endOf("week");

    setStartDateTime(start);
    setEndDateTime(end);
    loadEpisodes(start, end);
  };

  const getYesterdayPeriod = () => {
    const currentDate = moment();
    const start = currentDate.clone().subtract(1, "day").startOf("day");
    const end = currentDate.clone().subtract(1, "day").endOf("day");

    setStartDateTime(start);
    setEndDateTime(end);
    loadEpisodes(start, end);
  };

  const getTodayPeriod = () => {
    const currentDate = moment();
    const start = currentDate.clone().startOf("day");
    const end = currentDate.clone().endOf("day");

    setStartDateTime(start);
    setEndDateTime(end);
    loadEpisodes(start, end);
  };

  return (
    <>
      <PageHeader title={`History for ${space?.name}`} onBack={() => navigate(`/spaces/${id}`)} />
      <Space direction="vertical" style={{ width: "100%" }}>
        <Space direction="horizontal">
          Start:{" "}
          <DatePicker
            format="DD.MM.YYYY HH:mm"
            showTime
            value={startDateTime}
            onChange={(value, valueString) => {
              startChanged(valueString);
            }}
            allowClear={false}
          />
          End:{" "}
          <DatePicker
            format="DD.MM.YYYY HH:mm"
            showTime
            value={endDateTime}
            onChange={(value, valueString) => {
              endChanged(valueString);
            }}
            allowClear={false}
          />
          <Button
            type="primary"
            onClick={() => {
              loadEpisodes(startDateTime, endDateTime);
            }}
          >
            {" "}
            Load
          </Button>
        </Space>
        <Space direction="horizontal">
          <Button type="primary" onClick={getLastMonthPeriod}>
            {" "}
            Last Month
          </Button>
          <Button type="primary" onClick={getCurrentMonthPeriod}>
            {" "}
            This Month
          </Button>
          <Button type="primary" onClick={getLastWeekPeriod}>
            {" "}
            Last Week
          </Button>
          <Button type="primary" onClick={getThisWeekPeriod}>
            {" "}
            This Week
          </Button>
          <Button type="primary" onClick={getYesterdayPeriod}>
            {" "}
            Yesterday
          </Button>
          <Button type="primary" onClick={getTodayPeriod}>
            {" "}
            Today
          </Button>
        </Space>
        <HistorySum id={id ?? ""} type={HistoryType.Space} />
      </Space>
    </>
  );
};

export default () => {
  const dispatch = useAppDispatch();

  const { id } = useParams();
  const currentFacility = useAppSelector((state: RootState) => state.selection.currentFacility);
  const space = useAppSelector((state: RootState) => state.spaces.item);
  const loading = useAppSelector((state: RootState) => state.spaces.loading) || !space;
  const error = useAppSelector((state: RootState) => state.devices.error);

  useEffect(() => {
    dispatch(historyActions.setSpaceHistory({ spaces: [] }));
  }, []);

  useEffect(() => {
    dispatch(view(id!, {}));
  }, [id, dispatch]);

  if (isPageError(error)) {
    return <ShowPageError error={error!} />;
  }

  return (
    <AuthorizePage subject="Space" parentId={currentFacility?.id} action="read" loading={loading}>
      <SpaceHistoryIndex />
    </AuthorizePage>
  );
};
