import { Form, Select } from "antd";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { link } from "../../store/devicesActions";
import { RootState } from "../../store";
import { notifySuccess } from "../ui/notifications";
import ModelForm from "../ui/ModelForm";
import { Device } from "../../store/models";
import { useEffect } from "react";
import { index as indexFacilities } from "../../store/facilitiesActions";
import { deviceName } from "../../helpers/formatters";
import { isSystemAdmin } from "../../lib/permissions";

type LinkData = {
  facilityId?: string;
};

const DeviceLinkForm = ({ device }: { device: Device }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading = useAppSelector((state: RootState) => state.devices.loading);
  const error = useAppSelector((state: RootState) => state.devices.error);
  const facilities = useAppSelector((state: RootState) => state.facilities.list);
  const currentOrganization = useAppSelector((state: RootState) => state.selection.currentOrganization);

  useEffect(() => {
    let organizationId = currentOrganization === undefined ? "" : currentOrganization.id;
    if (currentOrganization === undefined && !isSystemAdmin()) {
      navigate("/devices");
      return;
    }
    if (isSystemAdmin()) {
      organizationId = "";
    }
    dispatch(indexFacilities(0, 20, organizationId));
  }, [device, dispatch]);

  const onFinishHandler = (data: LinkData) => {
    var facility = facilities?.find((x) => x.id == data.facilityId);
    if (facility === undefined) {
      return;
    }
    dispatch(
      link(device.id, facility.organizationId, facility.id ?? null, () => {
        const facilityWas = facilities!.find((f) => f.id === device.facilityId);
        navigate(`/devices/${device.id}`);
        const facilityNow = facilities!.find((f) => f.id === data.facilityId);
        const action = data.facilityId ? `linked to facility ${facilityNow!.name}` : `unlinked from facility ${facilityWas?.name ?? ""}`;
        notifySuccess(`Device ${deviceName(device)} is ${action}`);
      })
    );
  };

  return (
    <ModelForm loading={loading} initialValues={{ facilityId: device.facilityId }} onFinish={onFinishHandler} error={error}>
      <Form.Item name="facilityId" label="Facility">
        <Select allowClear showSearch>
          {facilities &&
            facilities.map((facility) => (
              <Select.Option key={facility.id} value={facility.id}>
                {facility.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
    </ModelForm>
  );
};

export default DeviceLinkForm;
