import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppError, Map, Pagination } from "./models";

type MapsState = {
  loading: boolean;
  error?: AppError;
  list?: Map[];
  pagination: Pagination;
  item?: Map;
};

const initialState: MapsState = {
  loading: false,
  error: undefined,
  list: undefined,
  item: undefined,
  pagination: { index: 0, total: 0 },
};

const mapsSlice = createSlice({
  name: "maps",
  initialState,
  reducers: {
    loading(state) {
      state.loading = true;
    },
    loaded(state) {
      state.loading = false;
      state.error = undefined;
    },
    error(state, action: PayloadAction<AppError>) {
      state.loading = false;
      state.error = action.payload;
    },
    index(state, action: PayloadAction<{ maps: Map[]; pagination: Pagination }>) {
      state.list = action.payload.maps;
      state.pagination = action.payload.pagination;
    },
    view(state, action: PayloadAction<Map>) {
      state.item = action.payload;
    },
  },
});

export const mapsActions = mapsSlice.actions;
export default mapsSlice;
