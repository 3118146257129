import { useRef, useState } from "react";
import _ from "lodash";
import L, { Map as LeafletMap } from "leaflet";
import { PageHeader, Select, Typography } from "antd";
import { Device, DeviceTrajectory } from "../../store/models";
import { ImageOverlay, MapContainer, Marker, Popup, Polyline, CircleMarker } from "react-leaflet";
import "./DeviceMap.less";
import { ResizableBox } from "react-resizable";
import { ExpandAltOutlined, LinkOutlined } from "@ant-design/icons";
import { isDevelopment } from "../../development/development";
import PageLoading from "../PageLoading";
import { useAppSelector } from "../../hooks";
import { RootState } from "../../store";
import { humanizedShortTimestamp } from "../../helpers/formatters";
import SearchableSelect from "../ui/SearchableSelect";
import "leaflet/dist/leaflet.css";

const { Paragraph } = Typography;

type Props = {
  device: Device;
};

type ImageDimensions = {
  w: number;
  h: number;
};

const DeviceMap = ({ device }: Props) => {
  const [imageDimensions, setImageDimensions] = useState<undefined | ImageDimensions>();
  const [trajectory, setTrajectory] = useState<undefined | DeviceTrajectory>();
  const deviceTrajectories = useAppSelector((state: RootState) => state.deviceTrajectories.list);

  const onLoad = ({ target: img }: any) => {
    setImageDimensions({ w: img.naturalWidth, h: img.naturalHeight });
  };
  if (!imageDimensions) {
    return (
      <>
        <PageLoading />
        <img onLoad={onLoad} src={device.mapUrl} style={{ display: "none" }} alt="" />
      </>
    );
  }

  const onTrajectoryChange = (trajectoryId: string) => {
    setTrajectory(deviceTrajectories!.find((t) => t.id === trajectoryId));
  };

  return (
    <>
      <PageHeader
        title={
          <>
            Map{" "}
            {device.mapUrl && (
              <a href={device.mapUrl} target="_blank" rel="noopener noreferrer">
                <LinkOutlined />
              </a>
            )}
          </>
        }
      ></PageHeader>
      <Paragraph>
        Trajectory &nbsp;
        <SearchableSelect width={300} placeholder="Select trajectory" onChange={onTrajectoryChange}>
          {deviceTrajectories &&
            deviceTrajectories.map((trajectory) => (
              <Select.Option key={trajectory.id} value={trajectory.id}>
                {`${humanizedShortTimestamp(trajectory.creationTime)} ${trajectory.type}`}
              </Select.Option>
            ))}
        </SearchableSelect>
        <br />
        <br />
        <DeviceMapContent device={device} imageDimensions={imageDimensions} trajectory={trajectory} />
      </Paragraph>
    </>
  );
};

const DeviceMapContent = ({ device, imageDimensions, trajectory }: Props & { imageDimensions: ImageDimensions; trajectory?: DeviceTrajectory }) => {
  const map = useRef<LeafletMap>(null);
  if (!device.mapUrl) {
    return null;
  }
  const debug = isDevelopment();

  const width = imageDimensions.w;
  const height = imageDimensions.h;

  const maxLat = 100;
  const maxLng = maxLat * (width / height);

  const bounds = new L.LatLngBounds({ lat: 0, lng: 0 }, { lat: maxLat, lng: maxLng });
  const outscale = 0.05;
  const maxBounds = new L.LatLngBounds({ lat: -maxLat * outscale, lng: -maxLng * outscale }, { lat: maxLat * (1 + outscale), lng: maxLng * (1 + outscale) });
  const center = { lat: maxLat / 2, lng: maxLng / 2 };

  L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  });

  const sortedPoints = trajectory && _.sortBy(trajectory.points, "index");
  const blue = { color: "blue" };
  const green = { color: "green" };

  const delayedInvalidateSize = _.debounce(() => map.current?.invalidateSize(), 500);

  return (
    <div className="device-map">
      <ResizableBox
        width={500}
        height={300}
        minConstraints={[100, 100]}
        onResize={delayedInvalidateSize}
        resizeHandles={["se"]}
        // wrap in <div> to remove warning:  React does not recognize the `handleAxis` prop on a DOM element.
        handle={
          <div>
            <ExpandAltOutlined rotate={90} className="device-map__resize-icon" />
          </div>
        }
      >
        <MapContainer crs={L.CRS.Simple} ref={map} attributionControl={false} center={center} maxBounds={maxBounds} zoom={2} minZoom={0} maxZoom={10}>
          <ImageOverlay url={device.mapUrl} bounds={bounds} />
          <Marker position={[55, 50]}>
            <Popup>Robot is here</Popup>
          </Marker>

          {sortedPoints && <Polyline pathOptions={blue} positions={sortedPoints.map((p) => [p.y, p.x])} />}

          {sortedPoints &&
            sortedPoints.map((p, index) => (
              <CircleMarker key={index} center={[p.y, p.x]} pathOptions={green} radius={3}>
                <Popup>
                  {index}: [{p.x}, {p.y}], ∠ {p.angle}
                  {p.parameters && `, ${p.parameters}`}
                </Popup>
              </CircleMarker>
            ))}

          {debug && (
            <>
              <Marker position={[0, 0]}>
                <Popup>0, 0</Popup>
              </Marker>
              <Marker position={[maxLat, 0]}>
                <Popup>{maxLat}, 0</Popup>
              </Marker>
              <Marker position={[0, maxLng]}>
                <Popup>0, {maxLng}</Popup>
              </Marker>
              <Marker position={[maxLat, maxLng]}>
                <Popup>
                  {maxLat}, {maxLng}
                </Popup>
              </Marker>
            </>
          )}
        </MapContainer>
      </ResizableBox>
    </div>
  );
};

export default DeviceMap;
