import {PageHeader} from "antd";
import PermissionForm from "../../../components/permissions/PermissionForm";
import {useNavigate, useParams} from "react-router-dom";
import {useAppSelector} from "../../../hooks";
import {RootState} from "../../../store";
import {view as viewUser} from '../../../store/usersActions';
import {useEffect} from "react";
import {useAppDispatch} from "../../../hooks";
import AuthorizePage from "../../../components/authorize/AuthorizePage";

const PermissionsNew = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {userId} = useParams();
  const user = useAppSelector((state: RootState) => state.users.item);
  const userLoading = useAppSelector((state: RootState) => state.permissions.loading);

  useEffect(() => {
    dispatch(viewUser(userId!));
  }, [userId, dispatch]);

  return <>
    <PageHeader title={`New Permission for ${!userLoading && user ? `${user.name} ${user.surname}` : ''}`}
                onBack={() => navigate(`/users/${userId}`)}/>
    <PermissionForm userId={userId!}/>
  </>;
}

export default () => {
  const currentOrganization = useAppSelector((state: RootState) => state.selection.currentOrganization);
  return <AuthorizePage subject='Permission' parentId={currentOrganization?.id} action='create'>
    <PermissionsNew/>
  </AuthorizePage>
}
