import { Facility, Machine, Organization, Floor, Space, Map } from "./models";

export function assignIncludedOrganizations(model: { organizationId?: string; organization?: Organization }, included?: { organizations?: Organization[] }) {
  if (!included || !model.organizationId) {
    return model;
  }
  const organization = included.organizations && included.organizations.find((o) => o.id === model.organizationId);
  model.organization = organization ?? undefined;
  return model;
}

export function assignIncludedFacilities(model: { facilityId?: string; facility?: Facility }, included?: { facilities?: Facility[] }) {
  if (!included || !model.facilityId) {
    return model;
  }
  const facility = included.facilities && included.facilities.find((f) => f.id === model.facilityId);
  model.facility = facility ?? undefined;
  return model;
}

export function assignIncludedFloors(model: { floorId?: string; floor?: Floor }, included?: { floors?: Floor[] }) {
  if (!included || !model.floorId) {
    return model;
  }
  const floor = included.floors && included.floors.find((f) => f.id === model.floorId);
  model.floor = floor ?? undefined;
  return model;
}

export function assignIncludedSpaces(model: { spaceId?: string; space?: Space }, included?: { spaces?: Space[] }) {
  if (!included || !model.spaceId) {
    return model;
  }
  const space = included.spaces && included.spaces.find((f) => f.id === model.spaceId);
  model.space = space ?? undefined;
  return model;
}

export function assignIncludedMap(model: { mapId?: string; map?: Map }, included?: { maps?: Map[] }) {
  if (!included || !model.mapId) {
    return model;
  }
  const map = included.maps && included.maps.find((f) => f.id === model.mapId);
  model.map = map ?? undefined;
  return model;
}

export function assignIncludedCurrentMap(model: { currentMapId?: string; currentMap?: Map }, included?: { maps?: Map[] }) {
  if (!included || !model.currentMapId) {
    return model;
  }
  const map = included.maps && included.maps.find((f) => f.id === model.currentMapId);
  model.currentMap = map ?? undefined;
  return model;
}

export function assignIncludedMachines(model: { machineId?: string; machine?: Machine }, included?: { machines?: Machine[] }) {
  if (!included || !model.machineId) {
    return model;
  }
  const machine = included.machines && included.machines.find((f) => f.id === model.machineId);
  model.machine = machine ?? undefined;
  return model;
}
