import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppError, Space, Pagination } from "./models";

type SpacesState = {
  loading: boolean;
  error?: AppError;
  list?: Space[];
  pagination: Pagination;
  item?: Space;
};

const initialState: SpacesState = {
  loading: false,
  error: undefined,
  list: undefined,
  item: undefined,
  pagination: { index: 0, total: 0 },
};

const spacesSlice = createSlice({
  name: "spaces",
  initialState,
  reducers: {
    loading(state) {
      state.loading = true;
    },
    loaded(state) {
      state.loading = false;
      state.error = undefined;
    },
    error(state, action: PayloadAction<AppError>) {
      state.loading = false;
      state.error = action.payload;
    },
    index(state, action: PayloadAction<{ spaces: Space[]; pagination: Pagination }>) {
      state.list = action.payload.spaces;
      state.pagination = action.payload.pagination;
    },
    view(state, action: PayloadAction<Space>) {
      state.item = action.payload;
    },
  },
});

export const spacesActions = spacesSlice.actions;
export default spacesSlice;
