import {Button, Form, Alert} from "antd";
import "./ModelForm.less";
import {AppError} from "../../store/models";
import {Store} from "rc-field-form/lib/interface";
import {FormInstance} from "antd/lib/form/hooks/useForm";
import MultiLineText from "./MultiLineText";
import {useState} from "react";

const validateMessages = {
  // eslint-disable-next-line no-template-curly-in-string
  required: '${label} is required!',
};

type Props = {
  isNew?: boolean,
  withoutSubmit?: boolean
  loading?: boolean,
  initialValues?: Store,
  onFinish?: (data: any) => void,
  error?: AppError,
  children: React.ReactNode,
  form?: FormInstance,
  autoComplete?: string,
  fullWidthdata?: boolean
}
const ModelForm: React.FC<Props> = (
  {
    isNew, withoutSubmit, initialValues, onFinish, loading, error, children,
    form, autoComplete, fullWidthdata
  }
) => {
  const [exception, setException] = useState(null)
  const labelSpan = fullWidthdata ? 4 : 8;
  const layout = {
    labelCol: {span: labelSpan},
    wrapperCol: {span: fullWidthdata ? undefined : 16},
  };

  const onFinishWithRethrow = (data: any) => {
    try {
      onFinish && onFinish(data);
    } catch (err: any) {
      setException(err)
    }
  }

  if (exception) {
    throw(exception)
  }

  return <Form form={form} {...layout} autoComplete={autoComplete} onFinish={onFinishWithRethrow}
               className={fullWidthdata ? "" : "ModelForm--std"}
               initialValues={initialValues} validateMessages={validateMessages}>
    {children}
    {!withoutSubmit &&
      <Form.Item wrapperCol={{...layout.wrapperCol, offset: labelSpan}}>
        <Button type="primary" htmlType="submit" disabled={loading}>
          {isNew ? 'Create' : 'Update'}
        </Button>
      </Form.Item>
    }
    {error &&
      <Form.Item wrapperCol={{offset: labelSpan}}>
        <Alert message={<MultiLineText text={error.message}/>} type="error"/>
      </Form.Item>
    }
  </Form>
}

export default ModelForm;
