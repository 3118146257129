import store from "../store";
import { Permission, Role } from "../store/models";

export type Subjects =
  | "Organization"
  | "Facility"
  | "Floor"
  | "Space"
  | "User"
  | "Permission"
  | "Machine"
  | "Device"
  | "Map"
  | "File"
  | "DeviceConfiguration"
  | "DeviceStatus"
  | "DeviceHistory"
  | "DeviceCommand";

export function canCreate(subject: string, parentId: string | undefined = undefined) {
  if (isSystemAdmin()) {
    return true;
  }

  switch (subject) {
    case "Organization":
      return false;
    case "Facility":
      return isAdminForOrganization(parentId);
    case "Floor":
      return isAdminForOrganization(parentId);
    case "Space":
      return isAdminForOrganization(parentId);
    case "Map":
      return false;
    case "File":
      return false;
    case "User":
      return false;
    case "Permission":
      return isAdminForOrganization(parentId);
    case "Machine":
      return isManufacturerForAnyMachine();
    case "Device":
      return false;
    case "DeviceConfiguration":
      return isAdminForOrganization(parentId) || isManagerForOrganization(parentId);
    case "DeviceCommand":
      return isAnyForOrganization(parentId);
  }

  throw new Error(`Unprocessed permission for CREATE: ${subject} ${parentId}`);
}

export function canRead(subject: Subjects, subjectId: string | undefined = undefined, parentId: string | undefined = undefined) {
  if (isSystemAdmin()) {
    return true;
  }

  switch (subject) {
    case "Organization":
      if (subjectId) {
        return isAnyForOrganization(subjectId);
      }
      return hasAnyOrganization();
    case "Facility":
      if (parentId) {
        return isAnyForOrganization(parentId);
      }
      if (subjectId) {
        return isAnyForFacility(subjectId);
      }
      return false;
    case "Floor":
      return isOperatorForFacility(parentId);
    case "Space":
      return isOperatorForFacility(parentId);
    case "Map":
      return isOperatorForFacility(parentId);
    case "User":
      return isAdminForOrganization(parentId);
    case "Permission":
      return isAdminForAnyOrganization();
    case "Machine":
      return isAdminForOrganization(parentId);
    case "Device":
      return isAdminForOrganization(parentId);
    case "DeviceConfiguration":
      return isAnyForOrganization(parentId);
    case "DeviceStatus":
      return isAnyForOrganization(parentId);
    case "DeviceHistory":
      return isAnyForOrganization(parentId);
    case "DeviceCommand":
      return isAnyForOrganization(parentId);
  }

  throw new Error(`Unprocessed permission for READ: ${subject} ${subjectId}/${parentId}`);
}

export function canUpdate(subject: string, subjectId: string, parentId?: string) {
  if (isSystemAdmin()) {
    return true;
  }

  switch (subject) {
    case "Organization":
      return isAdminForOrganization(subjectId);
    case "Facility":
      return isAdminForOrganization(parentId) || isManagerForFacility(subjectId);
    case "Floor":
      return isAdminForOrganization(parentId) || isManagerForFacility(subjectId);
    case "Space":
      return isAdminForOrganization(parentId) || isManagerForFacility(subjectId);
    case "User":
      return isAdminForAnyOrganization();
    case "Map":
      return false;
    case "File":
      return false;
    case "Machine":
      return isManufacturerForAnyMachine();
    case "Device":
      return false;
    case "DeviceConfiguration":
      // This is simplified permissions, in reality manager should be for facility
      // Let's see, if this will create problems then need to change this
      return isAdminForOrganization(parentId) || isManagerForOrganization(parentId);
  }

  throw new Error(`Unprocessed permission for UPDATE: ${subject} ${subjectId}/${parentId}`);
}

export function canDelete(subject: string, parentId?: string) {
  if (isSystemAdmin()) {
    return true;
  }

  switch (subject) {
    case "Organization":
      return false;
    case "Facility":
      return isAdminForOrganization(parentId);
    case "User":
      return false;
    case "Space":
      return false;
    case "Floor":
      return false;
    case "Map":
      return false;
    case "File":
      return false;
    case "Permission":
      return isAdminForOrganization(parentId);
    case "Machine":
      return isManufacturerForAnyMachine();
    case "Device":
      return false;
    case "DeviceConfiguration":
      return false;
    case "DeviceCommand":
      return isAnyForOrganization(parentId);
  }

  throw new Error(`Unprocessed permission for DELETE: ${subject} /${parentId}`);
}

export function canLink(subject: string, parentId?: string) {
  if (isSystemAdmin()) {
    return true;
  }

  switch (subject) {
    case "Device":
      return isAdminForOrganization(parentId);
  }

  throw new Error(`Unprocessed permission for LINK: ${subject} -/${parentId}`);
}

function isPermission(check: (p: Permission) => boolean) {
  return !!store.getState().auth.permissions.find((p) => check(p));
}

export function getFirstOrganizationId(): string | undefined {
  return store.getState().auth.permissions.find((p) => p.organizationId)?.organizationId;
}

export function getFirstFacilityId(): string | undefined {
  return store.getState().auth.permissions.find((p) => p.facilityId)?.facilityId;
}

// todo: is it ok to export this? maybe create some hook for permissions
// create helpers: AuthorizeBlock, authorizeAction()
export function isSystemAdmin() {
  return isPermission((p) => p.role === Role.SystemAdmin);
}

// todo: is it ok to export this? maybe create some hook for permissions
export function isAdminForOrganization(organizationId?: string) {
  return !!organizationId && isPermission((p) => p.role === Role.Admin && p.organizationId === organizationId);
}

function isAdminForAnyOrganization() {
  return isPermission((p) => p.role === Role.Admin && !!p.organizationId);
}

export function isManagerForOrganization(organizationId?: string) {
  return !!organizationId && isPermission((p) => p.role === Role.Manager && p.organizationId === organizationId);
}

export function isManagerForFacility(facilityId?: string) {
  return !!facilityId && isPermission((p) => p.role === Role.Manager && p.facilityId === facilityId);
}

export function isOperatorForOrganization(organizationId?: string) {
  return !!organizationId && isPermission((p) => p.role === Role.Operator && p.organizationId === organizationId);
}

export function isOperatorForFacility(facilityId?: string) {
  return !!facilityId && isPermission((p) => p.role === Role.Operator && p.facilityId === facilityId);
}

export function isManufacturerForAnyMachine() {
  return isPermission((p) => p.role === Role.Manufacturer);
}

export function isAnyForOrganization(organizationId?: string) {
  return !!organizationId && isPermission((p) => p.organizationId === organizationId);
}

function isAnyForFacility(facilityId: string) {
  return !!facilityId && isPermission((p) => p.facilityId === facilityId);
}

function hasAnyOrganization() {
  return isPermission((p) => !!p.organizationId);
}
