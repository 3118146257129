import { useState } from "react";
import { PageHeader, Skeleton } from "antd";
import { useEffect } from "react";
import { view } from "../../store/floorsActions";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../store";
import AuthorizePage from "../../components/authorize/AuthorizePage";
import ShowPageError, { isPageError } from "../../components/ShowPageError";
import FloorForm from "../../components/floors/FloorForm";
import FacilityNotSelected from "../../components/FacilityNotSelected";

const FloorsEdit = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const floor = useAppSelector((state: RootState) => state.floors.item);
  const error = useAppSelector((state: RootState) => state.floors.error);
  const currentFacility = useAppSelector((state: RootState) => state.selection.currentFacility);
  const [loaded, setLoaded] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    dispatch(view(id!, {}, () => setLoaded(true)));
  }, [id, dispatch]);

  if (!currentFacility) {
    return <FacilityNotSelected />;
  }

  if (isPageError(error)) {
    return <ShowPageError error={error!} />;
  }

  return (
    <>
      <PageHeader title={`Edit Floor ${loaded && floor ? floor.name : ""}`} onBack={() => navigate("/floors")} />
      {loaded ? <FloorForm floor={floor} /> : <Skeleton active={true} />}
    </>
  );
};

export default () => {
  const { id } = useParams();
  const currentFacility = useAppSelector((state: RootState) => state.selection.currentFacility);
  return (
    <AuthorizePage subject="Floor" subjectId={id} parentId={currentFacility?.id} action="update">
      <FloorsEdit />
    </AuthorizePage>
  );
};
