import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { RootState } from "../../store";

import { Skeleton, Space as ASpace, Button, Row, Card, DatePicker } from "antd";
import { Space, Facility, Floor, SpaceDayHistory } from "../../store/models";
import { getOrganizationHistory } from "../../store/historyActions";

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Pie, Bar } from "react-chartjs-2";
import moment from "moment";
import OrganizationNotSelected from "../OrganizationNotSelected";
import SpacesDashboard from "./SpacesDashboard";
import { formatNumberWithSpaces, secondsToHoursMinutes } from "../../helpers/formatters";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

moment.updateLocale("en", {
  week: {
    dow: 1, // Monday is the first day of the week
  },
});

const OrganizationDashboard = () => {
  const [loading, setLoading] = useState(true);

  const [spaceDayReadings, setSpaceDayReadings] = useState<SpaceDayHistory[]>([]);
  const [spaces, setSpaces] = useState<Space[]>([]);
  const [floors, setFloors] = useState<Floor[]>([]);
  const [facilities, setFacilities] = useState<Facility[]>([]);

  const [pageLoading, setPageLoading] = useState(true);

  const [pieChartData, setPieChartData] = useState(null);
  const [barChartData, setBarChartData] = useState(null);

  const [cleanedArea, setCleanedArea] = useState(0);
  const [drivenDistance, setDrivenDistance] = useState(0);
  const [cleanedTime, setCleanedTime] = useState(0);

  const dispatch = useAppDispatch();

  const [startDateTime, setStartDateTime] = useState(moment().subtract(1, "hour"));
  const [endDateTime, setEndDateTime] = useState(moment().add(1, "hour"));

  const currentOrganization = useAppSelector((state: RootState) => state.selection.currentOrganization);

  const startChanged = (dateString: string) => {
    setStartDateTime(moment(dateString, "DD.MM.YYYY HH:mm"));
  };

  const endChanged = (dateString: string) => {
    setEndDateTime(moment(dateString, "DD.MM.YYYY HH:mm"));
  };

  const getLastMonthPeriod = () => {
    const currentDate = moment();
    const start = currentDate.clone().subtract(1, "month").startOf("month");
    const end = currentDate.clone().subtract(1, "month").endOf("month");

    setStartDateTime(start);
    setEndDateTime(end);
    loadHistory(start, end);
  };

  const getCurrentMonthPeriod = () => {
    const currentDate = moment();
    const start = currentDate.clone().startOf("month");
    const end = currentDate.clone().endOf("month");

    setStartDateTime(start);
    setEndDateTime(end);
    loadHistory(start, end);
  };

  const getLastWeekPeriod = () => {
    const currentDate = moment();
    const start = currentDate.clone().subtract(1, "week").startOf("week");
    const end = currentDate.clone().subtract(1, "week").endOf("week");

    setStartDateTime(start);
    setEndDateTime(end);
    loadHistory(start, end);
  };

  const getThisWeekPeriod = () => {
    const currentDate = moment();
    const start = currentDate.clone().startOf("week");
    const end = currentDate.clone().endOf("week");

    setStartDateTime(start);
    setEndDateTime(end);
    loadHistory(start, end);
  };

  const getYesterdayPeriod = () => {
    const currentDate = moment();
    const start = currentDate.clone().subtract(1, "day").startOf("day");
    const end = currentDate.clone().subtract(1, "day").endOf("day");

    setStartDateTime(start);
    setEndDateTime(end);
    loadHistory(start, end);
  };

  const getTodayPeriod = () => {
    const currentDate = moment();
    const start = currentDate.clone().startOf("day");
    const end = currentDate.clone().endOf("day");

    setStartDateTime(start);
    setEndDateTime(end);
    loadHistory(start, end);
  };

  const loadHistory = (start: moment.Moment, end: moment.Moment) => {
    setLoading(true);
    dispatch(
      getOrganizationHistory(start, end, currentOrganization?.id ?? "", (response) => {
        setSpaceDayReadings(response.readings);
        setFloors(response.included.floors);
        setSpaces(response.included.spaces);
        setFacilities(response.included.facilities);
        setLoading(false);
      })
    );
  };

  useEffect(() => {
    if (currentOrganization !== undefined) {
      getTodayPeriod();
    }
  }, [currentOrganization]);

  useEffect(() => {
    const idleTime = spaceDayReadings.reduce((sum, item) => sum + item.idleTime, 0);
    const chargingTime = spaceDayReadings.reduce((sum, item) => sum + item.chargingTime, 0);
    const cleaningTime = spaceDayReadings.reduce((sum, item) => sum + item.cleaningTime, 0);

    const cleanedArea = spaceDayReadings.reduce((sum, item) => sum + item.cleanedAreaOverlapping, 0);
    setCleanedArea(cleanedArea);

    const drivenDIstance = spaceDayReadings.reduce((sum, item) => sum + item.drivenDistance, 0);
    setDrivenDistance(drivenDIstance);

    const cleanedTime = spaceDayReadings.reduce((sum, item) => sum + item.cleaningTime, 0);
    setCleanedTime(cleanedTime);

    const pieLabels = ["Idle Time", "Charging Time", "Cleaning Time"];
    const pieValues = [idleTime / 1000, chargingTime / 1000, cleaningTime / 1000];

    setPieChartData({
      //@ts-ignore
      labels: pieLabels,
      datasets: [
        {
          data: pieValues,
          backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)", "rgba(255, 206, 86, 0.2)"],
          borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)", "rgba(255, 206, 86, 1)"],
          hoverBackgroundColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)", "rgba(255, 206, 86, 1)"],
        },
      ],
    });

    let uniqueDates: string[] = spaceDayReadings
      .map((obj) => obj.date) // extract datetime strings
      .filter((date, index, self) => self.indexOf(date) === index); // filter out duplicates

    uniqueDates = uniqueDates.sort((a, b) => {
      let dateA = new Date(a);
      let dateB = new Date(b);
      return dateA.getTime() - dateB.getTime();
    });
    const labels = uniqueDates.map((x) => {
      return moment(x).format("DD.MM.YYYY");
    });
    let readings: number[] = [];

    uniqueDates.forEach((date) => {
      let dateReadings = spaceDayReadings.filter((x) => x.date === date);
      let cleanedArea = dateReadings.reduce((sum, item) => sum + item.cleanedAreaOverlapping, 0);
      readings.push(cleanedArea);
    });

    setBarChartData({
      //@ts-ignore
      labels: labels,
      datasets: [
        {
          label: "Cleaned Area",
          data: readings,
          borderColor: "rgba(255, 99, 132, 0.2)",
          backgroundColor: "rgba(255, 99, 132, 0.2)",
          stack: "combined",
          type: "bar",
        },
      ],
    });
  }, [spaceDayReadings]);

  useEffect(() => {
    if (facilities === undefined || spaceDayReadings === undefined) {
      return;
    }

    if (facilities.length === 0 || spaceDayReadings.length === 0) {
      return;
    }
  }, [spaceDayReadings, facilities]);

  useEffect(() => {
    setPageLoading(false);
  }, []);

  return (
    <>
      {!pageLoading && (
        <>
          {currentOrganization === undefined && (
            <>
              <OrganizationNotSelected />
            </>
          )}

          {currentOrganization !== undefined && (
            <ASpace direction="vertical" style={{ width: "100%" }}>
              <Card title={`${currentOrganization.name} Dashboard`} bordered={true}>
                <ASpace direction="vertical">
                  <ASpace direction="horizontal">
                    Start:{" "}
                    <DatePicker
                      format="DD.MM.YYYY HH:mm"
                      showTime
                      value={startDateTime}
                      onChange={(value, valueString) => {
                        startChanged(valueString);
                      }}
                      allowClear={false}
                    />
                    End:{" "}
                    <DatePicker
                      format="DD.MM.YYYY HH:mm"
                      showTime
                      value={endDateTime}
                      onChange={(value, valueString) => {
                        endChanged(valueString);
                      }}
                      allowClear={false}
                    />
                    <Button
                      type="primary"
                      disabled={loading}
                      onClick={() => {
                        loadHistory(startDateTime, endDateTime);
                      }}
                    >
                      Load
                    </Button>
                  </ASpace>
                  <ASpace direction="horizontal">
                    <Button type="primary" disabled={loading} onClick={getLastMonthPeriod}>
                      Last Month
                    </Button>
                    <Button type="primary" disabled={loading} onClick={getCurrentMonthPeriod}>
                      This Month
                    </Button>
                    <Button type="primary" disabled={loading} onClick={getLastWeekPeriod}>
                      Last Week
                    </Button>
                    <Button type="primary" disabled={loading} onClick={getThisWeekPeriod}>
                      This Week
                    </Button>
                    <Button type="primary" disabled={loading} onClick={getYesterdayPeriod}>
                      Yesterday
                    </Button>
                    <Button type="primary" disabled={loading} onClick={getTodayPeriod}>
                      Today
                    </Button>
                  </ASpace>
                </ASpace>
              </Card>
              {loading && <Skeleton />}
              {!loading && (
                <>
                  <Row>
                    <ASpace direction="vertical">
                      <Card
                        title={
                          <div>
                            Cleaned Area (m<sup>2</sup>)
                          </div>
                        }
                        bordered={true}
                      >
                        <h2>{`${formatNumberWithSpaces(cleanedArea)}`}</h2>
                      </Card>
                      <Card title="Driven Distance (m) " bordered={true}>
                        <h2>{`${formatNumberWithSpaces(drivenDistance)}`}</h2>
                      </Card>
                      <Card title="Cleaning Time (DD:HH:mm)" bordered={true}>
                        <h2>{secondsToHoursMinutes(cleanedTime / 1000, true)} </h2>
                      </Card>
                    </ASpace>
                    <Card title="Time (DD:HH:mm)" bordered={true} style={{ width: "400px", height: "500px" }}>
                      {pieChartData && (
                        <Pie
                          key={"pieChart"}
                          id="1"
                          data={pieChartData}
                          options={{
                            plugins: {
                              tooltip: {
                                callbacks: {
                                  //@ts-ignore
                                  label: function (tooltipItem, data) {
                                    //@ts-ignore
                                    return secondsToHoursMinutes(tooltipItem.raw, true); // Add your custom conversion here
                                  },
                                },
                              },
                            },
                          }}
                        />
                      )}
                    </Card>
                    <Card
                      title={
                        <div>
                          Cleaned Area (m<sup>2</sup>)
                        </div>
                      }
                      bordered={true}
                      style={{ width: "600px", height: "500px" }}
                    >
                      {barChartData && (
                        <Bar
                          key={"barChart"}
                          data={barChartData}
                          options={{
                            plugins: {
                              legend: {
                                position: "top" as const,
                                display: false,
                              },
                              tooltip: {
                                callbacks: {
                                  //@ts-ignore
                                  label: function (tooltipItem, data) {
                                    //@ts-ignore
                                    return formatNumberWithSpaces(tooltipItem.raw); // Add your custom conversion here
                                  },
                                },
                              },
                            },
                            scales: {
                              y: {
                                title: {
                                  display: true,
                                },
                                min: 0,
                                ticks: {
                                  // forces step size to be 50 units
                                  stepSize: 1000,
                                },
                              },
                            },
                          }}
                        />
                      )}
                    </Card>
                  </Row>
                  <SpacesDashboard readings={spaceDayReadings} spaces={spaces} startDateTime={startDateTime} endDateTime={endDateTime} />
                </>
              )}
            </ASpace>
          )}
        </>
      )}
    </>
  );
};

export default OrganizationDashboard;
