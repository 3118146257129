import {Form, Input} from "antd";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {useNavigate} from "react-router-dom";
import {save} from "../../store/facilitiesActions";
import {RootState} from "../../store";
import {notifySuccess} from "../ui/notifications";
import ModelForm from "../ui/ModelForm";
import {Facility} from "../../store/models";

type FacilityData = {
  name: string
  notes: string
}

const FacilityForm = ({facility}: { facility?: Facility }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate();
  const loading = useAppSelector((state: RootState) => state.facilities.loading);
  const error = useAppSelector((state: RootState) => state.facilities.error);
  const organizationId = useAppSelector((state: RootState) => state.selection.currentOrganization!.id);

  const onFinishHandler = (data: FacilityData) => {
    const action = facility?.id ? 'updated' : 'created';
    dispatch(save({...data, id: facility?.id, organizationId}, (facility) => {
      navigate(`/facilities/${facility.id}`);
      notifySuccess(`Facility ${facility!.name} is ${action}`);
    }));
  };

  const newFacility = !facility?.id
  return <ModelForm isNew={newFacility} loading={loading} initialValues={{...facility}}
                    onFinish={onFinishHandler} error={error}>
    <Form.Item name='name' label="Name" rules={[{required: true}]}>
      <Input/>
    </Form.Item>
    <Form.Item name='notes' label="Notes" rules={[{required: true}]}>
      <Input.TextArea/>
    </Form.Item>
  </ModelForm>
}

export default FacilityForm;
