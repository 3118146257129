import {Form, Input} from "antd";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {useNavigate} from "react-router-dom";
import {updatePassword} from "../../store/meActions";
import {RootState} from "../../store";
import {notifySuccess} from "../ui/notifications";
import ModelForm from "../ui/ModelForm";

type PasswordData = {
  currentPassword: string
  newPassword: string
}

const PasswordForm = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate();
  const loading = useAppSelector((state: RootState) => state.me.loading);
  const error = useAppSelector((state: RootState) => state.me.error);

  const onFinishHandler = ({newPassword, currentPassword}: PasswordData) => {
    dispatch(updatePassword(newPassword, currentPassword, () => {
      navigate(`/`);
      notifySuccess(`Password is updated`);
    }));
  };
  return <ModelForm isNew={false} loading={loading} onFinish={onFinishHandler} error={error}>
    <Form.Item label="Current Password" name="currentPassword" rules={[{required: true}]}>
      <Input.Password/>
    </Form.Item>
    <Form.Item label="New Password" name="newPassword" rules={[{required: true}]}>
      <Input.Password/>
    </Form.Item>
  </ModelForm>
}

export default PasswordForm;
