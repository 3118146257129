import { Form, Input, InputNumber, Select, Row, Col, Button ,Upload, Progress} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { FormListProps } from "antd/lib/form/FormList";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { save } from "../../store/mapsActions";
import { RootState } from "../../store";
import { notifySuccess } from "../ui/notifications";
import ModelForm from "../ui/ModelForm";
import { Map, MapType } from "../../store/models";
import { useState } from "react";

type MapData = {
  name: string;
  resolution: number;
  width: number;
  height: number;
  type: number;
  transformMatrix: number[];
  inverseMatrix: number[];
  file: any
};

const MapForm = ({ map }: { map?: Map }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading = useAppSelector((state: RootState) => state.maps.loading);
  const error = useAppSelector((state: RootState) => state.maps.error);
  const spaceId = useAppSelector((state: RootState) => state.selection.currentSpace!.id);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);


  const onFinishHandler = (data: MapData) => {
    const action = map?.id ? "updated" : "created";
    setProgress(0);
    setUploading(true);

    dispatch(
      save({ ...data, id: map?.id, spaceId }, (map) => {
        navigate(`/maps/${map.id}`);
        notifySuccess(`Map ${map!.name} is ${action}`);
      },
      (percentage) => {
        setProgress(percentage);
      })
    );
  };

  const newMap = !map?.id;
  return (
    <>
     <ModelForm isNew={newMap} loading={loading} initialValues={{ ...map }} onFinish={onFinishHandler} error={error}>
      <Form.Item name="name" label="Name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="type" label="Type" rules={[{ required: true }]}>
        <Select
          options={[
            { value: 1, label: "Kudan" },
            { value: 2, label: "Ivisio" },
            { value: 6, label: "Cartographer" },
          ]}
        />
      </Form.Item>
      
      <Form.Item name="file" label="Zip">
          <Upload multiple={false} maxCount={1} accept=".zip" beforeUpload={() => false}>
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Form.Item>

      <Form.Item name="width" label="Width" >
        <InputNumber step={1} precision={0} />
      </Form.Item>
      <Form.Item name="height" label="Height" >
        <InputNumber step={1} precision={0} />
      </Form.Item>
      <Form.Item name="resolution" label="Resolution" >
        <InputNumber step={0.01} />
      </Form.Item>
      <Form.Item label="Transform Matrix">
        <Row gutter={16}>
          {[...Array(9)].map((_, index) => (
            <Col span={8} key={index}>
              <Form.Item name={["transformMatrix", index]} key={`tm${index}`} >
                <InputNumber placeholder="0" />
              </Form.Item>
            </Col>
          ))}
        </Row>
      </Form.Item>

      <Form.Item label="Inverse Matrix">
        <Row gutter={16}>
          {[...Array(9)].map((_, index) => (
            <Col span={8} key={index}>
              <Form.Item name={["inverseMatrix", index]} key={`im${index}`} >
                <InputNumber placeholder="0" />
              </Form.Item>
            </Col>
          ))}
        </Row>
      </Form.Item>

      {/* <Form.Item name="transformMatrix" label="Transform matrix">
        <InputNumber step={0.01} />
      </Form.Item> */}
    </ModelForm>
    {uploading && <Progress percent={progress} type="circle" size={"default"} style={{ marginLeft: "200px" }} />}
    </>
  );
};

export default MapForm;
