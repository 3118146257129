import {useCallback, useState} from 'react';
import AuthorizePage from "../../components/authorize/AuthorizePage";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {RootState} from "../../store";
import OrganizationNotSelected from "../../components/OrganizationNotSelected";
import {Input, PageHeader, Typography, Form, Alert} from 'antd';
import _ from "lodash";
import {search} from "../../store/usersActions";
import PermissionForm from "../../components/permissions/PermissionForm";
import ModelForm from "../../components/ui/ModelForm";
import {LoadingOutlined,} from '@ant-design/icons';

const {Text, Paragraph} = Typography;

const UsersAdd = () => {
  const dispatch = useAppDispatch();
  const users = useAppSelector((state: RootState) => state.users.list);
  const currentOrganization = useAppSelector((state: RootState) => state.selection.currentOrganization);
  const [firstTime, setFirstTime] = useState(true);
  const [loaded, setLoaded] = useState(false);

  const delayedEmailChange = _.debounce(function (email) {
    dispatch(search({email}, () => {
      setLoaded(true)
    }))
  }, 1000);

  const onEmailChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFirstTime(false)
      setLoaded(false)
      delayedEmailChange(e.target.value)
    },
    // Don't use dependency on delayedEmailChange because we need only one(first) instance of debounce
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  if (!currentOrganization) {
    return <OrganizationNotSelected/>
  }

  const user = (users && users.length > 0) ? users[0] : undefined;

  return <>
    <PageHeader title={`Add existing user to ${currentOrganization!.name}`}/>
    <ModelForm withoutSubmit>
      <Form.Item name="email" label="Email">
        <Input placeholder="" onChange={onEmailChange}/>
      </Form.Item>
    </ModelForm>
    {loaded && user &&
      <>
        <Paragraph>
          <Text>New Permission for <strong>{user.name} {user.surname}</strong></Text>
        </Paragraph>
        <PermissionForm userId={user.id!}/>
      </>
    }
    {loaded && !user &&
      <Alert message="There is no user with this email" type="warning"/>
    }
    {!loaded && !firstTime &&
      <Alert message={<LoadingOutlined/>} type="info"/>
    }
  </>
}

export default () => {
  const currentOrganization = useAppSelector((state: RootState) => state.selection.currentOrganization);

  return <AuthorizePage subject='Permission' action='create' parentId={currentOrganization?.id}>
    <UsersAdd/>
  </AuthorizePage>
}
