import {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {AppDispatch, RootState} from "../../store";
import {index} from '../../store/usersActions';
import {Empty, PageHeader, Skeleton, Space, Table} from "antd";
import {AuditOutlined, LoginOutlined, PlusSquareOutlined} from '@ant-design/icons';
import {Link, useSearchParams} from "react-router-dom";
import {User} from "../../store/models";
import AuthorizePage from "../../components/authorize/AuthorizePage";
import IconButton from "../../components/ui/IconButton";
import {isAdminForOrganization, isSystemAdmin} from "../../lib/permissions";
import Dev from "../../components/development/Dev";
import {execDevLoginAs} from "../../development/development";
import OrganizationNotSelected from "../../components/OrganizationNotSelected";
import FacilityNotSelected from "../../components/FacilityNotSelected";
import CreateButton from "../../components/buttons/CreateButton";
import UpdateButton from "../../components/buttons/UpdateButton";
import ReadButton from "../../components/buttons/ReadButton";

type Row = { id: string, name: string, surname: string, email: string }
type DataSource = Row[]

const PAGE_SIZE = 10;

function buildColumns(dispatch: AppDispatch) {
  return [
    {
      title: 'Name', dataIndex: 'name', key: 'name', width: 200,
      render: (text: string, user: User) => <Link to={user.id}>{text}</Link>,
    },
    {
      title: 'Surname', dataIndex: 'surname', key: 'surname', width: 200,
      render: (text: string, user: User) => <Link to={user.id}>{text}</Link>,
    },
    {
      title: 'Email', dataIndex: 'email', key: 'email',
    },
    {
      title: 'Actions', key: 'action', width: 100,
      render: (_: any, user: User) => (
        <Space size="middle">
          <UpdateButton subject="User" subjectId={user.id} subjectName={`${user.name} ${user.surname}`}
                        routePrefix={`${user.id}/`}/>
          <ReadButton subject="Permission" subjectId={user.id} to={`${user.id}/permissions`} icon={<AuditOutlined/>}/>
          <Dev forIcon>
            <IconButton onClick={() => execDevLoginAs(user, dispatch)} icon={<LoginOutlined/>}
                        title={`Login as ${user.name} ${user.surname}`}/>
          </Dev>
        </Space>
      ),
    },
  ];
}


const UsersIndex = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const users = useAppSelector((state: RootState) => state.users.list);
  const pagination = useAppSelector((state: RootState) => state.users.pagination);
  const loading = useAppSelector((state: RootState) => state.users.loading);
  const [page, setPage] = useState(Number(searchParams.get('page')) || 1)
  const currentOrganization = useAppSelector((state: RootState) => state.selection.currentOrganization);
  const currentFacility = useAppSelector((state: RootState) => state.selection.currentFacility);

  let dataSource: DataSource = [];
  if (!loading && users) {
    dataSource = users.map(({id, name, surname, email}: Row) => {
      return {key: id, id, name, surname, email}
    });
  }

  useEffect(() => {
    if (isSystemAdmin()) {
      dispatch(index(page - 1, PAGE_SIZE));
    } else if (currentOrganization) {
      if (isAdminForOrganization(currentOrganization.id)) {
        dispatch(index(page - 1, PAGE_SIZE, currentOrganization.id));
      } else if (currentFacility) {
        dispatch(index(page - 1, PAGE_SIZE, currentOrganization.id, currentFacility.id));
      }
    }
  }, [page, currentOrganization, currentFacility, dispatch]);

  if (!isSystemAdmin()) {
    if (!currentOrganization) {
      return <OrganizationNotSelected/>
    }
    if (!currentFacility && !isAdminForOrganization(currentOrganization.id)) {
      return <FacilityNotSelected/>
    }
  }

  const pageChangeHandler = (page: number) => {
    setPage(page);
    setSearchParams({page: page.toString()})
  }

  const columns = buildColumns(dispatch);

  return <>
    <PageHeader title={`Users (${pagination.total})`}
                extra={[
                  <CreateButton key="u" subject="User" icon={<PlusSquareOutlined/>}/>,
                  <CreateButton key="p" subject="Permission" parentId={currentOrganization?.id} to='add'
                                title={"Add existing user"}/>,
                ]}
    />

    <Table dataSource={dataSource} columns={columns}
           pagination={{
             current: page,
             total: pagination.total,
             defaultPageSize: PAGE_SIZE,
             onChange: pageChangeHandler,
           }}
           locale={{emptyText: loading ? <Skeleton active={true}/> : <Empty/>}}
    />
  </>
}

export default () => {
  const currentOrganization = useAppSelector((state: RootState) => state.selection.currentOrganization);
  return <AuthorizePage subject='User' parentId={currentOrganization?.id} action='read'>
    <UsersIndex/>
  </AuthorizePage>
}
