import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { RootState } from "../../store";
import { view, destroy } from "../../store/devicesActions";
import { Descriptions, PageHeader, Skeleton } from "antd";
import { SettingOutlined, AimOutlined, PlayCircleOutlined, HistoryOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { notifySuccess } from "../../components/ui/notifications";
import { Device } from "../../store/models";
import AuthorizePage from "../../components/authorize/AuthorizePage";
import ShowPageError, { isPageError } from "../../components/ShowPageError";
import ModelDescriptions from "../../components/ui/ModelDescriptions";
import OrganizationLink from "../../components/links/OrganizationLink";
import FacilityLink from "../../components/links/FacilityLink";
import { deviceName } from "../../helpers/formatters";
import MachineLink from "../../components/links/MachineLink";
import DeleteButton from "../../components/buttons/DeleteButton";
import UpdateButton from "../../components/buttons/UpdateButton";
import ReadButton from "../../components/buttons/ReadButton";
import DeviceLinkButton from "../../components/buttons/DeviceLinkButton";

const DeleteDeviceButton = ({ device }: { device: Device }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const confirm = () => {
    dispatch(
      destroy(device.id, () => {
        navigate("/devices");
        notifySuccess(`Device ${deviceName(device)} is deleted`);
      })
    );
  };
  return <DeleteButton subject="Device" subjectName={deviceName(device)} onConfirm={confirm} />;
};

const DevicesView = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const device = useAppSelector((state: RootState) => state.devices.item);
  const error = useAppSelector((state: RootState) => state.devices.error);
  const loading = useAppSelector((state: RootState) => state.devices.loading) || !device;

  useEffect(() => {
    dispatch(view(id!, { include: "organizations,facilities,machines" }));
  }, [id, dispatch]);

  if (isPageError(error)) {
    return <ShowPageError error={error!} />;
  }

  return (
    <>
      <PageHeader
        title={`Device ${deviceName(device)}`}
        onBack={() => navigate("/devices")}
        extra={
          device
            ? [
                <UpdateButton key="u" subject="Device" subjectId={device.id} subjectName={deviceName(device)} />,
                // <ReadButton key="c" subject="DeviceConfiguration" parentId={device.organizationId} to={`configurations`} icon={<SettingOutlined />} plural />,
                <DeviceLinkButton key="l" device={device} />,
                <ReadButton subject="DeviceHistory" parentId={device.organizationId} icon={<HistoryOutlined />} to={`/devices/${device.id}/history`} />,
                // <ReadButton key="s" subject="DeviceStatus" parentId={device.organizationId} to={`statuses`} icon={<AimOutlined />} />,
                // <ReadButton key="co" subject="DeviceCommand" parentId={device.organizationId} to={`commands`} icon={<PlayCircleOutlined />} plural />,
                <DeleteDeviceButton key="d" device={device} />,
              ]
            : []
        }
      />
      {loading ? (
        <Skeleton active={true} />
      ) : (
        <ModelDescriptions title="Details">
          <Descriptions.Item label="Machine">
            <MachineLink model={device} />
          </Descriptions.Item>
          <Descriptions.Item label="Organization">
            <OrganizationLink model={device} />
          </Descriptions.Item>
          <Descriptions.Item label="Facility">
            <FacilityLink model={device} />
          </Descriptions.Item>
        </ModelDescriptions>
      )}
    </>
  );
};

export default () => {
  const { id } = useParams();
  const currentOrganization = useAppSelector((state: RootState) => state.selection.currentOrganization);
  return (
    <AuthorizePage subject="Device" subjectId={id} parentId={currentOrganization?.id} action="read">
      <DevicesView />
    </AuthorizePage>
  );
};
