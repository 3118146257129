import {Button, Tooltip} from "antd";
import {Link} from "react-router-dom";

type Parameters = {
  title?: string
  icon: React.ReactNode
  to?: string
  disabled?:boolean
  onClick?: React.MouseEventHandler<HTMLElement>
}

const IconButton = ({to, onClick, title, icon,disabled}: Parameters) => {
  const button = <Button shape="circle" disabled={disabled} onClick={onClick}>
    {to ? <Link to={to}>{icon}</Link> : icon}
  </Button>
  return title ?
    <Tooltip title={title} mouseEnterDelay={0.5}>
      {button}
    </Tooltip> :
    button
}

export default IconButton;
