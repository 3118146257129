import { Dispatch } from "redux";
import { authGet, authPost, authPut, authDelete } from "./client";
import { devicesActions } from "./devicesSlice";
import processError from "./processError";
import { Device, DeviceState, DeviceIncluded, Pagination } from "./models";
import { assignIncludedFacilities, assignIncludedMachines, assignIncludedOrganizations } from "./assignIncluded";
import { debug } from "console";
import moment from "moment";
import { isOnline } from "../lib/time";

type DevicesResponse = {
  devices: Device[];
  pagination: Pagination;
  included?: DeviceIncluded;
};

type StatesResponse = {
  states: DeviceState[];
};

type DeviceResponse = {
  device: Device;
  included?: DeviceIncluded;
};

type SaveData = {
  id?: string;
  alias: string;
  firmwareVersion: string;
  softwareVersion: string;
  machineId: string;
  organizationId?: string;
  facilityId?: string;
};

type indexOpt = { scope?: string; include?: string };
export const index =
  (pageIndex: number, pageSize: number, organizationId?: string, facilityId?: string, opt?: indexOpt, search?: string) => async (dispatch: Dispatch) => {
    const { scope, include } = opt || {};
    try {
      dispatch(devicesActions.loading());
      const { data }: { data: DevicesResponse } = await authGet("devices", { organizationId, facilityId, pageIndex, pageSize, scope, include, search });
      const { devices, pagination, included } = data;
      devices.forEach((d) => assignIncluded(d, included));
      dispatch(devicesActions.index({ devices, pagination }));
    } catch (error: any) {
      dispatch(devicesActions.index({ devices: [], pagination: { index: 0, total: 0 } }));
      // processError(error, dispatch, devicesActions);
    } finally {
      dispatch(devicesActions.loaded());
    }
  };

export const states = (pageIndex: number, pageSize: number, organizationId?: string, facilityId?: string, opt?: indexOpt) => async (dispatch: Dispatch) => {
  const { scope } = opt || {};
  try {
    const { data }: { data: StatesResponse } = await authGet("devices/states", { organizationId, facilityId, pageIndex, pageSize, scope });
    // const { data }: { data: StatesResponse } = await authGet("devices/states", {});
    var states = data.states.sort((a, b) => {
      //moment.utc(text).isBefore(moment().subtract(1, "minute")
      if (a.modified !== b.modified) {
        return Number(isOnline(a.modified)) - Number(isOnline(b.modified));
      } else {
        return a.alias.localeCompare(b.alias);
      }
    });
    dispatch(devicesActions.states({ states: states }));
  } catch (error: any) {
    dispatch(devicesActions.states({ states: [] }));
    // processError(error, dispatch, devicesActions);
  } finally {
    dispatch(devicesActions.loaded());
  }
};

type viewOpt = { include?: string };
export const view = (id: string, opt?: viewOpt, onSuccess?: (devices: Device) => void) => async (dispatch: Dispatch) => {
  const { include } = opt || {};
  try {
    dispatch(devicesActions.loading());
    const { data }: { data: DeviceResponse } = await authGet(`devices/${id}`, { include });
    const { device, included } = data;
    dispatch(devicesActions.view(assignIncluded(device, included)));
    onSuccess && onSuccess(device);
  } catch (error: any) {
    processError(error, dispatch, devicesActions);
  } finally {
    dispatch(devicesActions.loaded());
  }
};

export const save = (device: SaveData, id: string | undefined, onSuccess: (device: Device) => void) => async (dispatch: Dispatch) => {
  try {
    dispatch(devicesActions.loading());
    const { data }: { data: DeviceResponse } = id ? await authPut(`devices/${id}`, { device: device }) : await authPost("devices", { device: device });
    onSuccess(data.device);
  } catch (error: any) {
    processError(error, dispatch, devicesActions);
  } finally {
    dispatch(devicesActions.loaded());
  }
};

export const destroy = (id: string, onSuccess?: () => void) => async (dispatch: Dispatch) => {
  try {
    dispatch(devicesActions.loading());
    await authDelete(`devices/${id}`);
    onSuccess && onSuccess();
  } catch (error: any) {
    processError(error, dispatch, devicesActions);
  } finally {
    dispatch(devicesActions.loaded());
  }
};

export const link = (id: string, organizationId: string, facilityId: string | null, onSuccess: () => void) => async (dispatch: Dispatch) => {
  try {
    dispatch(devicesActions.loading());
    await authPost(`devices/${id}/link`, { link: { organizationId, facilityId } });
    onSuccess();
  } catch (error: any) {
    processError(error, dispatch, devicesActions);
  } finally {
    dispatch(devicesActions.loaded());
  }
};

function assignIncluded(device: Device, included?: DeviceIncluded): Device {
  assignIncludedOrganizations(device, included);
  assignIncludedFacilities(device, included);
  assignIncludedMachines(device, included);
  return device;
}
