import _ from "lodash";
import {ActivityStatsStatus} from "../../store/models";
import {PageHeader} from "antd";
import {Pie} from "@ant-design/charts";
import {PieConfig} from "@ant-design/plots/es/components/pie";


const ActivityStatsDeviceStatus = ({stats}: { stats: ActivityStatsStatus }) => {
  const data = _.map(stats, (v, k) => {
    return {type: k, value: v}
  });

  const config: PieConfig = {
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 0.75,
    label: {
      type: 'spider',
      labelHeight: 28,
      content: '{name}\n{percentage}',
    },
    legend: {layout: "horizontal", position: "bottom",},
    interactions: [
      {type: 'element-selected'},
      {type: 'element-active'},
    ],
  };
  return <>
    <PageHeader title="Activity statistics"/>
    <Pie {...config} padding={0}/>
  </>
}

export default ActivityStatsDeviceStatus;

