import AuthorizeButton from "../authorize/AuthorizeButton";
import { EditOutlined } from "@ant-design/icons";
import { Subjects } from "../../lib/permissions";
import { useNavigate } from "react-router-dom";
import { humanizeSubject } from "../../helpers/formatters";

type Props = {
  to?: string;
  routePrefix?: string;
  routePostfix?: string;
  subject: Subjects;
  subjectId?: string;
  parentId?: string;
  subjectName: string;
  title?: string;
};
const UpdateButton = ({ to, routePrefix, routePostfix, subject, subjectId, parentId, subjectName, title }: Props) => {
  const navigate = useNavigate();
  const subjectTitle = `${subjectName ? `${subjectName} ` : ""}${humanizeSubject(subject)}`;
  title ||= `Update ${subjectTitle}`;
  const target = to ?? `${routePrefix ?? ""}edit${routePostfix ?? ""}`;

  return (
    <AuthorizeButton
      action="update"
      subject={subject}
      subjectId={subjectId}
      parentId={parentId}
      icon={<EditOutlined />}
      title={title}
      onClick={() => navigate(target)}
    />
  );
};
export default UpdateButton;
