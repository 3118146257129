import {Button, Form, Input, Space} from "antd";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {useNavigate} from "react-router-dom";
import {save} from "../../store/deviceConfigurationsActions";
import {RootState} from "../../store";
import {notifySuccess} from "../ui/notifications";
import ModelForm from "../ui/ModelForm";
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import {DeviceConfigurationWifi} from "../../store/models";

type Prop = {
  deviceConfiguration: DeviceConfigurationWifi,
  deviceId: string
}

const WifiDeviceConfigurationForm = ({deviceConfiguration, deviceId}: Prop) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate();
  const loading = useAppSelector((state: RootState) => state.deviceConfigurations.loading);
  const error = useAppSelector((state: RootState) => state.deviceConfigurations.error);

  const onFinishHandler = (data: DeviceConfigurationWifi) => {
    dispatch(save(deviceId, [{component: 'wifi', configuration: data}], () => {
      navigate(`/devices/${deviceId}/configurations`);
      notifySuccess(`Wifi configuration for ${deviceId} is updated`);
    }));
  };

  if (!deviceConfiguration.networks) {
    deviceConfiguration = {...deviceConfiguration, networks: []}
  }
  const networks = deviceConfiguration.networks;

  if (networks.length === 0) {
    deviceConfiguration.networks = [{name: '', password: ''}]
  }

  return <ModelForm isNew={false} loading={loading} initialValues={deviceConfiguration} onFinish={onFinishHandler}
                    error={error} autoComplete="off">

    <Form.List name="networks">
      {(fields, {add, remove}) => (
        <>
          {fields.map(({key, name, ...restField}) => (
            <Space key={key} align="baseline">
              <Form.Item {...restField} label="Name" name={[name, 'name']} rules={[{required: true}]}>
                <Input/>
              </Form.Item>
              <Form.Item {...restField} label="Password" name={[name, 'password']}>
                <Input.Password/>
              </Form.Item>

              <MinusCircleOutlined onClick={() => remove(name)}/>
            </Space>
          ))}

          <Form.Item wrapperCol={{offset: 1}}>
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
              Add new WiFi network
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  </ModelForm>
}

export default WifiDeviceConfigurationForm;
