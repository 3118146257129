import {PageHeader} from "antd";
import DeviceCommandsForm from "../../../components/deviceCommands/DeviceCommandsForm";
import {useNavigate, useParams} from "react-router-dom";
import AuthorizePage from "../../../components/authorize/AuthorizePage";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {RootState} from "../../../store";
import {useEffect} from "react";
import {view} from "../../../store/devicesActions";
import ShowPageError, {isPageError} from "../../../components/ShowPageError";

const Content = () => {
  const navigate = useNavigate();
  const {deviceId} = useParams();

  return <>
    <PageHeader title={`Sent command to device ${deviceId}`}
                onBack={() => navigate(`/devices/${deviceId}/commands`)}/>
    <DeviceCommandsForm deviceId={deviceId!}/>
  </>;
}

const DeviceCommandsNew = () => {
  const dispatch = useAppDispatch();
  const {deviceId} = useParams();
  const device = useAppSelector((state: RootState) => state.devices.item);
  const loading = useAppSelector((state: RootState) => state.devices.loading) || !device;
  const error = useAppSelector((state: RootState) => state.devices.error);

  useEffect(() => {
    dispatch(view(deviceId!));
  }, [deviceId, dispatch]);

  if (isPageError(error)) {
    return <ShowPageError error={error!}/>
  }

  return <AuthorizePage subject='DeviceCommand' parentId={device?.organizationId} action='create'
                        loading={loading}>
    <Content/>
  </AuthorizePage>
}

export default DeviceCommandsNew;

