import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

const SpaceNotSelected = () => {
  const navigate = useNavigate();

  return (
    <Result
      status="warning"
      title="Space is not selected"
      extra={[
        <Button type="primary" onClick={() => navigate("/spaces")}>
          Select it
        </Button>,
      ]}
    />
  );
};

export default SpaceNotSelected;
