import { Dispatch } from "redux";
import { authGet, authPost, authDelete } from "./client";
import { permissionsActions } from "./permissionsSlice";
import processError from "./processError";
import { Permission, PermissionIncluded } from "./models";
import { assignIncludedFacilities, assignIncludedOrganizations } from "./assignIncluded";

type PermissionsResponse = {
  permissions: Permission[];
  included?: PermissionIncluded;
};

type PermissionResponse = {
  permission: Permission;
};

type CreateData = {
  userId: string;
  organizationId?: string;
  facilityId?: string;
  role: string;
};
type indexOpt = { include?: string };
export const index = (userId: string, opt?: indexOpt) => async (dispatch: Dispatch) => {
  const { include } = opt || {};
  try {
    dispatch(permissionsActions.loading());
    const { data }: { data: PermissionsResponse } = await authGet(`users/${userId}/permissions`, { include });
    const { permissions, included } = data;
    permissions.forEach((d) => assignIncluded(d, included));
    dispatch(permissionsActions.index({ permissions }));
  } catch (error: any) {
    processError(error, dispatch, permissionsActions);
  } finally {
    dispatch(permissionsActions.loaded());
  }
};

export const create = (permission: CreateData, onSuccess: (permission: Permission) => void) => async (dispatch: Dispatch) => {
  try {
    dispatch(permissionsActions.loading());
    const { data }: { data: PermissionResponse } = await authPost(`users/permissions`, { permission: permission });
    onSuccess(data.permission);
  } catch (error: any) {
    processError(error, dispatch, permissionsActions);
  } finally {
    dispatch(permissionsActions.loaded());
  }
};

export const destroy = (id: string, onSuccess?: () => void) => async (dispatch: Dispatch) => {
  try {
    dispatch(permissionsActions.loading());
    await authDelete(`users/permissions/${id}`);
    onSuccess && onSuccess();
  } catch (error: any) {
    processError(error, dispatch, permissionsActions);
  } finally {
    dispatch(permissionsActions.loaded());
  }
};

function assignIncluded(permission: Permission, included?: PermissionIncluded): Permission {
  assignIncludedOrganizations(permission, included);
  assignIncludedFacilities(permission, included);
  return permission;
}
