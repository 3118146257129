import { PageHeader, Typography, Space, Select } from "antd";
import DeviceStates from "../components/devices/DeviceStates";
import { index } from "../store/floorsActions";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { AppDispatch, RootState } from "../store";
import { selectFloor } from "../store/selectionActions";

import SpacesDashboard from "../components/dashboard/SpacesDashboard";
import OrganizationDashboard from "../components/dashboard/OrganizationDashboard";

const Dashboard = () => {
  const dispatch = useAppDispatch();

  const currentFloor = useAppSelector((state: RootState) => state.selection.currentFloor);
  const currentFacility = useAppSelector((state: RootState) => state.selection.currentFacility);

  const floors = useAppSelector((state: RootState) => state.floors.list);
  const pagination = useAppSelector((state: RootState) => state.floors.pagination);
  const loading = useAppSelector((state: RootState) => state.floors.loading);

  useEffect(() => {
    if (currentFacility) {
      dispatch(index(0, 20, currentFacility.id));
    }
  }, [currentFacility, dispatch]);

  useEffect(() => {
    if (floors !== undefined && floors.length > 0) {
      dispatch(selectFloor(floors[0]));
    }
  }, [floors]);

  const handleChange = (value: string) => {
    let floor = floors?.find((x) => x.id === value);
    if (floor !== undefined) {
      dispatch(selectFloor(floor));
    }
  };

  const floorOptions = floors?.map((x) => {
    return { value: x.id, label: x.name };
  });

  return (
    <>
      <OrganizationDashboard />

      {/* <DeviceStates /> */}
      {/* <PageHeader title="Under development"/>
    <Text>Meanwhile you can play around with other pages</Text> */}
    </>
  );
};

export default Dashboard;
