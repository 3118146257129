import { Dispatch } from "redux";
import { authGet } from "./client";
import { deviceStatusesActions } from "./deviceStatusesSlice";
import processError from "./processError";
import { DeviceStatus } from "./models";

type DeviceStatusesResponse = {
  statuses: DeviceStatus[];
};

export const index = (deviceId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(deviceStatusesActions.loading());
    const { data }: { data: DeviceStatusesResponse } = await authGet(`devices/${deviceId}/statuses`);
    dispatch(deviceStatusesActions.index({ deviceStatuses: data.statuses }));
  } catch (error: any) {
    processError(error, dispatch, deviceStatusesActions);
  } finally {
    dispatch(deviceStatusesActions.loaded());
  }
};
