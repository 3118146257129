import axios, { AxiosRequestConfig } from "axios";
import store from "./../store";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BACKEND}/`,
  headers: { "Content-Type": "application/json" },
});

const fileInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BACKEND}/`,
  headers: { "Content-Type": "multipart/form-data", accept: "*/*" },
});

export function preferAuthGet(path: string, params = {}) {
  return store.getState().auth.authenticated ? authGet(path, params) : get(path, params);
}

export function get(path: string, params = {}, config: AxiosRequestConfig = {}) {
  return instance.get(path, { params, ...config });
}

export function authGet(path: string, params = {}) {
  return instance.get(path, { params, ...authorization() });
}

export function post(path: string, data: any, config: AxiosRequestConfig = {}) {
  return instance.post(path, data, config);
}

export function authPost(path: string, data: any, params = {}) {
  return instance.post(path, data, { params, ...authorization() });
}

export function authPut(path: string, data: any) {
  return instance.put(path, data, authorization());
}

export function patch(path: string, data: any, config: AxiosRequestConfig = {}) {
  return instance.patch(path, data, config);
}

export function authPatch(path: string, data: any) {
  return instance.patch(path, data, authorization());
}

export function del(path: string, config: AxiosRequestConfig = {}) {
  return instance.delete(path, config);
}

export function authDelete(path: string) {
  return instance.delete(path, authorization());
}

export function authorization(): AxiosRequestConfig {
  return { headers: { Authorization: store.getState().auth.token! } };
}

export function authGetFile(path: string,onDownloadProgress?: (percentage: number) => void) {
  var params = authorization(); 
  params.responseType = "arraybuffer";

  const config: AxiosRequestConfig = {
    ...params,
      onDownloadProgress: progressEvent => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      onDownloadProgress && onDownloadProgress(percentCompleted);
    }
  };
  return fileInstance.get(path, config);
}

