import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {RootState} from "../../store";
import {view, destroy} from '../../store/machinesActions';
import {Descriptions, PageHeader, Skeleton} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {Machine} from "../../store/models";
import {notifySuccess} from "../../components/ui/notifications";
import AuthorizePage from "../../components/authorize/AuthorizePage";
import ShowPageError, {isPageError} from "../../components/ShowPageError";
import ModelDescriptions from "../../components/ui/ModelDescriptions";
import DeleteButton from "../../components/buttons/DeleteButton";
import UpdateButton from "../../components/buttons/UpdateButton";

const DeleteMachineButton = ({machine}: { machine: Machine }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const confirm = () => {
    dispatch(destroy(machine.id, () => {
      navigate('/machines');
      notifySuccess(`Machine ${machine.model} is deleted`);
    }));
  };
  return <DeleteButton subject="Machine" subjectId={machine.id} subjectName={machine.model} onConfirm={confirm}/>
}

const MachineView = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {id} = useParams();
  const machine = useAppSelector((state: RootState) => state.machines.item);
  const error = useAppSelector((state: RootState) => state.machines.error);
  const loading = useAppSelector((state: RootState) => state.machines.loading) || !machine;

  useEffect(() => {
    dispatch(view(id!));
  }, [id, dispatch]);

  if (isPageError(error)) {
    return <ShowPageError error={error!}/>
  }

  return <>
    <PageHeader title={`Machine ${!loading && machine ? machine.model : ''}`}
                onBack={() => navigate('/machines')}
                extra={[
                  machine && <UpdateButton key="e" subject="Machine" subjectId={machine.id}
                                           subjectName={machine.model}/>,
                  machine && <DeleteMachineButton key="d" machine={machine}/>
                ]}
    />
    {loading ?
      <Skeleton active={true}/> :
      <ModelDescriptions title="Details">
        <Descriptions.Item label="Model">{machine!.model}</Descriptions.Item>
        <Descriptions.Item label="Manufacturer">{machine!.manufacturer}</Descriptions.Item>
        <Descriptions.Item label="Manufactured Date">{machine!.manufacturedDate.toString()}</Descriptions.Item>
      </ModelDescriptions>
    }

  </>
}

export default () => {
  const {id} = useParams();
  return <AuthorizePage subject='Machine' subjectId={id} action='read'>
    <MachineView/>
  </AuthorizePage>
}
