import {Form, Typography, Select} from "antd";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {Link, useNavigate} from "react-router-dom";
import {create} from "../../store/permissionsActions";
import {index as facilitiesIndex} from "../../store/facilitiesActions";
import {RootState} from "../../store";
import {notifySuccess} from "../ui/notifications";
import ModelForm from "../ui/ModelForm";
import {Role} from "../../store/models";
import {useEffect, useState} from "react";
import {isSystemAdmin} from "../../lib/permissions";

const {Option} = Select;
const {Text, Paragraph} = Typography;
const PAGE_SIZE = 20;

type PermissionData = {
  role: Role
  organizationId?: string
  facilityId?: string
};

type PermissionAttributes = {
  role: string
  userId: string
  organizationId?: string
  facilityId?: string
};

type Prop = {
  userId: string
}
const PermissionForm = ({userId}: Prop) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate();
  const loading = useAppSelector((state: RootState) => state.permissions.loading);
  // todo: error attribute is not cleared goes to this form for a first time
  // Steps: find user by email, add Admin => get error Duplicated role => change user email => see this error again
  const error = useAppSelector((state: RootState) => state.permissions.error);
  const facilities = useAppSelector((state: RootState) => state.facilities.list);
  const currentOrganization = useAppSelector((state: RootState) => state.selection.currentOrganization);

  const [role, setRole] = useState<Role>();

  useEffect(() => {
    if (currentOrganization) {
      dispatch(facilitiesIndex(0, PAGE_SIZE, currentOrganization.id))
    }
  }, [currentOrganization, dispatch])

  const onRoleChange = (newRole: Role) => {
    if (role !== newRole) {
      setRole(newRole);
    }
  };

  const organizationShouldBeSelected = (role === Role.Admin || role === Role.Manager || role === Role.Operator);
  const organizationIsNotSelected = organizationShouldBeSelected && !currentOrganization;

  const onFinishHandler = (data: PermissionData) => {
    const {role, facilityId} = data;
    if (organizationIsNotSelected) {
      return
    }
    let payload: PermissionAttributes = {role, userId: userId!}
    if (role === Role.Admin) {
      payload.organizationId = currentOrganization!.id;
    }
    if (role === Role.Operator || role === Role.Manager) {
      payload.facilityId = facilityId;
    }
    dispatch(create(payload, (permission) => {
      navigate(`/users/${userId}/permissions`);
      notifySuccess(`Permission ${permission.role} is added`);
    }));
  };

  return <ModelForm isNew={true} loading={loading}
                    onFinish={onFinishHandler} error={error}
  >
    <Form.Item name="role" label="Role" rules={[{required: true}]}>
      <Select onChange={onRoleChange} placeholder="Select role">
        <Option value={Role.Admin}>Administrator</Option>
        <Option value={Role.Manager}>Manager</Option>
        <Option value={Role.Operator}>Operator</Option>
        {isSystemAdmin() && <Option value={Role.Manufacturer}>Manufacturer</Option>}
      </Select>
    </Form.Item>

    {(!organizationIsNotSelected && (role === Role.Manager || role === Role.Operator)) &&
      <Form.Item name="facilityId" label="Facility" rules={[{required: true}]}>
        <Select showSearch placeholder="All facilities">
          {facilities && facilities.map(facility =>
            <Option key={facility.id} value={facility.id}>{facility.name}</Option>)
          }
        </Select>
      </Form.Item>
    }

    {organizationIsNotSelected &&
      <Paragraph>
        <Text type="warning">
          Organization is not selected. Please select it in <Link to={"/organizations"}>this page</Link>
        </Text>
      </Paragraph>
    }
  </ModelForm>
}

export default PermissionForm;
