import {Dispatch} from 'redux';
import {systemActions} from './systemSlice';
import {AppError} from "./models";

export const error = (error: AppError) => (dispatch: Dispatch) => {
  dispatch(systemActions.error(error));
}

export const clearError = () => (dispatch: Dispatch) => {
  dispatch(systemActions.clearError());
}
