import {Form, Input} from "antd";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {useNavigate} from "react-router-dom";
import {save} from "../../store/deviceCommandsActions";
import {RootState} from "../../store";
import {notifySuccess} from "../ui/notifications";
import ModelForm from "../ui/ModelForm";

const {TextArea} = Input;

type FormData = {
  name: string
  parameters: string
}

type Prop = {
  deviceId: string
}

const DeviceCommandsForm = ({deviceId}: Prop) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate();
  const loading = useAppSelector((state: RootState) => state.deviceCommands.loading);
  const error = useAppSelector((state: RootState) => state.deviceCommands.error);

  const onFinishHandler = (data: FormData) => {
    const deviceCommand = {name: data.name, parameters: JSON.parse(data.parameters)}
    dispatch(save(deviceId, deviceCommand, () => {
      navigate(`/devices/${deviceId}/commands`);
      notifySuccess(`${data.name} command is sent to ${deviceId}`);
    }));
  };

  return <ModelForm isNew={true} fullWidthdata loading={loading}
                    onFinish={onFinishHandler} error={error}>
    <Form.Item label="Name" name="name" rules={[{required: true}]}>
      <Input/>
    </Form.Item>
    <Form.Item label="Parameters" name="parameters" rules={[{required: true},
      () => ({
        validator(_, value) {
          try {
            JSON.parse(value);
            return Promise.resolve();
          } catch (error: any) {
            return Promise.reject(error);
          }
        },
      }),
    ]}>
      <TextArea className="code" autoSize={{minRows: 5}} style={{"fontFamily": "monospace"}}/>
    </Form.Item>
  </ModelForm>
}

export default DeviceCommandsForm;
