import { PageHeader } from "antd";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks";
import { RootState } from "../../store";
import AuthorizePage from "../../components/authorize/AuthorizePage";
import MapForm from "../../components/maps/MapForm";
import SpaceNotSelected from "../../components/SpaceNotSelected";

const MapsNew = () => {
  const navigate = useNavigate();
  const currentSpace = useAppSelector((state: RootState) => state.selection.currentSpace);

  if (!currentSpace) {
    return <SpaceNotSelected />;
  }

  return (
    <>
      <PageHeader title="New map" onBack={() => navigate("/maps")} />
      <MapForm />
    </>
  );
};

export default () => {
  const currentFacility = useAppSelector((state: RootState) => state.selection.currentFacility);
  return (
    <AuthorizePage subject="Map" parentId={currentFacility?.id} action="create">
      <MapsNew />
    </AuthorizePage>
  );
};
