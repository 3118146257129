import { Form, Input, DatePicker } from "antd";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { save } from "../../store/machinesActions";
import { RootState } from "../../store";
import { Machine } from "../../store/models";
import { notifySuccess } from "../ui/notifications";
import ModelForm from "../ui/ModelForm";

type MachineData = {
  id?: string;
  manufacturer: string;
  model: string;
  manufacturedDate: Date;
};
const MachineForm = ({ machine }: { machine?: Machine }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading = useAppSelector((state: RootState) => state.machines.loading);
  const error = useAppSelector((state: RootState) => state.machines.error);

  const onFinishHandler = (data: MachineData) => {
    const action = machine?.id ? "updated" : "created";
    dispatch(
      save(data, machine?.id, (machine) => {
        navigate(`/machines/${machine.id}`);
        notifySuccess(`Machine ${machine!.model} is ${action}`);
      })
    );
  };

  const isNew = !machine?.id;
  return (
    <ModelForm isNew={isNew} loading={loading} initialValues={{ ...machine }} onFinish={onFinishHandler} error={error}>
      {isNew && (
        <Form.Item name="id" label="ID" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
      )}
      <Form.Item name="manufacturer" label="Manufacturer" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="model" label="Model" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="manufacturedDate" label="Manufactured Date" rules={[{ required: true }]}>
        <DatePicker showTime />
      </Form.Item>
    </ModelForm>
  );
};

export default MachineForm;
