import {useState} from "react";
import {PageHeader, Skeleton} from "antd";
import FacilityForm from "../../components/facilities/FacilityForm";
import {useEffect} from "react";
import {view} from "../../store/facilitiesActions";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {useNavigate, useParams} from "react-router-dom";
import {RootState} from "../../store";
import AuthorizePage from "../../components/authorize/AuthorizePage";
import ShowPageError, {isPageError} from "../../components/ShowPageError";
import OrganizationNotSelected from "../../components/OrganizationNotSelected";

const FacilitiesEdit = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const facility = useAppSelector((state: RootState) => state.facilities.item);
  const error = useAppSelector((state: RootState) => state.facilities.error);
  const currentOrganization = useAppSelector((state: RootState) => state.selection.currentOrganization);
  const [loaded, setLoaded] = useState(false)
  const {id} = useParams();

  useEffect(() => {
    dispatch(view(id!, {}, () => setLoaded(true)));
  }, [id, dispatch]);

  if (!currentOrganization) {
    return <OrganizationNotSelected />
  }

  if (isPageError(error)) {
    return <ShowPageError error={error!}/>
  }

  return <>
    <PageHeader title={`Edit Facility ${loaded && facility ? facility.name : ''}`}
                onBack={() => navigate('/facilities')}
    />
    {loaded ?
      <FacilityForm facility={facility}/> :
      <Skeleton active={true}/>
    }
  </>;
}

export default () => {
  const {id} = useParams();
  const currentOrganization = useAppSelector((state: RootState) => state.selection.currentOrganization);
  return <AuthorizePage subject='Facility' subjectId={id} parentId={currentOrganization?.id} action='update'>
    <FacilitiesEdit/>
  </AuthorizePage>
}

