import {PageHeader} from "antd";
import OrganizationForm from "../../components/organizations/OrganizationForm";
import {useNavigate} from "react-router-dom";
import AuthorizePage from "../../components/authorize/AuthorizePage";
import * as React from "react";


const OrganizationsNew = () => {
  const navigate = useNavigate();
  return <>
    <PageHeader title="New Organization"
                onBack={() => navigate('/organizations')}
    />
    <OrganizationForm/>
  </>;
}

export default () => {
  return <AuthorizePage subject='Organization' action='create'>
    <OrganizationsNew/>
  </AuthorizePage>
}
