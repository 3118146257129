import { Dispatch } from "redux";
import { authGet, authPost, authPut, authDelete } from "./client";
import { facilitiesActions } from "./facilitiesSlice";
import processError from "./processError";
import { Facility, FacilityIncluded, Pagination } from "./models";
import { assignIncludedOrganizations } from "./assignIncluded";

type FacilitiesResponse = {
  facilities: Facility[];
  pagination: Pagination;
};

type FacilityResponse = {
  facility: Facility;
  included?: FacilityIncluded;
};

type SaveData = {
  id?: string;
  organizationId: string;
  name: string;
  notes: string;
};

export const index = (pageIndex: number, pageSize: number | undefined, organizationId: string, search?: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(facilitiesActions.loading());
    const { data }: { data: FacilitiesResponse } = await authGet("facilities", { organizationId, pageIndex, pageSize, search });
    dispatch(facilitiesActions.index({ facilities: data.facilities, pagination: data.pagination }));
  } catch (error: any) {
    dispatch(facilitiesActions.index({ facilities: [], pagination: { index: 0, total: 0 } }));
    processError(error, dispatch, facilitiesActions);
  } finally {
    dispatch(facilitiesActions.loaded());
  }
};

type viewOpt = { include?: string };
export const view =
  (id: string, { include }: viewOpt, onSuccess?: (facility: Facility) => void) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(facilitiesActions.loading());
      const { data }: { data: FacilityResponse } = await authGet(`facilities/${id}`, { include });
      const { facility, included } = data;
      dispatch(facilitiesActions.view(assignIncluded(facility, included)));
      onSuccess && onSuccess(data.facility);
    } catch (error: any) {
      processError(error, dispatch, facilitiesActions);
    } finally {
      dispatch(facilitiesActions.loaded());
    }
  };

export const save = (facility: SaveData, onSuccess: (facility: Facility) => void) => async (dispatch: Dispatch) => {
  try {
    dispatch(facilitiesActions.loading());
    const { id, ...attributes } = facility;
    const { data }: { data: FacilityResponse } = id
      ? await authPut(`facilities/${id}`, { facility: attributes })
      : await authPost("facilities", { facility: attributes });
    onSuccess(data.facility);
  } catch (error: any) {
    processError(error, dispatch, facilitiesActions);
  } finally {
    dispatch(facilitiesActions.loaded());
  }
};

export const destroy = (id: string, onSuccess?: () => void) => async (dispatch: Dispatch) => {
  try {
    dispatch(facilitiesActions.loading());
    await authDelete(`facilities/${id}`);
    onSuccess && onSuccess();
  } catch (error: any) {
    processError(error, dispatch, facilitiesActions);
  } finally {
    dispatch(facilitiesActions.loaded());
  }
};

function assignIncluded(facility: Facility, included?: FacilityIncluded): Facility {
  assignIncludedOrganizations(facility, included);
  return facility;
}
