import { Dispatch } from "redux";
import { authPut } from "./client";
import { meActions } from "./meSlice";
import processError from "./processError";

export const updatePassword = (newPassword: string, currentPassword: string, onSuccess: () => void) => async (dispatch: Dispatch) => {
  try {
    dispatch(meActions.loading());
    await authPut(`users/me/password`, { password: { newPassword, currentPassword } });

    onSuccess();
  } catch (error: any) {
    processError(error, dispatch, meActions);
  } finally {
    dispatch(meActions.loaded());
  }
};
