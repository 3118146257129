import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppError, DeviceStatus} from "./models";

type DeviceStatusesState = {
  loading: boolean
  error?: AppError
  list?: DeviceStatus[]
}

const initialState: DeviceStatusesState = {
  loading: false,
  error: undefined,
  list: undefined,
}

const deviceStatusesSlice = createSlice({
  name: 'deviceStatuses',
  initialState,
  reducers: {
    loading(state) {
      state.loading = true;
    },
    loaded(state) {
      state.loading = false;
      state.error = undefined;
    },
    error(state, action: PayloadAction<AppError>) {
      state.loading = false;
      state.error = action.payload
    },
    index(state, action: PayloadAction<{ deviceStatuses: DeviceStatus[] }>) {
      state.list = action.payload.deviceStatuses;
    },
  }
});

export const deviceStatusesActions = deviceStatusesSlice.actions;
export default deviceStatusesSlice;
