import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { AppDispatch, RootState } from "../../store";
import { index } from "../../store/organizationsActions";
import { selectOrganization } from "../../store/selectionActions";
import { Empty, Form, PageHeader, Skeleton, Space, Table, Input, Typography } from "antd";

import { RightOutlined } from "@ant-design/icons";
import { Link, useSearchParams } from "react-router-dom";
import IconButton from "../../components/ui/IconButton";
import { Organization } from "../../store/models";
import { notifySuccess } from "../../components/ui/notifications";
import AuthorizePage from "../../components/authorize/AuthorizePage";
import CreateButton from "../../components/buttons/CreateButton";
import UpdateButton from "../../components/buttons/UpdateButton";

type Row = { id: string; name: string; notes: string };
type DataSource = Row[];

const PAGE_SIZE = 10;

function buildColumns(dispatch: AppDispatch) {
  return [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 200,
      render: (text: string, organization: Organization) => <Link to={organization.id}>{text}</Link>,
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      render: (notes: string) => <div className="PreWrap">{notes}</div>,
    },
    {
      title: "Actions",
      key: "action",
      width: 100,
      render: (_: any, organization: Organization) => (
        <Space size="middle">
          <UpdateButton subject="Organization" subjectId={organization.id} subjectName={organization.name} routePrefix={`${organization.id}/`} />
          <IconButton
            onClick={() => {
              dispatch(selectOrganization(organization));
              notifySuccess(`Organization ${organization.name} is selected`);
            }}
            icon={<RightOutlined />}
            title="Select organization"
          />
        </Space>
      ),
    },
  ];
}

const OrganizationsIndex = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const organizations = useAppSelector((state: RootState) => state.organizations.list);
  const pagination = useAppSelector((state: RootState) => state.organizations.pagination);
  const loading = useAppSelector((state: RootState) => state.organizations.loading);
  const [page, setPage] = useState(Number(searchParams.get("page")) || 1);
  const [search, setSearch] = useState("");
  let dataSource: DataSource = [];
  if (!loading && organizations) {
    dataSource = organizations.map(({ id, name, notes }: Row) => {
      return { key: id, id, name, notes };
    });
  }

  useEffect(() => {
    dispatch(index(page - 1, PAGE_SIZE, search));
  }, [page, dispatch, search]);

  const pageChangeHandler = (page: number) => {
    setPage(page);
    setSearchParams({ page: page.toString() });
  };
  const columns = buildColumns(dispatch);
  const { Search } = Input;
  const { Paragraph } = Typography;
  const onSearch = (value: string) => {
    setSearch(value);
  };
  return (
    <>
      <PageHeader title={`Organizations (${pagination.total})`} extra={[<CreateButton key="c" subject="Organization" />]} />
      <Paragraph>
        <Search placeholder="Search" onSearch={onSearch} style={{ width: 200 }} />
      </Paragraph>

      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={{
          current: page,
          total: pagination.total,
          defaultPageSize: PAGE_SIZE,
          onChange: pageChangeHandler,
        }}
        locale={{ emptyText: loading ? <Skeleton active={true} /> : <Empty /> }}
      />
    </>
  );
};

export default () => {
  return (
    <AuthorizePage subject="Organization" action="read">
      <OrganizationsIndex />
    </AuthorizePage>
  );
};
