import {Route, Routes} from 'react-router-dom';
import {Layout} from 'antd';
import {useAppDispatch, useAppSelector} from './hooks';
import {RootState} from './store'
import Auth from './pages/Auth';
import './App.less';
import logo from "./assets/images/Squad_robotics.png";
import AuthenticatedApp from "./AuthenticatedApp";
import {notifyError} from "./components/ui/notifications";
import {clearError} from "./store/systemActions";
import AppErrorBoundary from "./components/AppErrorBoundary";

const {Header} = Layout;

const GuestApp = () => {
  return <Layout className="GuestApp-layout">
    <Header className="GuestApp-header">
      <img src={logo} className="GuestApp-logo" alt="logo"/>
    </Header>
    <Routes>
      <Route path='/*' element={<Auth/>}/>
    </Routes>
  </Layout>
}

const SystemError = () => {
  const dispatch = useAppDispatch()
  const error = useAppSelector((state: RootState) => state.system.error);
  if (error) {
    notifyError('System error', error.message, {key: 'systemError'});
    dispatch(clearError());
  }
  return null;
}

function App() {
  const authenticated = useAppSelector((state: RootState) => state.auth.authenticated);
  return <AppErrorBoundary>
    <SystemError/>
    {!authenticated && <GuestApp/>}
    {authenticated && <AuthenticatedApp/>}
  </AppErrorBoundary>;
}

export default App;
