import { Dispatch } from "redux";
import moment from "moment";
import { Buffer } from "buffer";
import { authGet, authGetFile } from "./client";
import { historyActions } from "./historySlice";
import processError from "./processError";
import { CleaningEpisode, OrganizationHistoryIncluded, SpaceHistory, SpaceDayHistory } from "./models";

type OrganizationHistoryResponse = {
  readings: SpaceDayHistory[];
  included: OrganizationHistoryIncluded;
};

type HistoryResponse = {
  spaces: SpaceHistory[];
};

type CleaningEpisodesResponse = {
  episodes: CleaningEpisode[];
};

export const getSpaceHistory =
  (startTimeMoment: moment.Moment, endTimeMoment: moment.Moment, spaceId: string, onSuccess?: (data: SpaceHistory) => void, onError?: () => void) =>
  async (dispatch: Dispatch) => {
    try {
      const timezoneOffset = new Date().getTimezoneOffset();

      let startTime = startTimeMoment.toISOString();
      let endTime = endTimeMoment.toISOString();

      const { data }: { data: SpaceHistory } = await authGet(`/history/space`, { startTime, endTime, spaceId, timezoneOffset, timeout: 3000000 });
      onSuccess && onSuccess(data);
    } catch (error: any) {
      onError && onError();
    } finally {
    }
  };

export const getOrganizationHistory =
  (
    startTimeMoment: moment.Moment,
    endTimeMoment: moment.Moment,
    organizationId: string,
    onSuccess?: (response: OrganizationHistoryResponse) => void,
    onFailure?: () => void
  ) =>
  async (dispatch: Dispatch) => {
    try {
      const timezoneOffset = new Date().getTimezoneOffset();
      var startTime = startTimeMoment.toISOString();
      var endTime = endTimeMoment.toISOString();
      const { data }: { data: OrganizationHistoryResponse } = await authGet(`/history/organization`, {
        startTime,
        endTime,
        organizationId,
        timezoneOffset,
        timeout: 3000000,
      });
      onSuccess && onSuccess(data);
    } catch (error: any) {
      onFailure && onFailure();
      processError(error, dispatch, historyActions);
    } finally {
    }
  };

export const getHistorySum =
  (startTimeMoment: moment.Moment, endTimeMoment: moment.Moment, deviceId?: string, spaceId?: string) => async (dispatch: Dispatch) => {
    try {
      dispatch(historyActions.setSpaceHistoryLoading(true));
      var startTime = startTimeMoment.toISOString();
      var endTime = endTimeMoment.toISOString();
      const { data }: { data: HistoryResponse } = await authGet(`history/episodes/sum`, { startTime, endTime, deviceId, spaceId, timeout: 3000000 });
      dispatch(historyActions.setSpaceHistory({ spaces: data.spaces }));
    } catch (error: any) {
      dispatch(historyActions.setSpaceHistory({ spaces: [] }));
      processError(error, dispatch, historyActions);
    } finally {
      dispatch(historyActions.setSpaceHistoryLoading(false));
    }
  };

export const getCleaningEpisodeImage = (episodeId: string, onSuccess?: (base64: string) => void) => async (dispatch: Dispatch) => {
  try {
    const response = await authGetFile(`history/episodes/trajectory/${episodeId}/image`);
    const base64Image = Buffer.from(response.data, "binary").toString("base64");
    dispatch(historyActions.setCleaningEpisodeImage({ id: episodeId, base64: base64Image }));
    onSuccess && onSuccess(base64Image);
  } catch (error: any) {
    processError(error, dispatch, historyActions);
  } finally {
  }
};

export const getCleaningEpisodes =
  (startTimeMoment: moment.Moment, endTimeMoment: moment.Moment, deviceId?: string, spaceId?: string) => async (dispatch: Dispatch) => {
    try {
      dispatch(historyActions.setLoadingEpisode(true));
      var startTime = startTimeMoment.toISOString();
      var endTime = endTimeMoment.toISOString();
      const { data }: { data: CleaningEpisodesResponse } = await authGet(`history/episodes/trajectory`, { startTime, endTime, deviceId, spaceId });
      dispatch(historyActions.setCleaningEpisodes(data.episodes));
    } catch (error: any) {
      processError(error, dispatch, historyActions);
    } finally {
      dispatch(historyActions.setLoadingEpisode(false));
    }
  };
