import {DeleteOutlined} from "@ant-design/icons";
import AuthorizeButton from "../authorize/AuthorizeButton";
import {Subjects} from "../../lib/permissions";
import {humanizeSubject} from "../../helpers/formatters";

type Props = {
  subject: Subjects
  subjectName: String
  subjectId?: string
  parentId?: string
  onClick?: React.MouseEventHandler<HTMLElement>
  onConfirm?: (e?: React.MouseEvent<HTMLElement>) => void;
}

const DeleteButton = ({subject, subjectId, parentId, subjectName, onConfirm}: Props) => {
  const subjectTitle = `${subjectName ? `${subjectName} ` : ''}${humanizeSubject(subject)}`
  const title = `Delete ${subjectTitle}`

  return (
    <AuthorizeButton action="delete" subject={subject} subjectId={subjectId} parentId={parentId} onConfirm={onConfirm}
                     icon={<DeleteOutlined/>} title={title}
                     prompt={`Are you sure to delete ${subjectTitle}?`}/>
  )
}
export default DeleteButton;
