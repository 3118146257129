import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {RootState} from "../../store";
import {view, destroy} from '../../store/organizationsActions';
import {Descriptions, PageHeader, Skeleton} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {Organization} from "../../store/models";
import {notifySuccess} from "../../components/ui/notifications";
import MultiLineText from "../../components/ui/MultiLineText";
import AuthorizePage from "../../components/authorize/AuthorizePage";
import ShowPageError, {isPageError} from "../../components/ShowPageError";
import ModelDescriptions from "../../components/ui/ModelDescriptions";
import DeleteButton from "../../components/buttons/DeleteButton";
import UpdateButton from "../../components/buttons/UpdateButton";

const DeleteOrganizationButton = ({organization}: { organization: Organization }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const confirm = () => {
    dispatch(destroy(organization.id, () => {
      navigate('/organizations');
      notifySuccess(`Organization ${organization.name} is deleted`);
    }));
  };
  return <DeleteButton subject="Organization" subjectId={organization.id} subjectName={organization.name}
                       onConfirm={confirm}/>
}

const OrganizationView = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {id} = useParams();
  const organization = useAppSelector((state: RootState) => state.organizations.item);
  const error = useAppSelector((state: RootState) => state.organizations.error);
  const loading = useAppSelector((state: RootState) => state.organizations.loading) || !organization;

  useEffect(() => {
    dispatch(view(id!));
  }, [id, dispatch]);

  if (isPageError(error)) {
    return <ShowPageError error={error!}/>
  }

  return <>
    <PageHeader title={`Organization ${!loading && organization ? organization.name : ''}`}
                onBack={() => navigate('/organizations')}
                extra={[
                  organization && <UpdateButton key="o" subject="Organization" subjectId={organization.id}
                                                subjectName={organization.name}/>,
                  organization && <DeleteOrganizationButton key="d" organization={organization}/>
                ]}
    />
    {loading ?
      <Skeleton active={true}/> :
      <ModelDescriptions title="Details">
        <Descriptions.Item label="Name">{organization!.name}</Descriptions.Item>
        <Descriptions.Item label="Notes">
          <MultiLineText text={organization!.notes}/>
        </Descriptions.Item>
      </ModelDescriptions>
    }

  </>
}

export default () => {
  const {id} = useParams();
  return <AuthorizePage subject='Organization' subjectId={id} action='read'>
    <OrganizationView/>
  </AuthorizePage>
}

