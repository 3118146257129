import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppError, DeviceCommand, Pagination} from "./models";

type DeviceCommandsState = {
  loading: boolean
  error?: AppError
  pagination: Pagination
  list?: DeviceCommand[]
}

const initialState: DeviceCommandsState = {
  loading: false,
  error: undefined,
  list: undefined,
  pagination: {index: 0, total: 0}
}

const deviceCommandsSlice = createSlice({
  name: 'deviceCommands',
  initialState,
  reducers: {
    loading(state) {
      state.loading = true;
    },
    loaded(state) {
      state.loading = false;
      state.error = undefined;
    },
    error(state, action: PayloadAction<AppError>) {
      state.loading = false;
      state.error = action.payload
    },
    index(state, action: PayloadAction<{ deviceCommands: DeviceCommand[], pagination: Pagination }>) {
      state.list = action.payload.deviceCommands;
      state.pagination = action.payload.pagination;
    },
  }
});

export const deviceCommandsActions = deviceCommandsSlice.actions;
export default deviceCommandsSlice;
