import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppError, SpaceHistory, CleaningEpisode } from "./models";

type HistoryState = {
  error?: AppError;
  spacesHistory: SpaceHistory[];
  spacesHistoryLoading: boolean;
  cleaningEpisodes: CleaningEpisode[];
  loadingEpisode: boolean;
};

const initialState: HistoryState = {
  error: undefined,
  spacesHistory: [],
  spacesHistoryLoading: false,
  cleaningEpisodes: [],
  loadingEpisode: false,
};

const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: {
    error(state, action: PayloadAction<AppError>) {
      state.error = action.payload;
    },
    setSpaceHistory(state, action: PayloadAction<{ spaces: SpaceHistory[] }>) {
      state.spacesHistory = action.payload.spaces;
    },
    setSpaceHistoryLoading(state, action: PayloadAction<boolean>) {
      state.spacesHistoryLoading = action.payload;
    },
    setLoadingEpisode(state, action: PayloadAction<boolean>) {
      state.loadingEpisode = action.payload;
    },
    setCleaningEpisodes(state, action: PayloadAction<CleaningEpisode[]>) {
      state.cleaningEpisodes = action.payload;
    },
    setCleaningEpisodeImage(state, action: PayloadAction<{ base64: string; id: string }>) {
      var episode = state.cleaningEpisodes.find((x) => x.id === action.payload.id);
      if (episode !== undefined) {
        episode.heatmapBase64 = action.payload.base64;
      }
    },
  },
});

export const historyActions = historySlice.actions;
export default historySlice;
