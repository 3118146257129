import {Subjects, canCreate, canUpdate, canDelete, canRead, canLink} from "../../lib/permissions";
import IconButton from "../ui/IconButton";
import Confirmation from "../ui/Confirmation";

export type ButtonActions = 'create' | 'update' | 'delete' | 'read' | 'link';

type Props = {
  subject: Subjects
  subjectId?: string
  parentId?: string
  action: ButtonActions
  icon?: JSX.Element,
  title: string
  onClick?: React.MouseEventHandler<HTMLElement>
  to?: string
  prompt?: string
  disabled?: boolean
  onConfirm?: (e?: React.MouseEvent<HTMLElement>) => void;
}
const AuthorizeButton = ({
                           subject, subjectId, parentId, action, icon, onClick, to,
                           title, prompt, onConfirm,disabled
                         }: Props) => {
  const can =
    (action === 'create' && canCreate(subject, parentId)) ||
    (action === 'read' && canRead(subject, subjectId, parentId)) ||
    (action === 'update' && canUpdate(subject, subjectId!, parentId)) ||
    (action === 'delete' && canDelete(subject, parentId)) ||
    (action === 'link' && canLink(subject, parentId))
  if (!can) {
    return null;
  }
  return onConfirm ?
    <Confirmation title={title} prompt={prompt!} icon={icon} onConfirm={onConfirm} danger/> :
    <IconButton to={to}  onClick={onClick} disabled={disabled} title={title} icon={icon}/>
}

export default AuthorizeButton;
