import { PageHeader } from "antd";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks";
import { RootState } from "../../store";
import AuthorizePage from "../../components/authorize/AuthorizePage";
import FacilityNotSelected from "../../components/FacilityNotSelected";
import FloorForm from "../../components/floors/FloorForm";

const FloorsNew = () => {
  const navigate = useNavigate();
  const currentFacility = useAppSelector((state: RootState) => state.selection.currentFacility);

  if (!currentFacility) {
    return <FacilityNotSelected />;
  }

  return (
    <>
      <PageHeader title="New Floor" onBack={() => navigate("/floors")} />
      <FloorForm />
    </>
  );
};

export default () => {
  const currentFacility = useAppSelector((state: RootState) => state.selection.currentFacility);
  return (
    <AuthorizePage subject="Floor" parentId={currentFacility?.id} action="create">
      <FloorsNew />
    </AuthorizePage>
  );
};
