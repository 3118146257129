import { Dispatch } from "redux";
import { authGet, authPost, authPut, authDelete, authorization,authGetFile} from "./client";
import axios from "axios";
import { Buffer } from "buffer";
import { mapFilesActions } from "./mapFilesSlice";
import processError from "./processError";
import { MapFile, MapFileIncluded, Pagination, MapType } from "./models";
import { assignIncludedMap } from "./assignIncluded";

type FilesResponse = {
  files: MapFile[];
  pagination: Pagination;
};

type FileResponse = {
  file: MapFile;
  included?: MapFileIncluded;
};

type SaveData = {
  id?: string;
  subId: string;
  type: number;
  file: any;
};

export const index = (pageIndex: number, pageSize: number | undefined, mapId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(mapFilesActions.loading());
    const { data }: { data: FilesResponse } = await authGet(`maps/${mapId}/files`, { pageIndex, pageSize });
    dispatch(mapFilesActions.index({ files: data.files, pagination: data.pagination }));
  } catch (error: any) {
    processError(error, dispatch, mapFilesActions);
  } finally {
    dispatch(mapFilesActions.loaded());
  }
};

type viewOpt = { include?: string };
export const view =
  (id: string, mapId: string, { include }: viewOpt, onSuccess?: (file: MapFile) => void) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(mapFilesActions.loading());
      const { data }: { data: FileResponse } = await authGet(`maps/${mapId}/files/${id}`, { include });
      const { file, included } = data;
      dispatch(mapFilesActions.view(assignIncluded(file, included)));

      onSuccess && onSuccess(file);
    } catch (error: any) {
      processError(error, dispatch, mapFilesActions);
    } finally {
      dispatch(mapFilesActions.loaded());
    }
  };

  export const download =
  (id: string, mapId: string, onSuccess?: (file: Blob) => void,onProgress?: (percentage: number) => void) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await authGetFile(`maps/${mapId}/files/${id}/file`,onProgress);
      const file = new Blob([response.data], { type: 'application/octet-stream' });
      onSuccess && onSuccess(file);
    } catch (error: any) {
      processError(error, dispatch, mapFilesActions);
    } 
  };


export const save =
  (mapId: string, file: SaveData, onSuccess: (file: MapFile) => void,
    onProgress?: (percentage: number) => void) => async (dispatch: Dispatch) => {
    var client = axios.create({
      baseURL: `${process.env.REACT_APP_API_BACKEND}/`,
      headers: { "Content-Type": "multipart/form-data", accept: "*/*" },
      onUploadProgress: (event: ProgressEvent) => {
        const progress = Math.round((event.loaded / event.total) * 100);
        onProgress && onProgress(progress);
      },
    });

    const formData = new FormData();
    formData.append("file", file.file.file);
    try {
      dispatch(mapFilesActions.loading());
      var { id, subId } = file;
      subId = subId === undefined ? "" : subId;
      const { data }: { data: FileResponse } = id
        ? await client.put(`maps/${mapId}/files/${id}?subId=${subId}&type=${file.type}`, formData, { ...authorization() })
        : await client.post(`maps/${mapId}/files?subId=${subId}&type=${file.type}`, formData, { ...authorization() });

      onSuccess(data.file);
    } catch (error: any) {
      processError(error, dispatch, mapFilesActions);
    } finally {
      dispatch(mapFilesActions.loaded());
    }
  };

export const destroy = (mapId: string, id: string, onSuccess?: () => void) => async (dispatch: Dispatch) => {
  try {
    dispatch(mapFilesActions.loading());
    await authDelete(`maps/${mapId}/files/${id}`);

    onSuccess && onSuccess();
  } catch (error: any) {
    processError(error, dispatch, mapFilesActions);
  } finally {
    dispatch(mapFilesActions.loaded());
  }
};

function assignIncluded(file: MapFile, included?: MapFileIncluded): MapFile {
  assignIncludedMap(file, included);
  return file;
}
