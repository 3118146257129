import {PageHeader} from "antd";
import MachineForm from "../../components/machines/MachineForm";
import {useNavigate} from "react-router-dom";
import AuthorizePage from "../../components/authorize/AuthorizePage";

const MachinesNew = () => {
  const navigate = useNavigate();
  return <>
    <PageHeader title="New Machine"
                onBack={() => navigate('/machines')}
    />
    <MachineForm/>
  </>;
}

export default () => {
  return <AuthorizePage subject='Machine' action='create'>
    <MachinesNew/>
  </AuthorizePage>
}
