import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppError} from "./models";

type SystemState = {
  error?: AppError
}

const initialState: SystemState = {
  error: undefined,
}

const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    error(state, action: PayloadAction<AppError>) {
      state.error = action.payload;
    },
    clearError(state) {
      state.error = undefined;
    },
  }
});

export const systemActions = systemSlice.actions;
export default systemSlice;
