import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./authSlice";
import usersSlice from "./usersSlice";
import organizationsSlice from "./organizationsSlice";
import facilitiesSlice from "./facilitiesSlice";
import floorsSlice from "./floorsSlice";
import spacesSlice from "./spacesSlice";
import mapsSlice from "./mapsSlice";
import mapFilesSlice from "./mapFilesSlice";
import systemSlice from "./systemSlice";
import meSlice from "./meSlice";
import permissionsSlice from "./permissionsSlice";
import machinesSlice from "./machinesSlice";
import devicesSlice from "./devicesSlice";
import selectionSlice from "./selectionSlice";
import deviceConfigurationsSlice from "./deviceConfigurationsSlice";
import deviceStatusesSlice from "./deviceStatusesSlice";
import deviceHistorySlice from "./historySlice";
import deviceCommandsSlice from "./deviceCommandsSlice";
import deviceTrajectoriesSlice from "./deviceTrajectoriesSlice";

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    users: usersSlice.reducer,
    organizations: organizationsSlice.reducer,
    facilities: facilitiesSlice.reducer,
    floors: floorsSlice.reducer,
    spaces: spacesSlice.reducer,
    maps: mapsSlice.reducer,
    mapFiles: mapFilesSlice.reducer,
    system: systemSlice.reducer,
    me: meSlice.reducer,
    permissions: permissionsSlice.reducer,
    machines: machinesSlice.reducer,
    devices: devicesSlice.reducer,
    selection: selectionSlice.reducer,
    deviceConfigurations: deviceConfigurationsSlice.reducer,
    deviceStatuses: deviceStatusesSlice.reducer,
    deviceHistory: deviceHistorySlice.reducer,
    deviceCommands: deviceCommandsSlice.reducer,
    deviceTrajectories: deviceTrajectoriesSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
