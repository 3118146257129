import { Dispatch } from "redux";
import { authDelete, authGet, authPost } from "./client";
import { deviceCommandsActions } from "./deviceCommandsSlice";
import processError from "./processError";
import { DeviceCommand, Pagination } from "./models";

type DeviceCommandsResponse = {
  commands: DeviceCommand[];
  pagination: Pagination;
};

type SaveData = {
  name: string;
  parameters: string;
};

export const index = (pageIndex: number, pageSize: number, deviceId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(deviceCommandsActions.loading());
    const { data }: { data: DeviceCommandsResponse } = await authGet(`devices/${deviceId}/commands`, { pageIndex, pageSize });
    dispatch(deviceCommandsActions.index({ deviceCommands: data.commands, pagination: data.pagination }));
  } catch (error: any) {
    processError(error, dispatch, deviceCommandsActions);
  } finally {
    dispatch(deviceCommandsActions.loaded());
  }
};

export const save = (deviceId: string, deviceCommand: SaveData, onSuccess: () => void) => async (dispatch: Dispatch) => {
  try {
    dispatch(deviceCommandsActions.loading());
    await authPost(`devices/${deviceId}/commands`, { command: deviceCommand });
    onSuccess();
  } catch (error: any) {
    processError(error, dispatch, deviceCommandsActions);
  } finally {
    dispatch(deviceCommandsActions.loaded());
  }
};

export const destroy = (id: string, onSuccess?: () => void) => async (dispatch: Dispatch) => {
  try {
    dispatch(deviceCommandsActions.loading());
    await authDelete(`devices/commands/${id}`);
    onSuccess && onSuccess();
  } catch (error: any) {
    processError(error, dispatch, deviceCommandsActions);
  } finally {
    dispatch(deviceCommandsActions.loaded());
  }
};
