import _ from "lodash";
import { useEffect, useState } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { Dropdown, Layout, Menu, notification, Space, Spin, Typography } from "antd";
import type { MenuProps } from "antd";
import { useAppDispatch, useAppSelector } from "./hooks";
import { AppDispatch, RootState } from "./store";
import { getCurrentUser, logout } from "./store/authActions";
import { clear, reloadCurrent, selectFacilityId, selectOrganizationId } from "./store/selectionActions";
import Dashboard from "./pages/Dashboard";
import OrganizationsIndex from "./pages/organizations/OrganizationsIndex";
import OrganizationsNew from "./pages/organizations/OrganizationsNew";
import OrganizationsView from "./pages/organizations/OrganizationsView";
import OrganizationsEdit from "./pages/organizations/OrganizationsEdit";
import FacilitiesIndex from "./pages/facilities/FacilitiesIndex";
import FacilitiesNew from "./pages/facilities/FacilitiesNew";
import FacilitiesView from "./pages/facilities/FacilitiesView";
import FacilitiesEdit from "./pages/facilities/FacilitiesEdit";
import UsersIndex from "./pages/users/UsersIndex";
import UsersNew from "./pages/users/UsersNew";
import UsersView from "./pages/users/UsersView";
import UsersEdit from "./pages/users/UsersEdit";
import UsersAdd from "./pages/users/UsersAdd";
import MePassword from "./pages/me/MePassword";
import PermissionsIndex from "./pages/users/permissions/PermissionsIndex";
import PermissionsNew from "./pages/users/permissions/PermissionsNew";
import MachinesIndex from "./pages/machines/MachinesIndex";
import MachinesNew from "./pages/machines/MachinesNew";
import MachinesView from "./pages/machines/MachinesView";
import MachinesEdit from "./pages/machines/MachinesEdit";
import DevicesIndex from "./pages/devices/DevicesIndex";
import DevicesNew from "./pages/devices/DevicesNew";
import DevicesView from "./pages/devices/DevicesView";
import DevicesEdit from "./pages/devices/DevicesEdit";
import DevicesLink from "./pages/devices/DevicesLink";
import DeviceConfigurationsIndex from "./pages/devices/configurations/DeviceConfigurationsIndex";
import DeviceConfigurationsNew from "./pages/devices/configurations/DeviceConfigurationsNew";
import DeviceConfigurationsEdit from "./pages/devices/configurations/DeviceConfigurationsEdit";
import DeviceStatusesIndex from "./pages/devices/statuses/DeviceStatusesIndex";
import DeviceCommandsIndex from "./pages/devices/commands/DeviceCommandsIndex";
import DeviceCommandsNew from "./pages/devices/commands/DeviceCommandsNew";
import {
  TeamOutlined,
  BankOutlined,
  AppstoreOutlined,
  UserOutlined,
  HomeOutlined,
  CarOutlined,
  RobotOutlined,
  BuildOutlined,
  HeatMapOutlined,
} from "@ant-design/icons";
import "./AuthenticatedApp.less";
import logo from "./assets/images/Squad_robotics.png";
import { notifyError } from "./components/ui/notifications";
import {
  canRead,
  getFirstFacilityId,
  getFirstOrganizationId,
  isAdminForOrganization,
  isManagerForFacility,
  isManagerForOrganization,
  isManufacturerForAnyMachine,
  isOperatorForFacility,
  isOperatorForOrganization,
  isSystemAdmin,
} from "./lib/permissions";
import { execDevLogout } from "./development/development";
import { lcCurrentOrganizationId } from "./lib/storage";
import { Organization } from "./store/models";
import DeviceHistoryIndex from "./pages/devices/history/DeviceHistoryIndex";
import FloorsIndex from "./pages/floors/FloorsIndex";
import FloorsNew from "./pages/floors/FloorsNew";
import FloorsView from "./pages/floors/FloorsView";
import FloorsEdit from "./pages/floors/FloorsEdit";
import SpacesIndex from "./pages/spaces/SpacesIndex";
import SpacesNew from "./pages/spaces/SpacesNew";
import SpacesView from "./pages/spaces/SpacesView";
import SpacesEdit from "./pages/spaces/SpacesEdit";
import MapsIndex from "./pages/maps/MapsIndex";
import MapsNew from "./pages/maps/MapsNew";
import MapsEdit from "./pages/maps/MapsEdit";
import MapsView from "./pages/maps/MapsView";
import MapsFilesNew from "./pages/maps/files/MapsFilesNew";
import MapsFilesEdit from "./pages/maps/files/MapsFilesEdit";
import SpaceHistoryIndex from "./pages/spaces/history/SpaceHistoryIndex";

const { Header, Content, Sider } = Layout;
const { Text, Link } = Typography;

type MenuItem = Required<MenuProps>["items"][number];

function getMainMenuItem(label: React.ReactNode, key: React.Key, icon: React.ReactNode, children?: MenuItem[]): MenuItem {
  return { key, icon, children, label };
}

function buildMainMenuItems(currentOrganizationId?: string, currentFacilityId?: string, currentFloorId?: string, currentSpaceId?: string): MenuItem[] {
  const accessToOrganizations = canRead("Organization");
  const accessToUsers = canRead("User", undefined, currentOrganizationId);
  const accessToMachines = canRead("Machine");
  const accessToDevices = canRead("Device", undefined, currentOrganizationId);
  const accessToFloors = canRead("Floor", undefined, currentFacilityId);
  const accessToSpaces = canRead("Space", undefined, currentFacilityId);
  const accessToMaps = canRead("Map", undefined, currentFacilityId);
  return [
    getMainMenuItem("Dashboard", "/", <AppstoreOutlined />),
    accessToOrganizations && getMainMenuItem("Organizations", "/organizations", <BankOutlined />),
    accessToOrganizations && currentOrganizationId ? getMainMenuItem("Facilities", "/facilities", <HomeOutlined />) : undefined,
    accessToFloors && currentFacilityId && getMainMenuItem("Floors", "/floors", <BuildOutlined />),
    accessToSpaces && currentFacilityId && currentFloorId && getMainMenuItem("Spaces", "/spaces", <BuildOutlined />),
    accessToMaps && currentFacilityId && currentFloorId && currentSpaceId && isSystemAdmin() && getMainMenuItem("Maps", "/maps", <HeatMapOutlined />),
    accessToMachines && getMainMenuItem("Machines", "/machines", <CarOutlined />),
    accessToDevices && getMainMenuItem("Devices", "/devices", <RobotOutlined />),
    accessToUsers && getMainMenuItem("Users", "/users", <TeamOutlined />),
  ].filter((e) => e !== undefined) as MenuItem[];
}

function execLogout(dispatch: AppDispatch, onComplete: () => void) {
  dispatch(
    logout(() => {
      dispatch(clear());
      onComplete();
    })
  );
}

const HeaderMenu = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentUser = useAppSelector((state: RootState) => state.auth.currentUser);

  const onClickHandler: MenuProps["onClick"] = ({ key }) => {
    if (key === "logout") {
      const currentOrganizationId = lcCurrentOrganizationId();
      execLogout(dispatch, () => {
        if (execDevLogout(navigate, currentOrganizationId)) {
          return;
        }
        navigate("/", { replace: true });
      });
      return;
    }
    navigate(key);
  };

  const menu = (
    <Menu
      onClick={onClickHandler}
      items={[
        { key: "/me/password", label: "Change password" },
        { key: "logout", label: "Logout" },
      ]}
    />
  );

  return (
    <Dropdown overlay={menu} className="AuthenticatedApp-userMenu">
      <Link>
        <Space>
          <UserOutlined />
          {currentUser!.email}
        </Space>
      </Link>
    </Dropdown>
  );
};

const HeaderTitle = () => {
  const currentOrganization = useAppSelector((state: RootState) => state.selection.currentOrganization);
  const currentFacility = useAppSelector((state: RootState) => state.selection.currentFacility);
  const currentFloor = useAppSelector((state: RootState) => state.selection.currentFloor);
  const currentSpace = useAppSelector((state: RootState) => state.selection.currentSpace);
  let role;
  if (isSystemAdmin()) {
    role = "System Admin";
  } else if (currentOrganization) {
    if (isAdminForOrganization(currentOrganization.id)) {
      role = "Administrator";
    } else if (currentFacility) {
      if (isManagerForFacility(currentFacility?.id)) {
        role = "Manager";
      } else if (isOperatorForFacility(currentFacility?.id)) {
        role = "Operator";
      }
    } else if (isManagerForOrganization(currentOrganization.id)) {
      role = "Manager";
    } else if (isOperatorForOrganization(currentOrganization.id)) {
      role = "Operator";
    }
  } else if (isManufacturerForAnyMachine()) {
    role = "Manufacturer";
  }

  const organization = currentOrganization ? currentOrganization.name : undefined;
  const facility = currentFacility ? currentFacility.name : undefined;
  const floor = currentFloor ? currentFloor.name : undefined;
  const space = currentSpace ? currentSpace.name : undefined;
  const currentSelection = _.compact([organization, facility, floor, space, role]).join(" - ");

  return (
    <Text className="AuthenticatedApp-Title" strong ellipsis={true}>
      {currentSelection}
    </Text>
  );
};

function selectFirstFacility(dispatch: AppDispatch, currentOrganization: Organization) {
  if (isManagerForOrganization(currentOrganization.id) || isOperatorForOrganization(currentOrganization.id)) {
    const firstFacilityId = getFirstFacilityId();
    if (firstFacilityId) {
      dispatch(selectFacilityId(firstFacilityId));
    }
  }
}

function loadCurrentSelection(dispatch: AppDispatch) {
  dispatch(
    reloadCurrent((organization, facility) => {
      if (!organization) {
        const firstOrganizationId = getFirstOrganizationId();
        if (firstOrganizationId) {
          dispatch(selectOrganizationId(firstOrganizationId, (organization) => selectFirstFacility(dispatch, organization)));
        }
      } else if (!facility) {
        selectFirstFacility(dispatch, organization);
      }
    })
  );
}

const AuthenticatedApp = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const currentUser = useAppSelector((state: RootState) => state.auth.currentUser);
  const currentOrganization = useAppSelector((state: RootState) => state.selection.currentOrganization);
  const currentFacility = useAppSelector((state: RootState) => state.selection.currentFacility);
  const currentFloor = useAppSelector((state: RootState) => state.selection.currentFloor);
  const currentSpace = useAppSelector((state: RootState) => state.selection.currentSpace);
  const error = useAppSelector((state: RootState) => state.auth.error);
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    if (error) {
      notification.close("currentUserError");
      notifyError("Failed authorization", "Please, log in", { key: "currentUserError" });
      execLogout(dispatch, () => {
        dispatch(clear());
        navigate("/", { replace: true });
      });
    }
  }, [error, dispatch, navigate]);

  useEffect(() => {
    if (!currentUser) {
      dispatch(
        getCurrentUser(() => {
          loadCurrentSelection(dispatch);
        })
      );
    }
  }, [currentUser, dispatch]);

  if (!currentUser) {
    return (
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh!important" }}>
        <Spin />
      </div>
    );
  }

  const mainMenuHandler: MenuProps["onClick"] = (e) => {
    navigate(`${e.key}`);
  };

  const mainMenuItems = buildMainMenuItems(currentOrganization?.id, currentFacility?.id, currentFloor?.id, currentSpace?.id);
  const mainMenuKey: string = location.pathname.match(/.[^/]*/)?.toString() ?? "/";

  return (
    <Layout className="AuthenticatedApp-layout">
      <Header className="AuthenticatedApp-header">
        <img src={logo} className="AuthenticatedApp-logo" alt="logo" />
        <HeaderTitle />
        <HeaderMenu />
      </Header>
      <Layout>
        <Sider collapsible theme="light" collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
          <Menu theme="light" defaultSelectedKeys={[mainMenuKey]} mode="inline" items={mainMenuItems} onClick={mainMenuHandler} />
        </Sider>
        <Layout className={"AuthenticatedApp-contentLayout"}>
          <Content className="AuthenticatedApp-content">
            {/*TODO: Decide do we need breadcrumb*/}
            {/*<Breadcrumb style={{margin: '16px 0'}}>*/}
            {/*  <Breadcrumb.Item>Organizations</Breadcrumb.Item>*/}
            {/*</Breadcrumb>*/}
            <Routes>
              <Route index element={<Dashboard />} />
              <Route path="organizations" element={<OrganizationsIndex />} />
              <Route path="organizations/new" element={<OrganizationsNew />} />
              <Route path="organizations/:id" element={<OrganizationsView />} />
              <Route path="organizations/:id/edit" element={<OrganizationsEdit />} />
              <Route path="facilities" element={<FacilitiesIndex />} />
              <Route path="facilities/new" element={<FacilitiesNew />} />
              <Route path="facilities/:id" element={<FacilitiesView />} />
              <Route path="facilities/:id/edit" element={<FacilitiesEdit />} />

              <Route path="floors" element={<FloorsIndex />} />
              <Route path="floors/new" element={<FloorsNew />} />
              <Route path="floors/:id" element={<FloorsView />} />
              <Route path="floors/:id/edit" element={<FloorsEdit />} />

              <Route path="spaces" element={<SpacesIndex />} />
              <Route path="spaces/new" element={<SpacesNew />} />
              <Route path="spaces/:id" element={<SpacesView />} />
              <Route path="spaces/:id/edit" element={<SpacesEdit />} />
              <Route path="spaces/:id/history" element={<SpaceHistoryIndex />} />

              <Route path="maps" element={<MapsIndex />} />
              <Route path="maps/new" element={<MapsNew />} />
              <Route path="maps/:id" element={<MapsView />} />
              <Route path="maps/:id/edit" element={<MapsEdit />} />

              <Route path="maps/:id/files/new" element={<MapsFilesNew />} />
              <Route path="maps/:id/files/:fileId/edit" element={<MapsFilesEdit />} />

              <Route path="users" element={<UsersIndex />} />
              <Route path="users/new" element={<UsersNew />} />
              <Route path="users/add" element={<UsersAdd />} />
              <Route path="users/:id" element={<UsersView />} />
              <Route path="users/:id/edit" element={<UsersEdit />} />
              <Route path="users/:userId/permissions" element={<PermissionsIndex />} />
              <Route path="users/:userId/permissions/new" element={<PermissionsNew />} />
              <Route path="me/password" element={<MePassword />} />
              <Route path="machines" element={<MachinesIndex />} />
              <Route path="machines/new" element={<MachinesNew />} />
              <Route path="machines/:id" element={<MachinesView />} />
              <Route path="machines/:id/edit" element={<MachinesEdit />} />
              <Route path="devices" element={<DevicesIndex />} />
              <Route path="devices/new" element={<DevicesNew />} />
              <Route path="devices/:id" element={<DevicesView />} />
              <Route path="devices/:id/edit" element={<DevicesEdit />} />
              <Route path="devices/:id/link" element={<DevicesLink />} />
              <Route path="devices/:deviceId/configurations" element={<DeviceConfigurationsIndex />} />
              <Route path="devices/:deviceId/configurations/new" element={<DeviceConfigurationsNew />} />
              <Route path="devices/:deviceId/configurations/edit/:component" element={<DeviceConfigurationsEdit />} />
              <Route path="devices/:deviceId/statuses" element={<DeviceStatusesIndex />} />
              <Route path="devices/:deviceId/history" element={<DeviceHistoryIndex />} />
              <Route path="devices/:deviceId/commands" element={<DeviceCommandsIndex />} />
              <Route path="devices/:deviceId/commands/new" element={<DeviceCommandsNew />} />
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AuthenticatedApp;
