import { Form, Input, InputNumber, Select, Row, Col, Button, Upload, Progress } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { save } from "../../store/mapFilesActions";
import { RootState } from "../../store";
import { notifySuccess } from "../ui/notifications";
import ModelForm from "../ui/ModelForm";
import { MapFileType, MapFile } from "../../store/models";
import { useEffect, useState } from "react";

type MapData = {
  subId: string;
  type: number;
  file: any;
};

const MapFileForm = ({ mapId, file }: { mapId: string; file?: MapFile }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading = useAppSelector((state: RootState) => state.mapFiles.loading);
  const error = useAppSelector((state: RootState) => state.mapFiles.error);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const onFinishHandler = async (data: MapData) => {
    const action = file?.id ? "updated" : "created";
    setProgress(0);
    setUploading(true);
    dispatch(
      save(
        mapId,
        { ...data, id: file?.id },
        (file) => {
          navigate(`/maps/${mapId}`);
          notifySuccess(`File ${file!.id} is ${action}`);
        },
        (percentage) => {
          setProgress(percentage);
        }
      )
    );
  };
  useEffect(() => {
    if (!loading && uploading) {
      setUploading(false);
    }
  }, [loading, dispatch]);

  const newFile = !file?.id;
  return (
    <>
      <ModelForm isNew={newFile} loading={loading} initialValues={{ ...file }} onFinish={onFinishHandler} error={error}>
        <Form.Item name="subId" label="Sub Id">
          <Input />
        </Form.Item>
        <Form.Item name="type" label="Type" rules={[{ required: true }]}>
          <Select
            options={[
              { value: 1, label: "Image" },
              { value: 2, label: "Yaml" },
              { value: 3, label: "Kudan" },
              { value: 4, label: "Ivisio" },
              { value: 5, label: "PGM" },
              { value: 6, label: "Cartographer" },
            ]}
          />
        </Form.Item>
        <Form.Item name="file" label="File">
          <Upload multiple={false} maxCount={1} beforeUpload={() => false}>
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Form.Item>

        {/* <Form.Item name="transformMatrix" label="Transform matrix">
        <InputNumber step={0.01} />
      </Form.Item> */}
      </ModelForm>
      {uploading && <Progress percent={progress} type="circle" size={"default"} style={{ marginLeft: "200px" }} />}
    </>
  );
};

export default MapFileForm;
