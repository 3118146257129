import { Dispatch } from "redux";
import { authGet, post } from "./client";
import { authActions } from "./authSlice";
import processError from "./processError";
import { Permission, CurrentUser } from "./models";
import { lcRemoveAuthToken, lcSetAuthToken } from "../lib/storage";

type UserDetails = {
  email: string;
};

type LoginResponse = {
  login: {
    token: string;
    user: UserDetails;
  };
};

type CurrentUserResponse = {
  user: CurrentUser;
};

type PermissionsResponse = {
  permissions: Permission[];
};

export const login = (email: string, password: string, onComplete?: () => void) => async (dispatch: Dispatch) => {
  try {
    dispatch(authActions.loading());
    const { data }: { data: LoginResponse } = await post("users/login", { login: { email, password } });
    lcSetAuthToken(data.login.token);
    dispatch(authActions.login({ token: data.login.token }));

    onComplete && onComplete();
  } catch (error: any) {
    processError(error, dispatch, authActions);
  } finally {
    dispatch(authActions.loaded());
  }
};

export const logout = (onComplete: () => void) => async (dispatch: Dispatch) => {
  lcRemoveAuthToken();
  dispatch(authActions.logout());
  onComplete();
};

export const getCurrentUser = (onComplete?: () => void) => async (dispatch: Dispatch) => {
  try {
    dispatch(authActions.loading());
    // todo: think a better way how to share raw http requests between multiple reducer actions
    let [meResponse, permissionsResponse] = await Promise.all([authGet("users/me"), authGet("users/me/permissions")]);
    const { data: meData }: { data: CurrentUserResponse } = meResponse;
    const { data: permissionsData }: { data: PermissionsResponse } = permissionsResponse;

    dispatch(authActions.currentUser({ user: meData.user, permissions: permissionsData.permissions }));
    onComplete && onComplete();
  } catch (error: any) {
    lcRemoveAuthToken();
    dispatch(authActions.logout());
    // processError(error, dispatch, authActions);
  } finally {
    dispatch(authActions.loaded());
  }
};
