import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { AppDispatch, RootState } from "../../store";
import { index } from "../../store/spacesActions";
import { Empty, PageHeader, Skeleton, Space as ASpace, Table, Input, Typography } from "antd";
import { RightOutlined, HistoryOutlined } from "@ant-design/icons";
import { Link, useSearchParams } from "react-router-dom";
import { Space, Map } from "../../store/models";
import AuthorizePage from "../../components/authorize/AuthorizePage";
import { selectSpace } from "../../store/selectionActions";
import { notifySuccess } from "../../components/ui/notifications";
import IconButton from "../../components/ui/IconButton";
import CreateButton from "../../components/buttons/CreateButton";
import UpdateButton from "../../components/buttons/UpdateButton";
import FacilityNotSelected from "../../components/FacilityNotSelected";
import FloorNotSelected from "../../components/FloorNotSelected";
import { isOperatorForFacility, isSystemAdmin } from "../../lib/permissions";
import ReadButton from "../../components/buttons/ReadButton";

type Row = { id: string; name: string; notes: string; floorId: string; currentMapId?: string; currentMap?: Map };
type DataSource = Row[];

const PAGE_SIZE = 10;

const SpacesIndex = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentFloor = useAppSelector((state: RootState) => state.selection.currentFloor);
  const currentFacility = useAppSelector((state: RootState) => state.selection.currentFacility);

  const spaces = useAppSelector((state: RootState) => state.spaces.list);
  const pagination = useAppSelector((state: RootState) => state.spaces.pagination);
  const loading = useAppSelector((state: RootState) => state.spaces.loading);
  const [page, setPage] = useState(Number(searchParams.get("page")) || 1);
  const [search, setSearch] = useState("");
  let dataSource: DataSource = [];
  if (!loading && spaces) {
    dataSource = spaces.map(({ id, name, notes, floorId, currentMap }: Row) => {
      return { key: id, id, name, notes, floorId, currentMap };
    });
  }

  useEffect(() => {
    if (currentFloor) {
      dispatch(index(page - 1, PAGE_SIZE, currentFloor.id, { include: "maps" }, search));
    }
  }, [page, currentFloor, dispatch, search]);

  if (!currentFacility) {
    return <FacilityNotSelected />;
  }

  if (!currentFloor) {
    return <FloorNotSelected />;
  }

  function buildColumns(dispatch: AppDispatch) {
    return [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: 200,
        render: (text: string, space: Space) => <Link to={space.id}>{text}</Link>,
      },
      {
        title: "Notes",
        dataIndex: "notes",
        key: "notes",
        render: (notes: string) => <div className="PreWrap">{notes}</div>,
      },
      {
        title: "Map",
        dataIndex: "currentMap",
        key: "currentMap",
        render: (_: any, space: Space) => (
          <ASpace size="middle">
            {space.currentMap && (
              <>
                {isOperatorForFacility(currentFacility!.id) && <div className="PreWrap">{space.currentMap.name}</div>}
                {isSystemAdmin() && <Link to={`/maps/${space.currentMap.id}`}>{space.currentMap.name}</Link>}
              </>
            )}
            {!space.currentMap && <div className="PreWrap">No Map</div>}
          </ASpace>
        ),
      },
      {
        title: "Actions",
        key: "action",
        width: 100,
        render: (_: any, space: Space) => (
          <ASpace size="middle">
            <UpdateButton subject="Space" subjectId={space.id} parentId={space.floorId} subjectName={space.name} routePrefix={`${space.id}/`} />
            {isSystemAdmin() && (
              <IconButton
                onClick={() => {
                  dispatch(selectSpace(space));
                  notifySuccess(`Space ${space.name} is selected`);
                }}
                icon={<RightOutlined />}
                title="Select space"
              />
            )}
            <ReadButton subject="Space" parentId={currentFacility!.id} icon={<HistoryOutlined />} to={`/spaces/${space.id}/history`} />
          </ASpace>
        ),
      },
    ];
  }

  const pageChangeHandler = (page: number) => {
    setPage(page);
    setSearchParams({ page: page.toString() });
  };

  const columns = buildColumns(dispatch);
  const { Search } = Input;
  const { Paragraph } = Typography;
  const onSearch = (value: string) => {
    setSearch(value);
  };
  return (
    <>
      <PageHeader title={`Spaces (${pagination.total})`} extra={[<CreateButton key="c" subject="Space" parentId={currentFacility.id} />]} />
      <Paragraph>
        <Search placeholder="Search" onSearch={onSearch} style={{ width: 200 }} />
      </Paragraph>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={{
          current: page,
          total: pagination.total,
          defaultPageSize: PAGE_SIZE,
          onChange: pageChangeHandler,
        }}
        locale={{ emptyText: loading ? <Skeleton active={true} /> : <Empty /> }}
      />
    </>
  );
};

export default () => {
  const currentFacility = useAppSelector((state: RootState) => state.selection.currentFacility);
  return (
    <AuthorizePage subject="Space" parentId={currentFacility?.id} action="read">
      <SpacesIndex />
    </AuthorizePage>
  );
};
