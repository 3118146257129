import {useState} from "react";
import {PageHeader, Skeleton} from "antd";
import OrganizationForm from "../../components/organizations/OrganizationForm";
import {useEffect} from "react";
import {view} from "../../store/organizationsActions";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {useNavigate, useParams} from "react-router-dom";
import {RootState} from "../../store";
import AuthorizePage from "../../components/authorize/AuthorizePage";
import ShowPageError, {isPageError} from "../../components/ShowPageError";

const OrganizationsEdit = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const organization = useAppSelector((state: RootState) => state.organizations.item);
  const error = useAppSelector((state: RootState) => state.organizations.error);
  const [loaded, setLoaded] = useState(false)
  const {id} = useParams();

  useEffect(() => {
    dispatch(view(id!, () => setLoaded(true)));
  }, [id, dispatch]);

  if (isPageError(error)) {
    return <ShowPageError error={error!}/>
  }

  return <>
    <PageHeader title={`Edit Organization ${loaded && organization ? organization.name : ''}`}
                onBack={() => navigate('/organizations')}
    />
    {loaded ?
      <OrganizationForm organization={organization}/> :
      <Skeleton active={true}/>
    }
  </>;
}

export default () => {
  const {id} = useParams();
  return <AuthorizePage subject='Organization' subjectId={id} action='update'>
    <OrganizationsEdit/>
  </AuthorizePage>
}
