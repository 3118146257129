import {Descriptions} from "antd";

type Props = {
  title: string,
  children: React.ReactNode,
}

const ModelDescriptions = ({title, children}: Props) => {
  return <Descriptions title={title} bordered column={1}>
    {children}
  </Descriptions>
}

export default ModelDescriptions;
