import {Link} from "react-router-dom";
import {Organization} from "../../store/models";

type ModelWithOrganization = {
  organizationId?: string
  organization?: Organization
}

const OrganizationLink = ({model, withModelName}: { model: ModelWithOrganization, withModelName?: boolean }) => {
  if (!model.organizationId) {
    return null
  }
  return <Link to={`/organizations/${model.organizationId}`}>
    {withModelName ? 'Organization ' : ''}
    {model.organization?.name ?? model.organizationId}
  </Link>
}

export default OrganizationLink;
