import { Dispatch } from "redux";
import { authGet, authPost, authPut, authDelete } from "./client";
import { machinesActions } from "./machinesSlice";
import processError from "./processError";
import { Machine, Pagination } from "./models";
import moment from "moment";

export type RawMachine = {
  id: string;
  manufacturer: string;
  model: string;
  manufacturedDate: string;
  deviceId: string;
};

type MachinesResponse = {
  machines: RawMachine[];
  pagination: Pagination;
};

type MachineResponse = {
  machine: RawMachine;
};

type SaveData = {
  id?: string;
  manufacturer: string;
  model: string;
  manufacturedDate: Date;
};

export const index = (pageIndex: number, pageSize: number, search?: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(machinesActions.loading());
    const { data }: { data: MachinesResponse } = await authGet("machines", { pageIndex, pageSize, search });
    dispatch(machinesActions.index({ machines: parseItems(data.machines), pagination: data.pagination }));
  } catch (error: any) {
    processError(error, dispatch, machinesActions);
  } finally {
    dispatch(machinesActions.loaded());
  }
};

export const view = (id: string, onSuccess?: (machine: Machine) => void) => async (dispatch: Dispatch) => {
  try {
    dispatch(machinesActions.loading());
    const { data }: { data: MachineResponse } = await authGet(`machines/${id}`);
    let parsed = parseItem(data.machine);
    dispatch(machinesActions.view(parsed));
    onSuccess && onSuccess(parsed);
  } catch (error: any) {
    processError(error, dispatch, machinesActions);
  } finally {
    dispatch(machinesActions.loaded());
  }
};

export const save = (machine: SaveData, id: string | undefined, onSuccess: (machine: Machine) => void) => async (dispatch: Dispatch) => {
  try {
    dispatch(machinesActions.loading());
    const { data }: { data: MachineResponse } = id ? await authPut(`machines/${id}`, { machine: machine }) : await authPost("machines", { machine: machine });
    onSuccess(parseItem(data.machine));
  } catch (error: any) {
    processError(error, dispatch, machinesActions);
  } finally {
    dispatch(machinesActions.loaded());
  }
};

export const destroy = (id: string, onSuccess?: () => void) => async (dispatch: Dispatch) => {
  try {
    dispatch(machinesActions.loading());
    await authDelete(`machines/${id}`);
    onSuccess && onSuccess();
  } catch (error: any) {
    processError(error, dispatch, machinesActions);
  } finally {
    dispatch(machinesActions.loaded());
  }
};

function parseItems(machines: RawMachine[]): Machine[] {
  return machines.map(parseItem);
}

function parseItem(raw: RawMachine): Machine {
  // todo: remove moment() from redux state
  return { ...raw, manufacturedDate: moment(raw.manufacturedDate) };
}
