import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {RootState} from "../../../store";
import {index} from '../../../store/deviceConfigurationsActions';
import {Empty, PageHeader, Skeleton, Table, Typography} from "antd";
import {EyeOutlined, EyeInvisibleOutlined} from '@ant-design/icons';
import {useNavigate} from "react-router-dom";
import AuthorizePage from "../../../components/authorize/AuthorizePage";
import {view} from "../../../store/devicesActions";
import IconButton from "../../../components/ui/IconButton";
import Confirmation from "../../../components/ui/Confirmation";
import ShowPageError, {isPageError} from "../../../components/ShowPageError";
import {DeviceConfiguration, DeviceConfigurationWifi} from "../../../store/models";
import UpdateButton from "../../../components/buttons/UpdateButton";
import CreateButton from "../../../components/buttons/CreateButton";

const {Paragraph} = Typography;

const Wifi = ({configuration, showSecrets}: { configuration: DeviceConfigurationWifi, showSecrets: boolean }) => {
  const device = useAppSelector((state: RootState) => state.devices.item);
  const dataSource = (configuration.networks || []).map((n, i) => ({
    key: i,
    name: n.name,
    password: showSecrets ? n.password : '*****'
  }))
  const columns = [
    {title: 'Name', dataIndex: 'name', key: 'name'},
    {title: 'Password', dataIndex: 'password', key: 'password'},
  ]
  return <>
    <PageHeader title="WiFi networks" extra={[
      <UpdateButton key="e" subject="DeviceConfiguration" parentId={device!.organizationId} subjectName='wifi'
                    routePostfix={'/wifi'}/>,
    ]}/>
    <Table pagination={false} dataSource={dataSource} columns={columns} locale={{emptyText: <Empty/>}}/>
  </>
}

const Custom = ({configuration}: { configuration: DeviceConfiguration }) => {
  const device = useAppSelector((state: RootState) => state.devices.item);
  return <>
    <PageHeader title={`${configuration.component}`} extra={[
      <UpdateButton key="e" subject="DeviceConfiguration" parentId={device!.organizationId}
                    subjectName={configuration.component} routePostfix={`/${configuration.component}`}/>,
    ]}/>
    <Paragraph>
      <pre>{JSON.stringify(configuration.configuration, null, 2)}</pre>
    </Paragraph>
  </>
}
const DeviceConfigurationsIndex = () => {
  const {deviceId} = useParams()
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const device = useAppSelector((state: RootState) => state.devices.item);
  const deviceConfigurations = useAppSelector((state: RootState) => state.deviceConfigurations.list);
  const loading = useAppSelector((state: RootState) => state.deviceConfigurations.loading);
  const [showSecrets, setShowSecrets] = useState(false)
  useEffect(() => {
    if (deviceId) {
      dispatch(index(deviceId));
    }
  }, [deviceId, dispatch]);

  let wifi: DeviceConfigurationWifi = {networks: []}
  const custom: DeviceConfiguration[] = []
  deviceConfigurations?.forEach(c => {
    switch (c.component) {
      case 'wifi':
        wifi = c.configuration as DeviceConfigurationWifi
        break;
      default:
        custom.push(c)
    }
  })

  return <>
    <PageHeader title={`Device configuration for ${deviceId}`}
                onBack={() => navigate(`/devices/${deviceId}`)}
                extra={[
                  <CreateButton key="c" subject="DeviceConfiguration" parentId={device!.organizationId}/>,
                  showSecrets ?
                    <IconButton key="s" onClick={() => setShowSecrets(false)}
                                title={"Hide secrets/passwords"} icon={<EyeInvisibleOutlined/>}/> :
                    <Confirmation key="s" onConfirm={() => setShowSecrets(true)}
                                  title='Reveal secrets/passwords' icon={<EyeOutlined/>}
                                  prompt={"Do you want to reveal secrets/passwords?"}/>
                ]}
    />
    {loading ?
      <Skeleton active={true}/> :
      <>
        {wifi && <Wifi configuration={wifi} showSecrets={showSecrets}/>}
        {custom.map((c, i) => <Custom key={i} configuration={c}></Custom>)}
      </>
    }
  </>
}

export default () => {
  const dispatch = useAppDispatch();
  const {deviceId} = useParams();
  const device = useAppSelector((state: RootState) => state.devices.item);
  const loading = useAppSelector((state: RootState) => state.devices.loading) || !device;
  const error = useAppSelector((state: RootState) => state.devices.error);

  useEffect(() => {
    dispatch(view(deviceId!));
  }, [deviceId, dispatch]);

  if (isPageError(error)) {
    return <ShowPageError error={error!}/>
  }

  return <AuthorizePage subject='DeviceConfiguration' parentId={device?.organizationId} action='read' loading={loading}>
    <DeviceConfigurationsIndex/>
  </AuthorizePage>
}
