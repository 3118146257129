import {Result} from "antd";
import {AppError} from "../store/models";
import {useParams, useLocation} from "react-router-dom";

const inflection = require('inflection')

export function isPageError(error?: AppError) : boolean {
  if (!error) {
    return false;
  }
  return error.code !== 400 && error.code !== 409;
}

const ShowPageError = ({error}: { error: AppError }) => {
  const {id} = useParams();
  const {pathname} = useLocation();
  const parts = pathname.split('/');
  let model;
  let modelIndex = 2;
  if (pathname.endsWith('edit')) {
    modelIndex = 3;
  }
  if (parts.length >= modelIndex) {
    model = parts[parts.length - modelIndex];
    model = inflection.capitalize(inflection.singularize(model));
  }

  if (error.code === 404) {
    return <Result status="404" title={`${model ?? 'Resource'}${id ? ` ${id}` : ''} not found`}/>;
  }
  return <Result status="500">Something went wrong</Result>
};

export default ShowPageError;
