import {PageHeader} from "antd";
import {Bullet} from '@ant-design/plots';
import {BulletConfig} from "@ant-design/charts";

const BatteryDeviceStatus = ({value}: { value: number }) => {
  const data = [
    {
      title: "",
      ranges: [1],
      measures: [value],
      target: value,
    },
  ];

  const config: BulletConfig = {
    data,
    tooltip: false,
    measureField: 'measures',
    rangeField: 'ranges',
    targetField: 'target',
    xField: 'title',
    color: {range: '#f0efff', measure: '#3bb441', target: '#3bb441',},
    xAxis: {
      line: null,
    },
    renderer: 'svg',
    yAxis: false,
    layout: 'horizontal',
    height: 30,
    label: {
      measure: {
        position: 'middle',
        style: {fill: '#fff'},
        content: (data) => `${data.measures * 100}%`,
      },
    },
    animation: false,
  };
  return <PageHeader title='Battery' subTitle={<Bullet {...config} autoFit={false} width={600}/>}/>
}

export default BatteryDeviceStatus;

