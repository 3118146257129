import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { AppDispatch, RootState } from "../../store";
import { index } from "../../store/floorsActions";
import { Empty, PageHeader, Skeleton, Space, Table, Input, Typography } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { Link, useSearchParams } from "react-router-dom";
import { Floor } from "../../store/models";
import AuthorizePage from "../../components/authorize/AuthorizePage";
import { selectFloor } from "../../store/selectionActions";
import { notifySuccess } from "../../components/ui/notifications";
import IconButton from "../../components/ui/IconButton";
import CreateButton from "../../components/buttons/CreateButton";
import UpdateButton from "../../components/buttons/UpdateButton";
import FacilityNotSelected from "../../components/FacilityNotSelected";

type Row = { id: string; name: string; notes: string; facilityId: string };
type DataSource = Row[];

const PAGE_SIZE = 10;

function buildColumns(dispatch: AppDispatch) {
  return [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 200,
      render: (text: string, floor: Floor) => <Link to={floor.id}>{text}</Link>,
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      render: (notes: string) => <div className="PreWrap">{notes}</div>,
    },
    {
      title: "Actions",
      key: "action",
      width: 100,
      render: (_: any, floor: Floor) => (
        <Space size="middle">
          <UpdateButton subject="Floor" subjectId={floor.id} parentId={floor.facilityId} subjectName={floor.name} routePrefix={`${floor.id}/`} />
          <IconButton
            onClick={() => {
              dispatch(selectFloor(floor));
              notifySuccess(`Floor ${floor.name} is selected`);
            }}
            icon={<RightOutlined />}
            title="Select floor"
          />
        </Space>
      ),
    },
  ];
}

const FloorsIndex = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentFacility = useAppSelector((state: RootState) => state.selection.currentFacility);
  const floors = useAppSelector((state: RootState) => state.floors.list);
  const pagination = useAppSelector((state: RootState) => state.floors.pagination);
  const loading = useAppSelector((state: RootState) => state.floors.loading);
  const [page, setPage] = useState(Number(searchParams.get("page")) || 1);
  const [search, setSearch] = useState("");
  let dataSource: DataSource = [];
  if (!loading && floors) {
    dataSource = floors.map(({ id, name, notes, facilityId }: Row) => {
      return { key: id, id, name, notes, facilityId };
    });
  }

  useEffect(() => {
    if (currentFacility) {
      dispatch(index(page - 1, PAGE_SIZE, currentFacility.id, search));
    }
  }, [page, currentFacility, dispatch, search]);

  if (!currentFacility) {
    return <FacilityNotSelected />;
  }

  const pageChangeHandler = (page: number) => {
    setPage(page);
    setSearchParams({ page: page.toString() });
  };

  const columns = buildColumns(dispatch);
  const { Search } = Input;
  const { Paragraph } = Typography;
  const onSearch = (value: string) => {
    setSearch(value);
  };
  return (
    <>
      <PageHeader title={`Floors (${pagination.total})`} extra={[<CreateButton key="c" subject="Floor" parentId={currentFacility.id} />]} />
      <Paragraph>
        <Search placeholder="Search" onSearch={onSearch} style={{ width: 200 }} />
      </Paragraph>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={{
          current: page,
          total: pagination.total,
          defaultPageSize: PAGE_SIZE,
          onChange: pageChangeHandler,
        }}
        locale={{ emptyText: loading ? <Skeleton active={true} /> : <Empty /> }}
      />
    </>
  );
};

export default () => {
  const currentFacility = useAppSelector((state: RootState) => state.selection.currentFacility);
  return (
    <AuthorizePage subject="Floor" parentId={currentFacility?.id} action="read">
      <FloorsIndex />
    </AuthorizePage>
  );
};
