import {Layout, Button, Space} from 'antd';
import LoginForm from "../components/LoginForm";
import {useAppDispatch} from '../hooks'
import {PREDEFINED_USERS} from "../mirage/users";
import './Auth.less';
import {login} from "../store/authActions";
import Dev from "../components/development/Dev";

const {Content} = Layout;

const Auth = () => {
  const dispatch = useAppDispatch()

  const loginAs = (index: number) => {
    const user = PREDEFINED_USERS[index];
    dispatch(login(user.email, user.password))
  }
  const users = process.env.REACT_APP_MIRAGEJS ? PREDEFINED_USERS : PREDEFINED_USERS.slice(0, 1);
  return <Content className="Auth-content">
    <>
      <LoginForm/>
      <Dev>
        <h2>Or login as</h2>
        <Space direction="vertical">
          {users.map((user, index) =>
            <Button key={index} onClick={() => loginAs(index)}>{user.name}</Button>
          )}
        </Space>
      </Dev>
    </>
  </Content>
}

export default Auth;
