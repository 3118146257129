import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {RootState} from "../../../store";
import {index} from '../../../store/deviceStatusesActions';
import {index as trajectoriesIndex} from '../../../store/deviceTrajectoriesActions';
import {Empty, PageHeader, Skeleton} from "antd";
import {useNavigate} from "react-router-dom";
import AuthorizePage from "../../../components/authorize/AuthorizePage";
import {view} from "../../../store/devicesActions";
import ShowPageError, {isPageError} from "../../../components/ShowPageError";
import {ActivityStatsStatus, BatteryStatus, CleaningSessionsStatus, DeviceStatus} from "../../../store/models";
import DeviceMap from "../../../components/devices/DeviceMap";
import CustomDeviceStatus from "../../../components/deviceStatuses/CustomDeviceStatus";
import BatteryDeviceStatus from "../../../components/deviceStatuses/BatteryDeviceStatus";
import ActivityStatsDeviceStatus from "../../../components/deviceStatuses/ActivityStatsDeviceStatus";
import CleaningSessionsStatsDeviceStatus from "../../../components/deviceStatuses/CleaningSessionsStatsDeviceStatus";

function extractDeviceStatus(statuses: DeviceStatus[] | undefined, name: string): any {
  if (!statuses) {
    return undefined
  }
  const index = statuses.findIndex(s => s.component === name)
  if (index !== -1) {
    const status = statuses[index].status as BatteryStatus;
    statuses.splice(index, 1);
    return status;
  }
  return undefined;
}

const DeviceStatusesIndex = () => {
  const {deviceId} = useParams()
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const device = useAppSelector((state: RootState) => state.devices.item);
  const deviceStatuses = [...(useAppSelector((state: RootState) => state.deviceStatuses.list) ?? [])];
  const deviceTrajectoriesLoading = useAppSelector((state: RootState) => state.deviceTrajectories.loading);
  const loading = useAppSelector((state: RootState) => state.deviceStatuses.loading);
  useEffect(() => {
    if (deviceId) {
      dispatch(index(deviceId));
      dispatch(trajectoriesIndex(deviceId))
    }
  }, [deviceId, dispatch]);

  const mapDataLoaded = device?.mapUrl && !deviceTrajectoriesLoading;

  const battery: BatteryStatus | undefined = extractDeviceStatus(deviceStatuses, 'battery');
  const activity: ActivityStatsStatus | undefined = extractDeviceStatus(deviceStatuses, 'activityStats');
  const cleaningSessions: CleaningSessionsStatus | undefined = extractDeviceStatus(deviceStatuses, 'cleaningSessions');

  return <>
    <PageHeader title={`Device status for ${deviceId}`}
                onBack={() => navigate(`/devices/${deviceId}`)}
    />
    {battery && <BatteryDeviceStatus value={battery.value}/>}
    {activity && <ActivityStatsDeviceStatus stats={activity}/>}
    {cleaningSessions && <CleaningSessionsStatsDeviceStatus stats={cleaningSessions}/>}
    {mapDataLoaded && <DeviceMap device={device!}/>}
    {loading ?
      <Skeleton active={true}/> :
      <>
        {deviceStatuses && deviceStatuses.length > 0 ?
          deviceStatuses?.map((s, i) => <CustomDeviceStatus key={i} status={s}/>) :
          <Empty/>
        }
      </>
    }
  </>
}

export default () => {
  const dispatch = useAppDispatch();
  const {deviceId} = useParams();
  const device = useAppSelector((state: RootState) => state.devices.item);
  const loading = useAppSelector((state: RootState) => state.devices.loading) || !device;
  const error = useAppSelector((state: RootState) => state.devices.error);

  useEffect(() => {
    dispatch(view(deviceId!));
  }, [deviceId, dispatch]);

  if (isPageError(error)) {
    return <ShowPageError error={error!}/>
  }

  return <AuthorizePage subject='DeviceStatus' parentId={device?.organizationId} action='read' loading={loading}>
    <DeviceStatusesIndex/>
  </AuthorizePage>
}
