import { Dispatch } from "redux";
import { authGet } from "./client";
import { deviceTrajectoriesActions } from "./deviceTrajectoriesSlice";
import processError from "./processError";
import { DeviceTrajectory } from "./models";

type DeviceTrajectoriesResponse = {
  trajectories: DeviceTrajectory[];
};

export const index = (deviceId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(deviceTrajectoriesActions.loading());
    const { data }: { data: DeviceTrajectoriesResponse } = await authGet(`devices/${deviceId}/trajectories`);
    dispatch(deviceTrajectoriesActions.index({ deviceTrajectories: data.trajectories }));
  } catch (error: any) {
    processError(error, dispatch, deviceTrajectoriesActions);
  } finally {
    dispatch(deviceTrajectoriesActions.loaded());
  }
};
