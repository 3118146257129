import _ from 'lodash';
import {belongsTo, createServer, hasMany, Model, Registry, Request, Response, RestSerializer} from "miragejs";
import {ModelDefinition} from 'miragejs/-types';
import Schema from 'miragejs/orm/schema';
import {Server} from 'miragejs/server';
import {User, PREDEFINED_USERS} from "./users";
import {Organization, PREDEFINED_ORGANIZATIONS} from "./organizations";
import {Facility, PREDEFINED_FACILITIES} from "./facilities";
import {Permission, PREDEFINED_PERMISSIONS} from "./permissions";

/*
Questions/limitations/action items:
- now almost all rotes return full model, this is not ideal, not all fields should be returned
Fix this, for example user model should not return password
- has @ts-ignore for models
 */

const UserModel: ModelDefinition<User> = Model.extend({});
const OrganizationModel: ModelDefinition<Organization> = Model.extend({});
const FacilityModel: ModelDefinition<Facility> = Model.extend({});
const PermissionModel: ModelDefinition<Permission> = Model.extend({});
type Models = {
  user: typeof UserModel, organization: typeof OrganizationModel, facility: typeof FacilityModel,
  permission: typeof PermissionModel
}
type Factories = {}
type AppRegistry = Registry<Models, Factories>
type AppSchema = Schema<AppRegistry>

function unauthorizedResponse() {
  return new Response(403, {}, {error: {message: "Unauthorized", code: 401}});
}

function notFoundResponse(subject: string) {
  return new Response(404, {}, {error: {message: `${subject} not found`, code: 404}});
}

function singularize(s: string): string {
  if (s.endsWith('ies')) {
    return s.slice(0, -3) + 'y'
  } else if (s.endsWith('s')) {
    return s.slice(0, -1);
  }
  return s;
}

function detectResource(url: string) {
  const resource = new URL(url).pathname.split('/')[1];
  const modelName = singularize(resource)
  return {resource, modelName};
}

type ShowActionOpt = {
  only?: string[]
}

function showAction(schema: AppSchema, request: Request, opt: ShowActionOpt = {}) {
  const {modelName} = detectResource(request.url);
  const {id} = request.params;
  // @ts-ignore
  const model = schema.findBy(modelName, {id});
  if (!model) {
    return notFoundResponse(_.capitalize(modelName));
  }
  return {[modelName]: opt.only ? _.pick(model.attrs, opt.only) : model}
}

function indexAction(schema: AppSchema, request: Request, opt: { searchBy?: string } = {}) {
  const {resource, modelName} = detectResource(request.url);
  const pageIndex = +request.queryParams!.pageIndex;
  const pageSize = +request.queryParams!.pageSize;

  const searchAttributes: { [key: string]: unknown } = {};
  if (opt.searchBy) {
    searchAttributes[opt.searchBy] = request.queryParams![opt.searchBy];
  }
  // @ts-ignore
  const all = schema.where(modelName, searchAttributes);
  const index = pageIndex * pageSize;
  return {
    [resource]: all.slice(index, index + pageSize).models,
    pagination: {
      index: index,
      total: all.length,
    }
  }
}

// /node_modules/miragejs/lib/serializers/rest-serializer.js
const ApplicationSerializer = RestSerializer.extend({})

export function makeServer({environment = "test"}) {
  return createServer({
    environment,

    models: {
      user: Model.extend<Partial<User>>({
        // @ts-ignore
        organizations: hasMany(),
        // @ts-ignore
        permissions: hasMany(),
      }),
      organization: Model.extend<Partial<Organization>>({
        // @ts-ignore
        facilities: hasMany(),
        // @ts-ignore
        users: hasMany(),
        // @ts-ignore
        permissions: hasMany(),
      }),
      facility: Model.extend<Partial<Facility>>({
        // @ts-ignore
        organization: belongsTo(),
        // @ts-ignore
        permissions: hasMany(),
      }),
      permission: Model.extend<Partial<Permission>>({
        // @ts-ignore
        user: belongsTo(),
        // @ts-ignore
        organization: belongsTo(),
        // @ts-ignore
        facility: belongsTo(),
      })
    },

    serializers: {
      application: ApplicationSerializer,
    },

    fixtures: {},

    routes() {
      this.urlPrefix = 'http://localhost:5079';
      // Server timings
      this.timing = 1;    // lightning
      // this.timing = 2000; // heavy requests
      // this.timing = 5000; // snail

      this.post("/users/login", (schema: AppSchema, request: Request) => {
        const data = JSON.parse(request.requestBody);
        const user = schema.findBy('user', {email: data.login.email, password: data.login.password});
        if (!user) {
          return unauthorizedResponse();
        }
        return {
          login: {
            token: user!.token
          }
        }
      })

      this.get("/users/me", (schema: AppSchema, request: Request) => {
        const token = request.requestHeaders['Authorization'];
        const user = schema.findBy('user', {token});
        if (!user) {
          return unauthorizedResponse();
        }
        return {
          user: {
            id: user!.id,
            email: user!.email,
            name: user!.name,
            surname: user!.surname,
          }
        }
      })

      this.get('/users', (schema, request) => {
        return indexAction(schema, request);
      });
      this.get('/users/:id', (schema, request) => {
        return showAction(schema, request, {only: ['id', 'name', 'surname', 'email']});
      });
      this.post('/users');
      this.put("/users/:id")
      this.del("/users/:id")

      this.get("/organizations", (schema, request) => {
        return indexAction(schema, request);
      });
      this.get('/organizations/:id', (schema, request) => {
        return showAction(schema, request);
      });
      this.post("/organizations")
      this.put("/organizations/:id")
      this.del("/organizations/:id")

      this.get("/facilities", (schema, request) => {
        return indexAction(schema, request, {searchBy: 'organizationId'});
      });
      this.get('/facilities/:id', (schema, request) => {
        return showAction(schema, request);
      });
      this.post('/facilities');
      this.put("/facilities/:id")
      this.del("/facilities/:id")

      this.get("/permissions", (schema, request) => {
        return indexAction(schema, request, {searchBy: 'userId'});
      });

      this.passthrough((request: any) => {
        return request?.queryParams?.skipMirage;
      });
    },

    seeds(server
            :
            Server<AppRegistry>
    ) {
      PREDEFINED_USERS.forEach(user => server.create('user', user))
      PREDEFINED_ORGANIZATIONS.forEach(organization => server.create('organization', organization))
      PREDEFINED_FACILITIES.forEach(facility => server.create('facility', facility))
      PREDEFINED_PERMISSIONS.forEach(permission => server.create('permission', permission))
    }
    ,
  });
}
