import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {RootState} from "../../../store";
import {index} from '../../../store/deviceConfigurationsActions';
import {PageHeader, Skeleton} from "antd";
import {useNavigate} from "react-router-dom";
import AuthorizePage from "../../../components/authorize/AuthorizePage";
import {view} from "../../../store/devicesActions";
import WifiDeviceConfigurationForm from "../../../components/deviceConfigurations/WifiDeviceConfigurationForm";
import DeviceConfigurationForm from "../../../components/deviceConfigurations/DeviceConfigurationForm";
import {DeviceConfigurationWifi} from "../../../store/models";
import ShowPageError, {isPageError} from "../../../components/ShowPageError";

const DeviceConfigurationsEdit = () => {
  const {deviceId, component} = useParams()
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const deviceConfigurations = useAppSelector((state: RootState) => state.deviceConfigurations.list);
  const loading = useAppSelector((state: RootState) => state.deviceConfigurations.loading);

  useEffect(() => {
    if (deviceId) {
      dispatch(index(deviceId));
    }
  }, [deviceId, dispatch]);

  const deviceConfiguration = deviceConfigurations && deviceConfigurations.find(c => c.component === component);
  const isWifi = component === 'wifi'
  const wifi = (deviceConfiguration && isWifi ? deviceConfiguration.configuration : {}) as DeviceConfigurationWifi

  return <>
    <PageHeader title={`Edit ${deviceConfiguration?.component} configuration for device ${deviceId}`}
                onBack={() => navigate(`/devices/${deviceId}/configurations`)}
    />
    {loading ?
      <Skeleton active={true}/> :
      <>
        {isWifi ?
          <WifiDeviceConfigurationForm deviceConfiguration={wifi} deviceId={deviceId!}/> :
          <DeviceConfigurationForm deviceConfiguration={deviceConfiguration} deviceId={deviceId!}/>
        }
      </>
    }
  </>
}

export default () => {
  const dispatch = useAppDispatch();
  const {deviceId} = useParams();
  const device = useAppSelector((state: RootState) => state.devices.item);
  const loading = useAppSelector((state: RootState) => state.devices.loading) || !device;
  const error = useAppSelector((state: RootState) => state.devices.error);

  useEffect(() => {
    dispatch(view(deviceId!));
  }, [deviceId, dispatch]);

  if (isPageError(error)) {
    return <ShowPageError error={error!}/>
  }

  return <AuthorizePage subject='DeviceConfiguration' parentId={device?.organizationId} action='update'
                        loading={loading}>
    <DeviceConfigurationsEdit/>
  </AuthorizePage>
}
