import {Form, Input} from "antd";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {useNavigate} from "react-router-dom";
import {save} from "../../store/organizationsActions";
import {RootState} from "../../store";
import {Organization} from "../../store/models";
import {notifySuccess} from "../ui/notifications";
import ModelForm from "../ui/ModelForm";

type OrganizationData = {
  name: string
  notes: string
}

const OrganizationForm = ({organization}: { organization?: Organization }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate();
  const loading = useAppSelector((state: RootState) => state.organizations.loading);
  const error = useAppSelector((state: RootState) => state.organizations.error);

  const onFinishHandler = (data: OrganizationData) => {
    const action = organization?.id ? 'updated' : 'created';
    dispatch(save({...data, id: organization?.id}, (organization) => {
      navigate(`/organizations/${organization.id}`);
      notifySuccess(`Organization ${organization!.name} is ${action}`);
    }));
  };

  const newOrganization = !organization?.id
  return <ModelForm isNew={newOrganization} loading={loading} initialValues={{...organization}}
                    onFinish={onFinishHandler} error={error}>
    <Form.Item name='name' label="Name" rules={[{required: true}]}>
      <Input/>
    </Form.Item>
    <Form.Item name='notes' label="Notes" rules={[{required: true}]}>
      <Input.TextArea/>
    </Form.Item>
  </ModelForm>
}

export default OrganizationForm;
