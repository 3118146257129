import {Organization} from "./organizations";

export type User = {
  id: string
  email: string
  password: string
  token: string
  name: string
  surname: string
  organizations: Organization[]
};

export const USR_SPACE_X = 3;
export const PREDEFINED_USERS = [
  {
    id: 'USR_DEV', email: 'dev@robots.lv', password: 'dev_default', token: 'admin_token',
    name: 'Admin', surname: 'Robotics'
  },
  {
    id: 'USR_BLANK', email: 'user@robots.lv', password: 'robotics', token: 'user_token',
    name: 'Blank', surname: 'Robotics'
  },
  {
    id: 'USR_TESLA', email: 'tesla@example.com', password: 'robotics', token: 'tesla_token',
    name: 'Tesla', surname: 'Admin'
  },
  {
    id: 'USR_SPACE_X', email: 'spacex@example.com', password: 'robotics', token: 'space_x_token',
    name: 'SpaceX', surname: 'Admin'
  },
  {
    id: 'USR_SPACE_X_MGR', email: 'spacex-mgr@example.com', password: 'robotics', token: 'space_x_mgr_token',
    name: 'SpaceX', surname: 'Manager'
  },
  {
    id: 'USR_SPACE_X_OPR', email: 'spacex-opr@example.com', password: 'robotics', token: 'space_x_opr_token',
    name: 'SpaceX', surname: 'Operator'
  },
];

