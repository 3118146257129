import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

const FacilityNotSelected = () => {
  const navigate = useNavigate();

  return (
    <Result
      status="warning"
      title="Facility is not selected"
      extra={[
        <Button type="primary" onClick={() => navigate("/facilities")} key="no_facility">
          Select it
        </Button>,
      ]}
    />
  );
};

export default FacilityNotSelected;
