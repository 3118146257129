import {PageHeader} from "antd";
import DeviceConfigurationForm from "../../../components/deviceConfigurations/DeviceConfigurationForm";
import {useNavigate, useParams} from "react-router-dom";
import AuthorizePage from "../../../components/authorize/AuthorizePage";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {RootState} from "../../../store";
import {useEffect} from "react";
import {view} from "../../../store/devicesActions";
import ShowPageError, {isPageError} from "../../../components/ShowPageError";

const DeviceConfigurationNew = () => {
  const navigate = useNavigate();
  const {deviceId} = useParams();

  return <>
    <PageHeader title={`New Custom Device Configuration for device ${deviceId}`}
                onBack={() => navigate(`/devices/${deviceId}`)}/>
    <DeviceConfigurationForm deviceId={deviceId!}/>
  </>;
}

export default () => {
  const dispatch = useAppDispatch();
  const {deviceId} = useParams();
  const device = useAppSelector((state: RootState) => state.devices.item);
  const loading = useAppSelector((state: RootState) => state.devices.loading) || !device;
  const error = useAppSelector((state: RootState) => state.devices.error);

  useEffect(() => {
    dispatch(view(deviceId!));
  }, [deviceId, dispatch]);

  if (isPageError(error)) {
    return <ShowPageError error={error!}/>
  }

  return <AuthorizePage subject='DeviceConfiguration' parentId={device?.organizationId} action='create'
                        loading={loading}>
    <DeviceConfigurationNew/>
  </AuthorizePage>
}

