import {useState} from "react";
import {PageHeader, Skeleton} from "antd";
import UserForm from "../../components/users/UserForm";
import {useEffect} from "react";
import {view} from "../../store/usersActions";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {useNavigate, useParams} from "react-router-dom";
import {RootState} from "../../store";
import AuthorizePage from "../../components/authorize/AuthorizePage";
import ShowPageError, {isPageError} from "../../components/ShowPageError";

const UsersEdit = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state: RootState) => state.users.item);
  const error = useAppSelector((state: RootState) => state.users.error);
  const [loaded, setLoaded] = useState(false)
  const {id} = useParams();

  useEffect(() => {
    dispatch(view(id!, () => setLoaded(true)));
  }, [id, dispatch]);

  if (isPageError(error)) {
    return <ShowPageError error={error!}/>
  }

  return <>
    <PageHeader title={`Edit User ${loaded && user ? `${user.name} ${user.surname}` : ''}`}
                onBack={() => navigate('/users')}
    />
    {loaded ?
      <UserForm user={user}/> :
      <Skeleton active={true}/>
    }
  </>;
}

export default () => {
  const {id} = useParams();
  return <AuthorizePage subject='User' subjectId={id} action='update'>
    <UsersEdit/>
  </AuthorizePage>
}
