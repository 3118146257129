import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { RootState } from "../../store";
import { view, destroy } from "../../store/floorsActions";
import { Descriptions, PageHeader, Skeleton } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { notifySuccess } from "../../components/ui/notifications";
import MultiLineText from "../../components/ui/MultiLineText";
import { Floor } from "../../store/models";
import AuthorizePage from "../../components/authorize/AuthorizePage";
import ShowPageError, { isPageError } from "../../components/ShowPageError";
import ModelDescriptions from "../../components/ui/ModelDescriptions";
import DeleteButton from "../../components/buttons/DeleteButton";
import UpdateButton from "../../components/buttons/UpdateButton";
import FacilityLink from "../../components/links/FacilityLink";

const DeleteFloorButton = ({ floor }: { floor: Floor }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentFacility = useAppSelector((state: RootState) => state.selection.currentFacility);
  const confirm = () => {
    dispatch(
      destroy(floor.id, () => {
        navigate("/floors");
        notifySuccess(`Floor ${floor.name} is deleted`);
      })
    );
  };
  return <DeleteButton subject="Floor" parentId={currentFacility?.id} onConfirm={confirm} subjectName={floor.name} />;
};

const FloorsView = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const floor = useAppSelector((state: RootState) => state.floors.item);
  const error = useAppSelector((state: RootState) => state.floors.error);
  const loading = useAppSelector((state: RootState) => state.floors.loading) || !floor;

  useEffect(() => {
    dispatch(view(id!, { include: "facilities" }));
  }, [id, dispatch]);

  if (isPageError(error)) {
    return <ShowPageError error={error!} />;
  }

  return (
    <>
      <PageHeader
        title={`Floor ${!loading && floor ? floor.name : ""}`}
        onBack={() => navigate("/floors")}
        extra={
          floor
            ? [
                <UpdateButton key="u" subject="Floor" subjectId={floor.id} parentId={floor?.facilityId} subjectName={floor.name} />,
                <DeleteFloorButton key="d" floor={floor} />,
              ]
            : []
        }
      />
      {loading ? (
        <Skeleton active={true} />
      ) : (
        <ModelDescriptions title="Details">
          <Descriptions.Item label="Name">{floor!.name}</Descriptions.Item>
          <Descriptions.Item label="Facility">
            <FacilityLink model={floor} />
          </Descriptions.Item>
          <Descriptions.Item label="Notes">
            <MultiLineText text={floor!.notes} />
          </Descriptions.Item>
        </ModelDescriptions>
      )}
    </>
  );
};

export default () => {
  const { id } = useParams();
  const currentFacility = useAppSelector((state: RootState) => state.selection.currentFacility);
  return (
    <AuthorizePage subject="Floor" subjectId={id} parentId={currentFacility?.id} action="read">
      <FloorsView />
    </AuthorizePage>
  );
};
