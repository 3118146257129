import moment from "moment";
import { Device } from "../store/models";
import { Subjects } from "../lib/permissions";

const inflection = require("inflection");

export function humanizedShortTimestamp(ts: string): string {
  return moment(ts).format("YY-MM-DD HH:mm:ss");
}

export function deviceName(device?: Device): string {
  return device ? device.alias || device.id || "" : "";
}

export function humanizeSubject(subject: Subjects, { plural }: { plural?: boolean } = {}): string {
  let mainWord = inflection.underscore(subject).split("_").pop();
  if (plural) {
    mainWord = inflection.pluralize(mainWord);
  }
  return inflection.humanize(mainWord, { low_first_letter: true });
}

export function formatNumberWithSpaces(num: number): string {
  let roundedNum = Math.round(num);
  return roundedNum.toLocaleString("en-US", { useGrouping: true, minimumFractionDigits: 0 }).replace(/,/g, " ");
}
export function secondsToHoursMinutes(seconds: number, includeDays: boolean = false): string {
  if (isNaN(seconds) || seconds < 0) {
    throw new Error("Invalid input. Seconds must be a non-negative number.");
  }

  const days = Math.floor(seconds / 86400); // 86400 seconds in a day (60 seconds/minute * 60 minutes/hour * 24 hours/day)
  const remainingSecondsAfterDays = seconds % 86400;

  const hours = Math.floor(remainingSecondsAfterDays / 3600);
  const remainingSeconds = remainingSecondsAfterDays % 3600;

  const minutes = Math.floor(remainingSeconds / 60);

  const DD = days.toString().padStart(2, "0");
  const HH = hours.toString().padStart(2, "0");
  const MM = minutes.toString().padStart(2, "0");

  return includeDays ? `${DD}:${HH}:${MM}` : `${HH}:${MM}`;
}
