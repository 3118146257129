import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { RootState } from "../../store";
import moment from "moment/moment";
import { index } from "../../store/machinesActions";
import { Empty, PageHeader, Skeleton, Space, Table, Input, Typography } from "antd";
import { Link, useSearchParams } from "react-router-dom";
import { Machine } from "../../store/models";
import AuthorizePage from "../../components/authorize/AuthorizePage";
import CreateButton from "../../components/buttons/CreateButton";
import UpdateButton from "../../components/buttons/UpdateButton";

type DataSource = Machine[];

const PAGE_SIZE = 10;

function buildColumns() {
  return [
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
      width: 200,
      render: (text: string, machine: Machine) => <Link to={machine.id}>{text}</Link>,
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer",
      key: "manufacturer",
    },
    {
      title: "Manufactured Date",
      dataIndex: "manufacturedDate",
      key: "manufacturedDate",
      render: (_: any, machine: Machine) => moment.utc(machine.manufacturedDate.toISOString()).local().format("DD.MM.YYYY HH:mm"),
    },
    {
      title: "Actions",
      key: "action",
      width: 100,
      render: (_: any, machine: Machine) => (
        <Space size="middle">
          <UpdateButton subject="Machine" subjectId={machine.id} subjectName={machine.model} routePrefix={`${machine.id}/`} />
        </Space>
      ),
    },
  ];
}

const MachinesIndex = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const machines = useAppSelector((state: RootState) => state.machines.list);
  const pagination = useAppSelector((state: RootState) => state.machines.pagination);
  const loading = useAppSelector((state: RootState) => state.machines.loading);
  const [page, setPage] = useState(Number(searchParams.get("page")) || 1);
  const [search, setSearch] = useState("");
  let dataSource: DataSource = [];
  if (!loading && machines) {
    dataSource = machines.map(({ id, model, manufacturer, manufacturedDate }: Machine) => {
      return { key: id, id, model, manufacturer, manufacturedDate };
    });
  }

  useEffect(() => {
    dispatch(index(page - 1, PAGE_SIZE, search));
  }, [page, dispatch, search]);

  const pageChangeHandler = (page: number) => {
    setPage(page);
    setSearchParams({ page: page.toString() });
  };
  const columns = buildColumns();

  const { Search } = Input;
  const { Paragraph } = Typography;
  const onSearch = (value: string) => {
    setSearch(value);
  };
  return (
    <>
      <PageHeader title={`Machines (${pagination.total})`} extra={[<CreateButton key="c" subject="Machine" />]} />
      <Paragraph>
        <Search placeholder="Search" onSearch={onSearch} style={{ width: 200 }} />
      </Paragraph>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={{
          current: page,
          total: pagination.total,
          defaultPageSize: PAGE_SIZE,
          onChange: pageChangeHandler,
        }}
        locale={{ emptyText: loading ? <Skeleton active={true} /> : <Empty /> }}
      />
    </>
  );
};

export default () => {
  return (
    <AuthorizePage subject="Machine" action="read">
      <MachinesIndex />
    </AuthorizePage>
  );
};
