import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { RootState } from "../../../store";
import { historyActions } from "../../../store/historySlice";
import { getHistorySum } from "../../../store/historyActions";
import { Empty, PageHeader, Skeleton, DatePicker, Button, Space } from "antd";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import AuthorizePage from "../../../components/authorize/AuthorizePage";
import { view } from "../../../store/devicesActions";
import ShowPageError, { isPageError } from "../../../components/ShowPageError";
import HistorySum from "../../../components/history/HistorySum";
import { HistoryType } from "../../../store/models";

const DeviceHistoryIndex = () => {
  const { deviceId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const device = useAppSelector((state: RootState) => state.devices.item);
  const loading = useAppSelector((state: RootState) => state.deviceHistory.spacesHistoryLoading);

  const [startDateTime, setStartDateTime] = useState(moment().subtract(1, "hour"));
  const [endDateTime, setEndDateTime] = useState(moment().add(1, "hour"));

  const startChanged = (dateString: string) => {
    setStartDateTime(moment(dateString, "DD.MM.YYYY HH:mm"));
  };

  const endChanged = (dateString: string) => {
    setEndDateTime(moment(dateString, "DD.MM.YYYY HH:mm"));
  };

  const loadEpisodes = (start: moment.Moment, end: moment.Moment) => {
    dispatch(historyActions.setCleaningEpisodes([]));
    dispatch(getHistorySum(start, end, deviceId));
  };

  const getLastMonthPeriod = () => {
    const currentDate = moment();
    const start = currentDate.clone().subtract(1, "month").startOf("month");
    const end = currentDate.clone().subtract(1, "month").endOf("month");

    setStartDateTime(start);
    setEndDateTime(end);
    loadEpisodes(start, end);
  };

  const getCurrentMonthPeriod = () => {
    const currentDate = moment();
    const start = currentDate.clone().startOf("month");
    const end = currentDate.clone().endOf("month");

    setStartDateTime(start);
    setEndDateTime(end);
    loadEpisodes(start, end);
  };

  const getLastWeekPeriod = () => {
    const currentDate = moment();
    const start = currentDate.clone().subtract(1, "week").startOf("week");
    const end = currentDate.clone().subtract(1, "week").endOf("week");

    setStartDateTime(start);
    setEndDateTime(end);
    loadEpisodes(start, end);
  };

  const getThisWeekPeriod = () => {
    const currentDate = moment();
    const start = currentDate.clone().startOf("week");
    const end = currentDate.clone().endOf("week");

    setStartDateTime(start);
    setEndDateTime(end);
    loadEpisodes(start, end);
  };

  const getYesterdayPeriod = () => {
    const currentDate = moment();
    const start = currentDate.clone().subtract(1, "day").startOf("day");
    const end = currentDate.clone().subtract(1, "day").endOf("day");

    setStartDateTime(start);
    setEndDateTime(end);
    loadEpisodes(start, end);
  };

  const getTodayPeriod = () => {
    const currentDate = moment();
    const start = currentDate.clone().startOf("day");
    const end = currentDate.clone().endOf("day");

    setStartDateTime(start);
    setEndDateTime(end);
    loadEpisodes(start, end);
  };

  return (
    <>
      <PageHeader title={`History for ${device?.alias}`} onBack={() => navigate(`/devices/${deviceId}`)} />
      <Space direction="vertical" style={{ width: "100%" }}>
        <Space direction="horizontal">
          Start:{" "}
          <DatePicker
            format="DD.MM.YYYY HH:mm"
            showTime
            value={startDateTime}
            onChange={(value, valueString) => {
              startChanged(valueString);
            }}
            allowClear={false}
          />
          End:{" "}
          <DatePicker
            format="DD.MM.YYYY HH:mm"
            showTime
            value={endDateTime}
            onChange={(value, valueString) => {
              endChanged(valueString);
            }}
            allowClear={false}
          />
          <Button
            type="primary"
            disabled={loading}
            onClick={() => {
              loadEpisodes(startDateTime, endDateTime);
            }}
          >
            {" "}
            Load
          </Button>
        </Space>
        <Space direction="horizontal">
          <Button type="primary" disabled={loading} onClick={getLastMonthPeriod}>
            {" "}
            Last Month
          </Button>
          <Button type="primary" disabled={loading} onClick={getCurrentMonthPeriod}>
            {" "}
            This Month
          </Button>
          <Button type="primary" disabled={loading} onClick={getLastWeekPeriod}>
            {" "}
            Last Week
          </Button>
          <Button type="primary" disabled={loading} onClick={getThisWeekPeriod}>
            {" "}
            This Week
          </Button>
          <Button type="primary" disabled={loading} onClick={getYesterdayPeriod}>
            {" "}
            Yesterday
          </Button>
          <Button type="primary" disabled={loading} onClick={getTodayPeriod}>
            {" "}
            Today
          </Button>
        </Space>

        <HistorySum id={deviceId ?? ""} type={HistoryType.Device} />
      </Space>
    </>
  );
};

export default () => {
  const dispatch = useAppDispatch();
  const { deviceId } = useParams();
  const device = useAppSelector((state: RootState) => state.devices.item);
  const loading = useAppSelector((state: RootState) => state.devices.loading) || !device;
  const error = useAppSelector((state: RootState) => state.devices.error);

  useEffect(() => {
    dispatch(historyActions.setSpaceHistory({ spaces: [] }));
  }, []);

  useEffect(() => {
    dispatch(view(deviceId!));
  }, [deviceId, dispatch]);

  if (isPageError(error)) {
    return <ShowPageError error={error!} />;
  }

  return (
    <AuthorizePage subject="DeviceHistory" parentId={device?.organizationId} action="read" loading={loading}>
      <DeviceHistoryIndex />
    </AuthorizePage>
  );
};
