import { PageHeader } from "antd";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../hooks";
import { RootState } from "../../../store";
import AuthorizePage from "../../../components/authorize/AuthorizePage";
import SpaceNotSelected from "../../../components/SpaceNotSelected";
import MapFileForm from "../../../components/maps/MapFileForm";

const MapsFilesNew = ({ mapId }: { mapId: string }) => {
  const navigate = useNavigate();
  const currentSpace = useAppSelector((state: RootState) => state.selection.currentSpace);

  if (!currentSpace) {
    return <SpaceNotSelected />;
  }

  return (
    <>
      <PageHeader title="New files" onBack={() => navigate(`/maps/${mapId}`)} />
      <MapFileForm mapId={mapId} />
    </>
  );
};

export default () => {
  const currentFacility = useAppSelector((state: RootState) => state.selection.currentFacility);
  const currentMap = useAppSelector((state: RootState) => state.selection.currentMap);
  return (
    <AuthorizePage subject="Map" parentId={currentFacility?.id} action="create">
      <MapsFilesNew mapId={currentMap!.id} />
    </AuthorizePage>
  );
};
