import { Form, Input } from "antd";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { save } from "../../store/spacesActions";
import { RootState } from "../../store";
import { notifySuccess } from "../ui/notifications";
import ModelForm from "../ui/ModelForm";
import { Space } from "../../store/models";

type SpaceData = {
  name: string;
  notes: string;
};

const SpaceForm = ({ space }: { space?: Space }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading = useAppSelector((state: RootState) => state.spaces.loading);
  const error = useAppSelector((state: RootState) => state.spaces.error);
  const floorId = useAppSelector((state: RootState) => state.selection.currentFloor!.id);

  const onFinishHandler = (data: SpaceData) => {
    const action = space?.id ? "updated" : "created";
    dispatch(
      save({ ...data, id: space?.id, floorId }, (space) => {
        navigate(`/spaces/${space.id}`);
        notifySuccess(`Space ${space!.name} is ${action}`);
      })
    );
  };

  const newSpace = !space?.id;
  return (
    <ModelForm isNew={newSpace} loading={loading} initialValues={{ ...space }} onFinish={onFinishHandler} error={error}>
      <Form.Item name="name" label="Name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="notes" label="Notes" rules={[{ required: true }]}>
        <Input.TextArea />
      </Form.Item>
    </ModelForm>
  );
};

export default SpaceForm;
