import { Link } from "react-router-dom";
import { Floor } from "../../store/models";

type ModelWithFloor = {
  floorId?: string;
  floor?: Floor;
};

const FloorLink = ({ model, withModelName }: { model: ModelWithFloor; withModelName?: boolean }) => {
  if (!model.floorId) {
    return null;
  }
  return (
    <Link to={`/floors/${model.floorId}`}>
      {withModelName ? "Floor " : ""}
      {model.floor?.name ?? model.floorId}
    </Link>
  );
};

export default FloorLink;
