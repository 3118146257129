import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppError, Permission} from "./models";

type PermissionsState = {
  loading: boolean
  error?: AppError
  list?: Permission[]
  item?: Permission
}

const initialState: PermissionsState = {
  loading: false,
  error: undefined,
  list: undefined,
}

const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    loading(state) {
      state.loading = true;
    },
    loaded(state) {
      state.loading = false;
      state.error = undefined;
    },
    error(state, action: PayloadAction<AppError>) {
      state.loading = false;
      state.error = action.payload;
    },
    index(state, action: PayloadAction<{ permissions: Permission[]}>) {
      state.list = action.payload.permissions;
    },
    view(state, action: PayloadAction<Permission>) {
      state.item = action.payload;
    },
  }
});

export const permissionsActions = permissionsSlice.actions;
export default permissionsSlice;
