import { Dispatch } from "redux";
import { authGet, authPost, authPut, authDelete } from "./client";
import { floorsActions } from "./floorsSlice";
import processError from "./processError";
import { Floor, FloorIncluded, Pagination } from "./models";
import { assignIncludedFacilities } from "./assignIncluded";

type FloorsResponse = {
  floors: Floor[];
  pagination: Pagination;
};

type FloorResponse = {
  floor: Floor;
  included?: FloorIncluded;
};

type SaveData = {
  id?: string;
  facilityId: string;
  name: string;
  notes: string;
};

export const index = (pageIndex: number, pageSize: number | undefined, facilityId: string, search?: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(floorsActions.loading());
    const { data }: { data: FloorsResponse } = await authGet("floors", { facilityId, pageIndex, pageSize, search });
    dispatch(floorsActions.index({ floors: data.floors, pagination: data.pagination }));
  } catch (error: any) {
    dispatch(floorsActions.index({ floors: [], pagination: { index: 0, total: 0 } }));
    processError(error, dispatch, floorsActions);
  } finally {
    dispatch(floorsActions.loaded());
  }
};

type viewOpt = { include?: string };
export const view =
  (id: string, { include }: viewOpt, onSuccess?: (floor: Floor) => void) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(floorsActions.loading());
      const { data }: { data: FloorResponse } = await authGet(`floors/${id}`, { include });
      const { floor, included } = data;
      dispatch(floorsActions.view(assignIncluded(floor, included)));
      onSuccess && onSuccess(data.floor);
    } catch (error: any) {
      processError(error, dispatch, floorsActions);
    } finally {
      dispatch(floorsActions.loaded());
    }
  };

export const save = (floor: SaveData, onSuccess: (floor: Floor) => void) => async (dispatch: Dispatch) => {
  try {
    dispatch(floorsActions.loading());
    const { id, ...attributes } = floor;
    const { data }: { data: FloorResponse } = id ? await authPut(`floors/${id}`, { floor: attributes }) : await authPost("floors", { floor: attributes });
    onSuccess(data.floor);
  } catch (error: any) {
    processError(error, dispatch, floorsActions);
  } finally {
    dispatch(floorsActions.loaded());
  }
};

export const destroy = (id: string, onSuccess?: () => void) => async (dispatch: Dispatch) => {
  try {
    dispatch(floorsActions.loading());
    await authDelete(`floors/${id}`);
    onSuccess && onSuccess();
  } catch (error: any) {
    processError(error, dispatch, floorsActions);
  } finally {
    dispatch(floorsActions.loaded());
  }
};

function assignIncluded(floor: Floor, included?: FloorIncluded): Floor {
  assignIncludedFacilities(floor, included);
  return floor;
}
