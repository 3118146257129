import { authGet } from "../client";
import { Space } from "../models";

type SpaceResponse = {
  space: Space;
};

export async function getSpace(id: string) {
  const { data }: { data: SpaceResponse } = await authGet(`spaces/${id}`);
  return data.space;
}
