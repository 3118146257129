import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppError, Organization, Pagination} from "./models";

type OrganizationsState = {
  loading: boolean
  error?: AppError
  list?: Organization[]
  pagination: Pagination
  item?: Organization
}

const initialState: OrganizationsState = {
  loading: false,
  error: undefined,
  list: undefined,
  item: undefined,
  pagination: {index: 0, total: 0}
}

const organizationsSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    loading(state) {
      state.loading = true;
    },
    loaded(state) {
      state.loading = false;
      state.error = undefined;
    },
    error(state, action: PayloadAction<AppError>) {
      state.loading = false;
      state.error = action.payload;
    },
    index(state, action: PayloadAction<{ organizations: Organization[], pagination: Pagination }>) {
      state.list = action.payload.organizations;
      state.pagination = action.payload.pagination;
    },
    view(state, action: PayloadAction<Organization>) {
      state.item = action.payload;
    },
  }
});

export const organizationsActions = organizationsSlice.actions;
export default organizationsSlice;
