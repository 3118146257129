import {NavigateFunction} from "react-router/lib/hooks";
import {
  lcAuthToken, lcLoginAsToken, lcRemoveLoginAsToken, lcSetAuthToken, lcSetCurrentOrganizationId,
  lcSetLoginAsToken
} from "../lib/storage";
import {User} from "../store/models";
import {login} from "../store/authActions";
import {AppDispatch} from "../store";

export function isDevelopment(): boolean {
  return process.env.NODE_ENV === 'development';
}

function notDevelopment(): boolean {
  return !isDevelopment();
}

export function execDevLoginAs(user: User, dispatch: AppDispatch): boolean {
  if (notDevelopment()) {
    return false;
  }

  lcSetLoginAsToken(lcAuthToken()!);
  dispatch(login(user.email, 'password', () => window.location.replace("/")));
  return true
}

export function execDevLogout(navigate: NavigateFunction, currentOrganizationId: string | null): boolean {
  if (notDevelopment()) {
    return false;
  }

  let loginAsToken = lcLoginAsToken();
  if (loginAsToken) {
    lcSetAuthToken(loginAsToken || '')
    lcRemoveLoginAsToken()
    if (currentOrganizationId) {
      lcSetCurrentOrganizationId(currentOrganizationId);
    }
    window.location.replace("/users")
  } else {
    navigate("/", {replace: true});
  }

  return true;
}
