import { Dispatch } from "redux";
import { authGet, authPost, authPut, authDelete } from "./client";
import { spacesActions } from "./spacesSlice";
import processError from "./processError";
import { Space, SpaceIncluded, Pagination } from "./models";
import { assignIncludedCurrentMap, assignIncludedFloors } from "./assignIncluded";
import { forEach } from "lodash";

type SpacesResponse = {
  spaces: Space[];
  pagination: Pagination;
  included?: SpaceIncluded;
};

type SpaceResponse = {
  space: Space;
  included?: SpaceIncluded;
};

type SaveData = {
  id?: string;
  floorId: string;
  name: string;
  notes: string;
};
type viewOpt = { include?: string };

export const index =
  (pageIndex: number, pageSize: number | undefined, floorId: string, { include }: viewOpt, search?: string) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(spacesActions.loading());
      const { data }: { data: SpacesResponse } = await authGet("spaces", { floorId, pageIndex, pageSize, include, search });
      data.spaces.forEach((space) => {
        assignIncluded(space, data.included);
      });
      dispatch(spacesActions.index({ spaces: data.spaces, pagination: data.pagination }));
    } catch (error: any) {
      dispatch(spacesActions.index({ spaces: [], pagination: { index: 0, total: 0 } }));
      processError(error, dispatch, spacesActions);
    } finally {
      dispatch(spacesActions.loaded());
    }
  };

export const view =
  (id: string, { include }: viewOpt, onSuccess?: (space: Space) => void) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(spacesActions.loading());
      const { data }: { data: SpaceResponse } = await authGet(`spaces/${id}`, { include });
      const { space, included } = data;
      dispatch(spacesActions.view(assignIncluded(space, included)));
      onSuccess && onSuccess(data.space);
    } catch (error: any) {
      processError(error, dispatch, spacesActions);
    } finally {
      dispatch(spacesActions.loaded());
    }
  };

export const save = (space: SaveData, onSuccess: (space: Space) => void) => async (dispatch: Dispatch) => {
  try {
    dispatch(spacesActions.loading());
    const { id, ...attributes } = space;
    const { data }: { data: SpaceResponse } = id ? await authPut(`spaces/${id}`, { space: attributes }) : await authPost("spaces", { space: attributes });

    onSuccess(data.space);
  } catch (error: any) {
    processError(error, dispatch, spacesActions);
  } finally {
    dispatch(spacesActions.loaded());
  }
};

export const destroy = (id: string, onSuccess?: () => void) => async (dispatch: Dispatch) => {
  try {
    dispatch(spacesActions.loading());
    await authDelete(`spaces/${id}`);
    onSuccess && onSuccess();
  } catch (error: any) {
    processError(error, dispatch, spacesActions);
  } finally {
    dispatch(spacesActions.loaded());
  }
};

function assignIncluded(space: Space, included?: SpaceIncluded): Space {
  assignIncludedFloors(space, included);
  assignIncludedCurrentMap(space, included);
  return space;
}
