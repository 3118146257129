import {notification} from "antd";
import MultiLineText from "./MultiLineText";

type notifyOptions = {
  key?: string
}

export function notifySuccess(message: string) {
  notification['success']({
    message: message,
    placement: 'top'
  });
}

export function notifyError(message: string, description: string = '', opt: notifyOptions = {}) {
  notification['error']({
    key: opt.key,
    message: message,
    description: <MultiLineText text={description}/>,
    duration: null,
  });
}
