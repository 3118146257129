import { useState, useEffect } from "react";
import { PageHeader, Skeleton } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { view } from "../../../store/mapFilesActions";
import { useAppSelector, useAppDispatch } from "../../../hooks";
import { RootState } from "../../../store";
import AuthorizePage from "../../../components/authorize/AuthorizePage";
import MapFileForm from "../../../components/maps/MapFileForm";
import ShowPageError, { isPageError } from "../../../components/ShowPageError";

const MapsEdit = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const file = useAppSelector((state: RootState) => state.mapFiles.item);
  const error = useAppSelector((state: RootState) => state.mapFiles.error);
  const [loaded, setLoaded] = useState(false);
  const { id, fileId } = useParams();

  useEffect(() => {
    dispatch(view(fileId!, id!, {}, () => setLoaded(true)));
  }, [id, dispatch]);

  if (isPageError(error)) {
    return <ShowPageError error={error!} />;
  }

  return (
    <>
      <PageHeader title={`Edit file `} onBack={() => navigate(`/maps/${id}`)} />
      {loaded ? <MapFileForm mapId={id!} file={file} /> : <Skeleton active={true} />}
    </>
  );
};

export default () => {
  const { id } = useParams();
  const currentFacility = useAppSelector((state: RootState) => state.selection.currentFacility);
  return (
    <AuthorizePage subject="Map" subjectId={id} parentId={currentFacility?.id} action="update">
      <MapsEdit />
    </AuthorizePage>
  );
};
