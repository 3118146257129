import { Dispatch } from "redux";
import { authGet, authPost, authPut, authDelete } from "./client";
import { organizationsActions } from "./organizationsSlice";
import processError from "./processError";
import { Organization, Pagination } from "./models";
import { getOrganization } from "./api/organization";

type OrganizationsResponse = {
  organizations: Organization[];
  pagination: Pagination;
};

type OrganizationResponse = {
  organization: Organization;
};

type SaveData = {
  id?: string;
  name: string;
  notes: string;
};

export const index = (pageIndex: number, pageSize: number, search: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(organizationsActions.loading());
    const { data }: { data: OrganizationsResponse } = await authGet("organizations", { pageIndex, pageSize, search });
    dispatch(organizationsActions.index({ organizations: data.organizations, pagination: data.pagination }));
  } catch (error: any) {
    dispatch(organizationsActions.index({ organizations: [], pagination: { index: 0, total: 0 } }));
    processError(error, dispatch, organizationsActions);
  } finally {
    dispatch(organizationsActions.loaded());
  }
};

export const view = (id: string, onSuccess?: (organization: Organization) => void) => async (dispatch: Dispatch) => {
  try {
    dispatch(organizationsActions.loading());
    const organization = await getOrganization(id);
    dispatch(organizationsActions.view(organization));
    onSuccess && onSuccess(organization);
  } catch (error: any) {
    processError(error, dispatch, organizationsActions);
  } finally {
    dispatch(organizationsActions.loaded());
  }
};

export const save = (organization: SaveData, onSuccess: (organization: Organization) => void) => async (dispatch: Dispatch) => {
  try {
    dispatch(organizationsActions.loading());
    const { id, ...attributes } = organization;
    const { data }: { data: OrganizationResponse } = id
      ? await authPut(`organizations/${id}`, { organization: attributes })
      : await authPost("organizations", { organization: attributes });
    onSuccess(data.organization);
  } catch (error: any) {
    processError(error, dispatch, organizationsActions);
  } finally {
    dispatch(organizationsActions.loaded());
  }
};

export const destroy = (id: string, onSuccess?: () => void) => async (dispatch: Dispatch) => {
  try {
    dispatch(organizationsActions.loading());
    await authDelete(`organizations/${id}`);
    onSuccess && onSuccess();
  } catch (error: any) {
    processError(error, dispatch, organizationsActions);
  } finally {
    dispatch(organizationsActions.loaded());
  }
};
