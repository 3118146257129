import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Permission, CurrentUser} from "./models";
import {lcAuthToken} from "../lib/storage";

type LoginAction = {
  token: string
}

// TODO: make it reusable for other actions
type AuthError = {
  fromBackend: boolean
  code: number
  message: string
}

type AuthState = {
  loading: boolean
  authenticated: boolean
  token?: string
  currentUser?: CurrentUser
  permissions: Permission[]
  error?: AuthError
}

const token = lcAuthToken() ?? undefined;
const initialState: AuthState = {
  loading: false,
  error: undefined,
  authenticated: !!token,
  token: token,
  currentUser: undefined,
  permissions: []
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loading(state) {
      state.loading = true;
    },
    loaded(state) {
      state.loading = false;
      state.error = undefined;
    },
    error(state, action: PayloadAction<AuthError>) {
      state.loading = false;
      state.error = action.payload;
    },
    login(state, action: PayloadAction<LoginAction>) {
      state.authenticated = true;
      state.token = action.payload.token;
    },
    logout(state) {
      state.authenticated = false;
      state.token = undefined;
      state.currentUser = undefined;
      state.error = undefined;
    },
    currentUser(state, action: PayloadAction<{ user: CurrentUser, permissions: Permission[] }>) {
      state.currentUser = action.payload.user;
      state.permissions = action.payload.permissions;
    }
  }
});

export const authActions = authSlice.actions;
export default authSlice;
