import {Button, Form, Input, InputRef, Modal} from "antd";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {useNavigate} from "react-router-dom";
import {save, updatePassword} from "../../store/usersActions";
import {RootState} from "../../store";
import {notifySuccess} from "../ui/notifications";
import {User} from "../../store/models";
import ModelForm from "../ui/ModelForm";
import {useRef, useState} from "react";

type UserData = {
  name: string
  surname: string
  email: string
  password?: string
}

const UserForm = ({user}: { user?: User }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate();
  const loading = useAppSelector((state: RootState) => state.users.loading);
  const error = useAppSelector((state: RootState) => state.users.error);
  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false);
  const passwordInput = useRef<InputRef>(null);

  const onFinishHandler = (data: UserData) => {
    let isNew = !user?.id;
    const action = isNew ? 'created': 'updated';
    dispatch(save({...data, id: user?.id}, (user) => {
      navigate(isNew ? `/users/${user.id}/permissions` : `/users/${user.id}`);
      notifySuccess(`User ${user!.name} ${user!.surname} is ${action}`);
    }));
  };

  const openPasswordModal = () => {
    const newPassword = passwordInput.current!.input!.value;
    if (newPassword && !loading) {
      dispatch(updatePassword(user!.id, newPassword!, () => {
        notifySuccess(`User ${user!.name} ${user!.surname} password is updated`);
      }));
    }
    closePasswordModal();
  };
  const closePasswordModal = () => {
    setIsPasswordModalVisible(false);
  };

  const newUser = !user?.id
  return <>
    <ModelForm isNew={newUser} loading={loading} initialValues={{...user}} onFinish={onFinishHandler} error={error}>
      <Form.Item label="Email" name="email" rules={[{required: true}]}>
        <Input/>
      </Form.Item>
      <Form.Item label="Given name" name="name" rules={[{required: true}]}>
        <Input/>
      </Form.Item>
      <Form.Item label="Surname" name="surname" rules={[{required: true}]}>
        <Input/>
      </Form.Item>
      {newUser ?
        <Form.Item label="Password" name="password" rules={[{required: !user?.id}]}>
          <Input.Password/>
        </Form.Item> :
        <Form.Item label="Password" name="password" rules={[{required: !user?.id}]}>
          <Button onClick={() => setIsPasswordModalVisible(true)}>Change</Button>
        </Form.Item>
      }
    </ModelForm>
    <Modal title="Change password" visible={isPasswordModalVisible} onOk={openPasswordModal} okText="Update"
           onCancel={closePasswordModal}>
      <Form.Item label="New password" name="newPassword" rules={[{required: true}]}>
        <Input.Password ref={passwordInput}/>
      </Form.Item>
    </Modal>
  </>
}

export default UserForm;
