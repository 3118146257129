import {authGet} from "../client";
import {Facility} from "../models";

// todo: move all other facility related api calls to this module

type FacilityResponse = {
  facility: Facility
}

export async function getFacility(id: string) {
  const {data}: { data: FacilityResponse } = await authGet(`facilities/${id}`);
  return data.facility;
}
