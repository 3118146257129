import {PageHeader, Skeleton} from "antd";

const PageLoading = () => {
  return <>
    <PageHeader title="Please wait"/>
    <Skeleton active={true}/>
  </>
}

export default PageLoading;
