import { useEffect, useState } from "react";
import moment from "moment";
import { Space as ASpace, Card, Row, Table, Button, Spin, Image } from "antd";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Space, Map, SpaceDayHistory, SpaceHistory } from "../../store/models";
import type { ColumnsType } from "antd/es/table";
import { Pie, Bar } from "react-chartjs-2";
import { getSpaceHistory } from "../../store/historyActions";
import { formatNumberWithSpaces, secondsToHoursMinutes } from "../../helpers/formatters";

type Props = {
  spaces: Space[];
  readings: SpaceDayHistory[];
  startDateTime: moment.Moment;
  endDateTime: moment.Moment;
};
type SpaceColor = {
  id: string;
  color: string;
};

type SpaceRow = {
  name: string;
  key: string;
  id: string;
  cleaningTime: number;
  idleTime: number;
  chargingTime: number;
  drivenDistance: number;
  cleanedDistance: number;
  cleanedArea: number;
  cleanedOverlappingArea: number;
  heatmapsBase64?: string[];
  heatmapGenerating: boolean;
};

const SpacesDashboard = ({ spaces, readings, startDateTime, endDateTime }: Props) => {
  const [spaceColors, setSpaceColors] = useState<SpaceColor[]>([]);
  const [spaceRows, setSpaceRows] = useState<SpaceRow[]>([]);

  const [cleanedPieChart, setCleanedPieChart] = useState(null);
  const [cleanedTimePieChart, setCleanedTimePieChart] = useState(null);
  const [cleanedAreaSplitChart, setCleanedAreaSplitChart] = useState(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    let colors = generateUniqueRgbColors(spaces.length);
    let colorMap: SpaceColor[] = [];
    spaces.forEach((space) => {
      let idx = spaces.indexOf(space);
      let color = colors[idx];
      colorMap.push({
        color: color,
        id: space.id,
      });
    });
    setSpaceColors(colorMap);

    let resultRows: SpaceRow[] = [];

    spaces.forEach((space) => {
      let spaceEntries = readings.filter((x) => x.spaceId === space.id);

      let chargeTime = spaceEntries.reduce((sum, item) => sum + item.chargingTime, 0);
      let idleTime = spaceEntries.reduce((sum, item) => sum + item.idleTime, 0);
      let cleaningTime = spaceEntries.reduce((sum, item) => sum + item.cleaningTime, 0);

      let cleanedArea = spaceEntries.reduce((sum, item) => sum + item.cleanedArea, 0);
      let cleanedAreaOverlapping = spaceEntries.reduce((sum, item) => sum + item.cleanedAreaOverlapping, 0);
      let drivenDistance = spaceEntries.reduce((sum, item) => sum + item.drivenDistance, 0);
      let cleanedDistance = spaceEntries.reduce((sum, item) => sum + item.cleanedDistance, 0);

      let variables = [chargeTime, idleTime, cleaningTime, cleanedArea, cleanedAreaOverlapping, drivenDistance, cleanedDistance];

      // all readings are 0, no point of showing
      if (variables.every((item) => item === 0)) {
        return;
      }

      let spaceRow: SpaceRow = {
        chargingTime: chargeTime,
        cleanedArea: cleanedArea,
        cleanedDistance: cleanedDistance,
        cleanedOverlappingArea: cleanedAreaOverlapping,
        cleaningTime: cleaningTime,
        drivenDistance: drivenDistance,
        id: space.id,
        idleTime: idleTime,
        name: space.name,
        heatmapsBase64: undefined,
        heatmapGenerating: false,
        key: space.id,
      };

      resultRows.push(spaceRow);
    });

    setSpaceRows(resultRows);
  }, [spaces, readings]);

  useEffect(() => {
    generateCleanedAreaPieChart();
    generateCleaningTimePieChart();
    generateDaySplitBarChart();
  }, [spaceColors]);

  const generateCleanedAreaPieChart = () => {
    let spaceLabels = spaces.map((x) => {
      return x.name;
    });

    let spaceCleanedReadings: number[] = [];
    let colors: string[] = [];
    spaces.forEach((x) => {
      let spaceReadings = readings.filter((r) => r.spaceId === x.id);
      let spaceCleanedArea = spaceReadings.reduce((sum, item) => sum + item.cleanedAreaOverlapping, 0);
      if (spaceCleanedArea === 0) {
        spaceLabels = spaceLabels.filter((l) => l !== x.name);
        return;
      }
      colors.push(getSpaceColor(x.id));
      spaceCleanedReadings.push(spaceCleanedArea);
    });
    setCleanedPieChart({
      //@ts-ignore
      labels: spaceLabels,

      datasets: [
        {
          data: spaceCleanedReadings,
          backgroundColor: colors.map((x) => x.replace(")", ",.2)")),
          borderColor: colors.map((x) => x.replace(")", ",1)")),
          hoverBackgroundColor: colors.map((x) => x.replace(")", ",1)")),
        },
      ],
    });
  };

  const generateCleaningTimePieChart = () => {
    let spaceLabels = spaces.map((x) => {
      return x.name;
    });

    let spaceTimeReadings: number[] = [];
    let colors: string[] = [];
    spaces.forEach((x) => {
      let spaceReadings = readings.filter((r) => r.spaceId === x.id);
      let time = spaceReadings.reduce((sum, item) => sum + item.cleaningTime, 0);
      if (time === 0) {
        spaceLabels = spaceLabels.filter((l) => l !== x.name);
        return;
      }
      spaceTimeReadings.push(time);
      colors.push(getSpaceColor(x.id));
    });
    setCleanedTimePieChart({
      //@ts-ignore
      labels: spaceLabels,

      datasets: [
        {
          data: spaceTimeReadings,
          backgroundColor: colors.map((x) => x.replace(")", ",.2)")),
          borderColor: colors.map((x) => x.replace(")", ",1)")),
          hoverBackgroundColor: colors.map((x) => x.replace(")", ",1)")),
        },
      ],
    });
  };

  const generateDaySplitBarChart = () => {
    const uniqueDates: string[] = readings
      .map((obj) => obj.date) // extract datetime strings
      .filter((date, index, self) => self.indexOf(date) === index); // filter out duplicates

    const labels = uniqueDates.map((x) => {
      return moment(x).format("DD.MM.YYYY");
    });

    let datasets = spaces.map((x) => {
      let spaceReadings: number[] = [];
      uniqueDates.forEach((date) => {
        let spaceDateReadings = readings.filter((r) => r.spaceId === x.id && r.date === date);
        let cleanedSum = spaceDateReadings.reduce((sum, item) => sum + item.cleanedAreaOverlapping, 0);
        spaceReadings.push(cleanedSum);
      });
      let color = getSpaceColor(x.id);

      if (spaceReadings.some((num) => num !== 0)) {
        return {
          key: x.name,
          label: x.name,
          data: spaceReadings,
          borderColor: color.replace(")", ",0.5)"),
          backgroundColor: color.replace(")", ",0.5)"),
          stack: "combined",
          type: "bar",
        };
      }
    });
    datasets = datasets.filter((x) => x !== undefined);

    setCleanedAreaSplitChart({
      //@ts-ignore
      labels: labels,
      datasets: datasets,
    });
  };

  function generateRandomRgb(): string {
    return `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)})`;
  }

  function generateUniqueRgbColors(x: number): string[] {
    const colors = new Set<string>();

    while (colors.size < x) {
      const color = generateRandomRgb();
      colors.add(color);
    }

    return Array.from(colors);
  }

  const getSpaceColor = (id: string) => {
    let colorMap = spaceColors.find((x) => x.id === id);
    if (colorMap === undefined) {
      return generateRandomRgb();
    }
    return colorMap.color;
  };

  const generateSpaceHeatmap = (spaceRow: SpaceRow) => {
    let modifiedRows = spaceRows.map((row) => {
      if (row.id === spaceRow.id) {
        row.heatmapGenerating = true;
        dispatch(getSpaceHistory(startDateTime, endDateTime, row.id, heatmapResponse, () => heatmapResponseError(row.id)));
      }
      return row;
    });
    setSpaceRows(modifiedRows);
  };

  const heatmapResponse = (spaceHistory: SpaceHistory) => {
    let modifiedRows = spaceRows.map((row) => {
      if (row.id === spaceHistory.spaceId) {
        row.heatmapGenerating = false;
        row.heatmapsBase64 = spaceHistory.heatmapsBase64;
      }
      return row;
    });
    setSpaceRows(modifiedRows);
  };

  const heatmapResponseError = (id: string) => {
    let modifiedRows = spaceRows.map((row) => {
      if (row.id === id) {
        row.heatmapGenerating = false;
      }
      return row;
    });
    setSpaceRows(modifiedRows);
  };

  const columns: ColumnsType<SpaceRow> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      render: (name: string) => <div className="PreWrap">{name}</div>,
    },

    {
      title: "Cleaned Time (DD:HH:mm)",
      dataIndex: "cleaningTime",
      key: "cleaningTime",
      render: (data: number) => <div className="PreWrap">{secondsToHoursMinutes(data / 1000, true)}</div>,
    },
    {
      title: "Idle Time (DD:HH:mm)",
      dataIndex: "idleTime",
      key: "idleTime",
      render: (data: number) => <div className="PreWrap">{secondsToHoursMinutes(data / 1000, true)}</div>,
    },
    {
      title: "Charging Time (DD:HH:mm)",
      dataIndex: "chargingTime",
      key: "chargingTime",
      render: (data: number) => <div className="PreWrap">{secondsToHoursMinutes(data / 1000, true)}</div>,
    },
    {
      title: (
        <div>
          Cleaned Area (m<sup>2</sup>)
        </div>
      ),
      dataIndex: "cleanedArea",
      key: "cleanedArea",
      render: (data: number) => <div className="PreWrap">{formatNumberWithSpaces(data)}</div>,
    },
    {
      title: (
        <div>
          Cleaned Area Overlapping (m<sup>2</sup>)
        </div>
      ),
      dataIndex: "cleanedOverlappingArea",
      key: "cleanedOverlappingArea",
      render: (data: number) => <div className="PreWrap">{formatNumberWithSpaces(data)}</div>,
    },
    {
      title: "Heatmaps",
      dataIndex: "heatmapsBase64",
      key: "heatmapsBase64",
      render: (data: string[], space: SpaceRow) => (
        <div>
          {space.heatmapsBase64 === undefined && !space.heatmapGenerating && (
            <Button
              onClick={() => {
                generateSpaceHeatmap(space);
              }}
              type="primary"
            >
              Generate
            </Button>
          )}
          {space.heatmapsBase64 === undefined && space.heatmapGenerating && <Spin />}

          {space.heatmapsBase64 !== undefined && !space.heatmapGenerating && (
            <ASpace direction="vertical">
              {space.heatmapsBase64.map((img, i) => {
                return <Image key={`${i}`} width={200} height={200} style={{ objectFit: "contain" }} src={`data:image/png;base64, ${img}`} />;
              })}
            </ASpace>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <Card title="Spaces" bordered={true}>
        <Row>
          <Card
            style={{ width: "400px", height: "500px" }}
            title={
              <div>
                Cleaned Area (m<sup>2</sup>)
              </div>
            }
            bordered={true}
          >
            {cleanedPieChart && (
              <Pie
                data={cleanedPieChart}
                options={{
                  plugins: {
                    tooltip: {
                      callbacks: {
                        //@ts-ignore
                        label: function (tooltipItem, data) {
                          //@ts-ignore
                          return formatNumberWithSpaces(tooltipItem.raw);
                        },
                      },
                    },
                  },
                }}
              />
            )}
          </Card>

          <Card style={{ width: "400px", height: "500px" }} title={<div>Cleaning Time (DD:HH:mm)</div>} bordered={true}>
            {cleanedTimePieChart && (
              <Pie
                data={cleanedTimePieChart}
                options={{
                  plugins: {
                    tooltip: {
                      callbacks: {
                        //@ts-ignore
                        label: function (tooltipItem, data) {
                          //@ts-ignore
                          return secondsToHoursMinutes(tooltipItem.raw / 1000, true); // Add your custom conversion here
                        },
                      },
                    },
                  },
                }}
              />
            )}
          </Card>

          <Card
            style={{ width: "600px", height: "500px" }}
            title={
              <div>
                Cleaned Area (m<sup>2</sup>)
              </div>
            }
            bordered={true}
          >
            {cleanedAreaSplitChart && (
              <Bar
                data={cleanedAreaSplitChart}
                options={{
                  plugins: {
                    legend: {
                      position: "top" as const,
                      display: true,
                    },
                    tooltip: {
                      callbacks: {
                        //@ts-ignore
                        label: function (tooltipItem, data) {
                          //@ts-ignore
                          return formatNumberWithSpaces(tooltipItem.raw); // Add your custom conversion here
                        },
                      },
                    },
                  },
                  scales: {
                    y: {
                      title: {
                        display: true,
                      },
                      min: 0,
                      ticks: {
                        // forces step size to be 50 units
                        stepSize: 1000,
                      },
                    },
                  },
                }}
              />
            )}
          </Card>
        </Row>
        <Row>
          <Table style={{ width: "100%" }} dataSource={spaceRows} columns={columns} pagination={false} />
        </Row>
      </Card>
    </>
  );
};

export default SpacesDashboard;
