import {PageHeader} from "antd";
import PasswordForm from "../../components/users/PasswordForm";
import {useNavigate} from "react-router-dom";

const MePassword = () => {
  const navigate = useNavigate();

  return <>
    <PageHeader title={`Change my password`} onBack={() => navigate('/')}/>
    <PasswordForm/>
  </>;
}

export default MePassword;
