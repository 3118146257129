import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Facility, Organization, Floor, Space, Map } from "./models";

type SelectionState = {
  currentOrganization?: Organization;
  currentFacility?: Facility;
  currentFloor?: Floor;
  currentSpace?: Space;
  currentMap?: Map;
};

const initialState: SelectionState = {
  // TODO: consider to create hook like useCurrentOrganization
  currentOrganization: undefined,
  currentFacility: undefined,
  currentFloor: undefined,
  currentSpace: undefined,
};

const selectionSlice = createSlice({
  name: "organizations",
  initialState,
  reducers: {
    selectOrganization(state, action: PayloadAction<Organization>) {
      state.currentOrganization = action.payload;
      state.currentFacility = undefined;
      state.currentFloor = undefined;
      state.currentSpace = undefined;
    },
    selectFacility(state, action: PayloadAction<Facility>) {
      state.currentFacility = action.payload;
      state.currentFloor = undefined;
      state.currentSpace = undefined;
    },
    selectFloor(state, action: PayloadAction<Floor>) {
      state.currentFloor = action.payload;
      state.currentSpace = undefined;
    },
    selectSpace(state, action: PayloadAction<Space>) {
      state.currentSpace = action.payload;
    },
    selectMap(state, action: PayloadAction<Map>) {
      state.currentMap = action.payload;
    },
    clearOrganization(state) {
      state.currentOrganization = undefined;
      state.currentFacility = undefined;
    },
    clearFacility(state) {
      state.currentFacility = undefined;
    },
    clearFloor(state) {
      state.currentFloor = undefined;
    },
    clearSpace(state) {
      state.currentSpace = undefined;
    },
    clearMap(state) {
      state.currentSpace = undefined;
    },
  },
});

export const selectionActions = selectionSlice.actions;
export default selectionSlice;
