import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

const OrganizationNotSelected = () => {
  const navigate = useNavigate();

  return (
    <Result
      status="warning"
      title="Organization is not selected"
      extra={[
        <Button key={"selectOrg"} type="primary" onClick={() => navigate("/organizations")}>
          Select it
        </Button>,
      ]}
    />
  );
};

export default OrganizationNotSelected;
