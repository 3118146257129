import {Select} from "antd";
import {DefaultOptionType, FilterFunc} from "rc-select/lib/Select";

type Props = {
  placeholder?: string
  disabled?: boolean
  width?: number
  onChange?: (value: string, option: DefaultOptionType | DefaultOptionType[]) => void
  children: React.ReactNode
}
const SearchableSelect = ({placeholder, disabled, width, children, onChange}: Props) => {
  const style: { width?: number } = {};
  if (width) {
    style.width = width;
  }
  const filterOption: FilterFunc<DefaultOptionType> = (input, option) => {
    return (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase());
  }
  return <Select allowClear showSearch
                 placeholder={placeholder} style={style} disabled={disabled} onChange={onChange}
                 filterOption={filterOption}>
    {children}
  </Select>
}

export default SearchableSelect;
