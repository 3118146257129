import { useState } from "react";
import { PageHeader, Skeleton } from "antd";
import { useEffect } from "react";
import { view } from "../../store/spacesActions";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../store";
import AuthorizePage from "../../components/authorize/AuthorizePage";
import ShowPageError, { isPageError } from "../../components/ShowPageError";
import FloorNotSelected from "../../components/FloorNotSelected";
import SpaceForm from "../../components/spaces/SpaceForm";

const SpacesEdit = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const space = useAppSelector((state: RootState) => state.spaces.item);
  const error = useAppSelector((state: RootState) => state.spaces.error);
  const currentFloor = useAppSelector((state: RootState) => state.selection.currentFloor);
  const [loaded, setLoaded] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    dispatch(view(id!, {}, () => setLoaded(true)));
  }, [id, dispatch]);

  if (!currentFloor) {
    return <FloorNotSelected />;
  }

  if (isPageError(error)) {
    return <ShowPageError error={error!} />;
  }

  return (
    <>
      <PageHeader title={`Edit Space ${loaded && space ? space.name : ""}`} onBack={() => navigate("/spaces")} />
      {loaded ? <SpaceForm space={space} /> : <Skeleton active={true} />}
    </>
  );
};

export default () => {
  const { id } = useParams();
  const currentFacility = useAppSelector((state: RootState) => state.selection.currentFacility);
  return (
    <AuthorizePage subject="Space" subjectId={id} parentId={currentFacility?.id} action="update">
      <SpacesEdit />
    </AuthorizePage>
  );
};
