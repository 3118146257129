import {PageHeader} from "antd";
import DeviceForm from "../../components/devices/DeviceForm";
import {useNavigate} from "react-router-dom";
import AuthorizePage from "../../components/authorize/AuthorizePage";

const DevicesNew = () => {
  const navigate = useNavigate();

  return <>
    <PageHeader title="New Device" onBack={() => navigate('/devices')}/>
    <DeviceForm/>
  </>;
}

export default () => {
  return <AuthorizePage subject='Device' action='create'>
    <DevicesNew/>
  </AuthorizePage>
}

