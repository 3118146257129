import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { RootState } from "../../store";
import { index } from "../../store/devicesActions";
import { index as indexFacilities } from "../../store/facilitiesActions";
import { Empty, Form, PageHeader, Skeleton, Space, Table, Checkbox, Typography, Select, Input } from "antd";
import { SettingOutlined, AimOutlined, HistoryOutlined, PlayCircleOutlined } from "@ant-design/icons";
import { Link, useSearchParams } from "react-router-dom";
import AuthorizePage from "../../components/authorize/AuthorizePage";
import moment from "moment/moment";
import { isAdminForOrganization, isSystemAdmin } from "../../lib/permissions";
import OrganizationNotSelected from "../../components/OrganizationNotSelected";
import FacilityNotSelected from "../../components/FacilityNotSelected";
import type { ColumnsType } from "antd/es/table";
import { Device } from "../../store/models";
import OrganizationLink from "../../components/links/OrganizationLink";
import FacilityLink from "../../components/links/FacilityLink";
import SearchableSelect from "../../components/ui/SearchableSelect";
import { deviceName } from "../../helpers/formatters";
import MachineLink from "../../components/links/MachineLink";
import CreateButton from "../../components/buttons/CreateButton";
import UpdateButton from "../../components/buttons/UpdateButton";
import ReadButton from "../../components/buttons/ReadButton";
import DeviceLinkButton from "../../components/buttons/DeviceLinkButton";

type Row = { key: React.Key } & Device;
type DataSource = Row[];

const PAGE_SIZE = 10;

const { Paragraph } = Typography;

const DevicesIndex = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentOrganization = useAppSelector((state: RootState) => state.selection.currentOrganization);
  const currentFacility = useAppSelector((state: RootState) => state.selection.currentFacility);
  const facilities = useAppSelector((state: RootState) => state.facilities.list);
  const devices = useAppSelector((state: RootState) => state.devices.list);
  const pagination = useAppSelector((state: RootState) => state.devices.pagination);
  const loading = useAppSelector((state: RootState) => state.devices.loading);
  const [page, setPage] = useState(Number(searchParams.get("page")) || 1);
  const [search, setSearch] = useState("");
  const [form] = Form.useForm();
  const selectedFacilityId = Form.useWatch("facilityId", form);
  const withoutOrganization = Form.useWatch("withoutOrganization", form);
  const withoutFacility = Form.useWatch("withoutFacility", form);
  const allDevices = Form.useWatch("allDevices", form);

  let dataSource: DataSource = [];

  // TODO: investigate fixed columns
  const columns: ColumnsType<Row> = [
    {
      title: "Name",
      dataIndex: "id",
      key: "id",
      width: 200,
      fixed: "left",
      render: (_text: string, device: Row) => <Link to={device.id}>{deviceName(device)}</Link>,
    },
    // {
    //   title: "State",
    //   dataIndex: "state",
    //   key: "state",
    // },
    {
      title: "Machine",
      dataIndex: "machineId",
      key: "machineId",
      render: (text: string, device: Row) => <MachineLink model={device} />,
    },
    {
      title: "Organization",
      dataIndex: "organizationId",
      key: "organization",
      render: (text: string, device: Row) => <OrganizationLink model={device} />,
    },
    {
      title: "Facility",
      dataIndex: "facilityId",
      key: "facilityId",
      render: (text: string, device: Row) => <FacilityLink model={device} />,
    },
    {
      title: "Firmware",
      dataIndex: "firmwareVersion",
      key: "firmwareVersion",
      render: (text: string) => text,
    },
    {
      title: "Software",
      dataIndex: "softwareVersion",
      key: "softwareVersion",
      render: (text: string) => text,
    },
    {
      title: "Actions",
      key: "action",
      width: 200,
      fixed: "right",
      render: (_: any, device: Row) => (
        <Space size="middle">
          <UpdateButton
            subject="Device"
            subjectId={device.id}
            parentId={device.organizationId}
            subjectName={deviceName(device)}
            routePrefix={`${device.id}/`}
          />
          {/* <ReadButton subject="DeviceConfiguration" parentId={device.organizationId} icon={<SettingOutlined />} to={`${device.id}/configurations`} plural /> */}
          {(currentOrganization || isSystemAdmin()) && <DeviceLinkButton device={device} />}
          {/* <ReadButton subject="DeviceStatus" parentId={device.organizationId} icon={<AimOutlined />} to={`${device.id}/statuses`} /> */}
          <ReadButton subject="DeviceHistory" parentId={device.organizationId} icon={<HistoryOutlined />} to={`${device.id}/history`} />
          {/* <ReadButton subject="DeviceCommand" parentId={device.organizationId} icon={<PlayCircleOutlined />} to={`${device.id}/commands`} plural /> */}
        </Space>
      ),
    },
  ];
  if (!loading && devices) {
    dataSource = devices.map((device: Device) => {
      return { key: device.id, ...device };
    });
  }

  useEffect(() => {
    if (!currentOrganization && !isSystemAdmin()) {
      return;
    }
    let organizationId = !withoutOrganization ? currentOrganization?.id : undefined;

    let facilityId = selectedFacilityId || currentFacility?.id;
    if (allDevices) {
      organizationId = facilityId = undefined;
    }
    const scope = [];
    if (withoutOrganization) {
      facilityId = undefined;
      scope.push("withoutOrganization");
    }
    if (withoutFacility) {
      facilityId = undefined;
      scope.push("withoutFacility");
    }
    const opt = {
      include: "facilities,organizations,machines",
      scope: scope.length > 0 ? scope.join(",") : undefined,
    };
    dispatch(index(page - 1, PAGE_SIZE, organizationId, facilityId, opt, search));
  }, [page, currentOrganization, currentFacility, selectedFacilityId, withoutFacility, withoutOrganization, dispatch, search, allDevices]);

  useEffect(() => {
    if (currentOrganization) {
      dispatch(indexFacilities(0, undefined, currentOrganization.id));
    }
  }, [currentOrganization, dispatch]);

  if (!isSystemAdmin() && !currentOrganization) {
    return <OrganizationNotSelected />;
  }
  if (!currentFacility && !isSystemAdmin() && !isAdminForOrganization(currentOrganization?.id)) {
    return <FacilityNotSelected />;
  }

  const pageChangeHandler = (page: number) => {
    setPage(page);
    setSearchParams({ page: page.toString() });
  };
  const { Search } = Input;
  const onSearch = (value: string) => {
    setSearch(value);
  };
  return (
    <>
      <PageHeader title={`Devices (${pagination.total})`} extra={[<CreateButton key="d" subject="Device" />]} />

      <Paragraph>
        <Form form={form} layout="inline">
          <Search placeholder="Search" onSearch={onSearch} style={{ width: 200 }} />
          {isSystemAdmin() && (
            <Form.Item name="allDevices" valuePropName="checked">
              <Checkbox>All</Checkbox>
            </Form.Item>
          )}
          {!allDevices && (
            <>
              {currentOrganization && (
                <Form.Item name="facilityId" label="Facility">
                  <SearchableSelect width={200} disabled={withoutOrganization || withoutFacility}>
                    {facilities &&
                      facilities.map((facility) => (
                        <Select.Option key={facility.id} value={facility.id}>
                          {facility.name}
                        </Select.Option>
                      ))}
                  </SearchableSelect>
                </Form.Item>
              )}

              {isSystemAdmin() && (
                <Form.Item name="withoutOrganization" valuePropName="checked">
                  <Checkbox>Without organization</Checkbox>
                </Form.Item>
              )}

              {currentOrganization && (isSystemAdmin() || isAdminForOrganization(currentOrganization.id)) && (
                <Form.Item name="withoutFacility" valuePropName="checked">
                  <Checkbox>Without facility</Checkbox>
                </Form.Item>
              )}
            </>
          )}
        </Form>
      </Paragraph>

      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={{
          current: page,
          total: pagination.total,
          defaultPageSize: PAGE_SIZE,
          onChange: pageChangeHandler,
        }}
        locale={{ emptyText: loading ? <Skeleton active={true} /> : <Empty /> }}
      />
    </>
  );
};

export default () => {
  const currentOrganization = useAppSelector((state: RootState) => state.selection.currentOrganization);
  return (
    <AuthorizePage subject="Device" parentId={currentOrganization?.id} action="read">
      <DevicesIndex />
    </AuthorizePage>
  );
};
