import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {User, AppError, Pagination} from "./models";

type UsersState = {
  loading: boolean
  error?: AppError
  list?: User[]
  pagination: Pagination
  item?: User
}

const initialState: UsersState = {
  loading: false,
  error: undefined,
  list: undefined,
  item: undefined,
  pagination: {index: 0, total: 0}
}

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    loading(state) {
      state.loading = true;
    },
    loaded(state) {
      state.loading = false;
      state.error = undefined;
    },
    error(state, action: PayloadAction<AppError>) {
      state.loading = false;
      state.error = action.payload;
    },
    index(state, action: PayloadAction<{ users: User[], pagination: Pagination }>) {
      state.list = action.payload.users;
      state.pagination = action.payload.pagination;
    },
    view(state, action: PayloadAction<User>) {
      state.item = action.payload;
    },
  }
});

export const usersActions = usersSlice.actions;
export default usersSlice;
