import type { Moment } from "moment";

export type Pagination = {
  index: number;
  total: number;
};

export type User = {
  id: string;
  email: string;
  name: string;
  surname: string;
};

export type CurrentUser = {
  id: string;
  email: string;
  name: string;
  surname: string;
};

export type AppError = {
  // todo: fromBackend is unused/always false, consider to remove
  fromBackend: boolean;
  code: number;
  message: string;
};

export type Organization = {
  id: string;
  name: string;
  notes: string;
};

export type Facility = {
  id: string;
  name: string;
  notes: string;
  organizationId: string;
  organization?: Organization;
};

export type FacilityIncluded = {
  organizations?: Organization[];
};

export type Floor = {
  id: string;
  name: string;
  notes: string;
  facilityId: string;
  facility?: Facility;
};

export type FloorIncluded = {
  facilities?: Facility[];
};

export type Space = {
  id: string;
  name: string;
  notes: string;
  floorId: string;
  floor?: Floor;
  currentMapId?: string;
  currentMap?: Map;
};

export type SpaceIncluded = {
  floors?: Floor[];
  maps?: Map[];
};

export type OrganizationHistoryIncluded = {
  floors: Floor[];
  spaces: Space[];
  facilities: Facility[];
};

export type SpaceDayHistory = {
  date: string;

  spaceId: string;
  floorId: string;
  facilityId: string;

  cleaningTime: number;
  chargingTime: number;
  idleTime: number;

  cleanedArea: number;
  cleanedAreaOverlapping: number;
  drivenDistance: number;
  cleanedDistance: number;
};

export type Map = {
  id: string;
  name: string;
  spaceId?: string;
  space?: Space;
  height?: number;
  width?: number;
  resolution?: number;
  transformMatrix?: number[];
  inverseMatrix?: number[];
  type?: MapType;
};

export type MapIncluded = {
  spaces?: Space[];
};

export type MapFile = {
  id: string;
  subId?: string;
  mapId: string;
  map?: Map;
  type: MapFileType;
  extension: string;
  size: number;
};

export type MapFileIncluded = {
  maps?: Map[];
};

export enum Role {
  SystemAdmin = "SYSTEM_ADMIN",
  Admin = "ADMIN",
  Manager = "MANAGER",
  Operator = "OPERATOR",
  Manufacturer = "MANUFACTURER",
}

export type Permission = {
  id: string;
  role: Role;
  organizationId?: string;
  organization?: Organization;
  facilityId?: string;
  facility?: Facility;
};

export type PermissionIncluded = {
  organizations?: Organization[];
  facilities?: Facility[];
};

export type Machine = {
  id: string;
  manufacturer: string;
  model: string;
  manufacturedDate: Moment;
};

export type Device = {
  id: string;
  secret: string;
  state: string;
  bootstrappingTime: string;
  mapUrl: string;
  alias: string;
  machineId: string;
  organizationId?: string;
  organization?: Organization;
  facilityId?: string;
  facility?: Facility;
};

export type DeviceState = {
  id: string;
  alias: string;
  moving: boolean;
  isLost: boolean;
  modified: Moment;
  executionState: number;
  brakeState: number;
  charging: boolean;
  batteryPercentage: boolean;
};

export type DeviceIncluded = {
  organizations?: Organization[];
  facilities?: Facility[];
  machines?: Machine[];
};

export type DeviceConfiguration = {
  component: string;
  configuration: { [name: string]: any };
};

export type DeviceConfigurationWifi = {
  networks: WifiNetwork[];
};

export type WifiNetwork = {
  name: string;
  password?: string;
};

export type DeviceStatus = {
  component: string;
  status: { [name: string]: any };
};
export type BatteryStatus = {
  value: number;
};
export type ActivityStatsStatus = {
  [name: string]: number;
};

export type CleaningSessionsStatus = {
  data: { duration: number; usedPower: number; startAt: string }[];
};

export type DeviceCommand = {
  id: string;
  name: string;
  parameters: { [name: string]: any };
  addedTime: string;
  sentTime: string;
  confirmedTime: string;
  responseCode: string;
  responseMessage: string;
  deviceId: string;
};

export type TrajectoryPoint = {
  index: number;
  x: number;
  y: number;
  angle: number;
  timestamp: string;
  parameters: string;
};
export type DeviceTrajectory = {
  id: string;
  type: string;
  creationTime: string;
  points: TrajectoryPoint[];
};

export type BaseEpisode = {
  id: string;
  facilityId: string;
  startTimestamp: string;
  endTimestamp: string;
  totalTime: number;
  batteryPercentage: number;
};

export interface CleaningEpisode extends BaseEpisode {
  idleTime: number;
  idleCleaningTime: number;
  movingTime: number;
  movingCleaningTime: number;
  cleanedArea: number;
  cleanedAreaOverlapping: number;
  cleanedDistance: number;
  drivenDistance: number;
  avoidanceDistance: number;
  avoidanceAmount: number;
  exitState: number;
  heatmapBase64: string;
}
export interface ChargingEpisode extends BaseEpisode {}

export interface IdleEpisode extends BaseEpisode {}

export type SpaceHistory = {
  spaceId: string;
  title: string;
  heatmapsBase64: string[];
  trajectoryTime: number;
  chargingTime: number;
  idleTime: number;
  batteryDelta: number;
  drivenDistance: number;
  cleanedArea: number;
  cleanedDistance: number;
  cleanedOverlappingArea: number;
  startTimestamp: string;
  endTimestamp: string;
};

export enum HistoryType {
  Unknown = 0,
  Device = 1,
  Space = 2,
}

export enum MapType {
  Unknown = 0,
  Kudan = 1,
  Ivisio = 2,
  Cartographer = 6,
}

export enum MapFileType {
  Unknown = 0,
  Image = 1,
  Yaml = 2,
  Kudan = 3,
  Ivisio = 4,
  Pgm = 5,
  Cartographer = 6,
}
