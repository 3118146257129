import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppError, Facility, Pagination} from "./models";

type FacilitiesState = {
  loading: boolean
  error?: AppError
  list?: Facility[]
  pagination: Pagination
  item?: Facility
}

const initialState: FacilitiesState = {
  loading: false,
  error: undefined,
  list: undefined,
  item: undefined,
  pagination: {index: 0, total: 0}
}

const facilitiesSlice = createSlice({
  name: 'facilities',
  initialState,
  reducers: {
    loading(state) {
      state.loading = true;
    },
    loaded(state) {
      state.loading = false;
      state.error = undefined;
    },
    error(state, action: PayloadAction<AppError>) {
      state.loading = false;
      state.error = action.payload;
    },
    index(state, action: PayloadAction<{ facilities: Facility[], pagination: Pagination }>) {
      state.list = action.payload.facilities;
      state.pagination = action.payload.pagination;
    },
    view(state, action: PayloadAction<Facility>) {
      state.item = action.payload;
    },
  }
});

export const facilitiesActions = facilitiesSlice.actions;
export default facilitiesSlice;
