import AuthorizeButton from "../authorize/AuthorizeButton";
import {Subjects} from "../../lib/permissions";
import {humanizeSubject} from "../../helpers/formatters";

type Props = {
  to: string
  subject: Subjects
  subjectId?: string
  parentId?: string
  icon?: JSX.Element
  title?: string
  plural?: boolean
}
const ReadButton = ({to, subject, subjectId, parentId, title, icon, plural}: Props) => {
  title = `View ${humanizeSubject(subject, {plural})}`

  return (
    <AuthorizeButton action='read' subject={subject} subjectId={subjectId} parentId={parentId} to={to}
                     icon={icon} title={title}/>
  )
}

export default ReadButton;
