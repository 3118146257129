export function lcAuthToken(): string | null {
  return localStorage.getItem("authToken");
}

export function lcSetAuthToken(value: string) {
  localStorage.setItem("authToken", value);
}

export function lcRemoveAuthToken() {
  localStorage.removeItem("authToken");
}

export function lcCurrentOrganizationId(): string | null {
  return localStorage.getItem("currentOrganizationId");
}

export function lcSetCurrentOrganizationId(value: string) {
  localStorage.setItem("currentOrganizationId", value);
}

export function lcRemoveCurrentOrganizationId() {
  localStorage.removeItem("currentOrganizationId");
}

export function lcCurrentFacilityId(): string | null {
  return localStorage.getItem("currentFacilityId");
}

export function lcSetCurrentFacilityId(value: string) {
  localStorage.setItem("currentFacilityId", value);
}

export function lcRemoveCurrentFacilityId() {
  localStorage.removeItem("currentFacilityId");
}

export function lcCurrentFloorId(): string | null {
  return localStorage.getItem("currentFloorId");
}

export function lcSetCurrentFloorId(value: string) {
  localStorage.setItem("currentFloorId", value);
}

export function lcRemoveCurrentFloorId() {
  localStorage.removeItem("currentFloorId");
}

export function lcCurrentSpaceId(): string | null {
  return localStorage.getItem("currentSpaceId");
}

export function lcSetCurrentSpaceId(value: string) {
  localStorage.setItem("currentSpaceId", value);
}

export function lcRemoveCurrentSpaceId() {
  localStorage.removeItem("currentSpaceId");
}

export function lcCurrentMapId(): string | null {
  return localStorage.getItem("currentMapId");
}

export function lcSetCurrentMapId(value: string) {
  localStorage.setItem("currentMapId", value);
}

export function lcRemoveCurrentMapId() {
  localStorage.removeItem("currentMapId");
}

export function lcLoginAsToken(): string | null {
  return localStorage.getItem("loginAsToken");
}

export function lcSetLoginAsToken(value: string) {
  localStorage.setItem("loginAsToken", value);
}

export function lcRemoveLoginAsToken() {
  localStorage.removeItem("loginAsToken");
}
