import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {RootState} from "../../store";
import {view, destroy} from '../../store/usersActions';
import {Descriptions, PageHeader, Skeleton} from "antd";
import {AuditOutlined} from '@ant-design/icons';
import {useNavigate, useParams} from "react-router-dom";
import {User} from "../../store/models";
import {notifySuccess} from "../../components/ui/notifications";
import AuthorizePage from "../../components/authorize/AuthorizePage";
import ShowPageError, {isPageError} from "../../components/ShowPageError";
import ModelDescriptions from "../../components/ui/ModelDescriptions";
import DeleteButton from "../../components/buttons/DeleteButton";
import UpdateButton from "../../components/buttons/UpdateButton";
import ReadButton from "../../components/buttons/ReadButton";

const DeleteUserButton = ({user}: { user: User }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const confirm = () => {
    dispatch(destroy(user.id, () => {
      navigate('/users');
      notifySuccess(`User ${user.name} ${user.surname} is deleted`);
    }));
  };
  return <DeleteButton subject="User" subjectId={user.id} subjectName={`${user.name} ${user.surname}`}
                       onConfirm={confirm}/>
}

const UsersView = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {id} = useParams();
  const user = useAppSelector((state: RootState) => state.users.item);
  const error = useAppSelector((state: RootState) => state.users.error);
  const loading = useAppSelector((state: RootState) => state.users.loading) || !user;

  useEffect(() => {
    dispatch(view(id!));
  }, [id, dispatch]);

  if (isPageError(error)) {
    return <ShowPageError error={error!}/>
  }

  return <>
    <PageHeader title={`User ${!loading && user ? `${user.name} ${user.surname}` : ''}`}
                onBack={() => navigate('/users')}
                extra={user ? [
                  <UpdateButton key="u" subject="User" subjectId={user.id}
                                subjectName={`${user.name} ${user.surname}`}/>,
                  <ReadButton key="p" subject="Permission" subjectId={user.id}
                                   icon={<AuditOutlined/>} to={`permissions`}/>,
                  <DeleteUserButton key="d" user={user}/>
                ] : []}
    />
    {loading ?
      <Skeleton active={true}/> :
      <ModelDescriptions title="Details">
        <Descriptions.Item label="Name">{user!.name}</Descriptions.Item>
        <Descriptions.Item label="Surname">{user!.surname}</Descriptions.Item>
        <Descriptions.Item label="Email">{user!.email}</Descriptions.Item>
      </ModelDescriptions>
    }
  </>
}

export default () => {
  const {id} = useParams();
  const currentOrganization = useAppSelector((state: RootState) => state.selection.currentOrganization);
  return <AuthorizePage subject='User' subjectId={id} parentId={currentOrganization?.id} action='read'>
    <UsersView/>
  </AuthorizePage>
}

