import {useState} from "react";
import {PageHeader, Skeleton} from "antd";
import DeviceForm from "../../components/devices/DeviceForm";
import {useEffect} from "react";
import {view} from "../../store/devicesActions";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {useNavigate, useParams} from "react-router-dom";
import {RootState} from "../../store";
import AuthorizePage from "../../components/authorize/AuthorizePage";
import ShowPageError, {isPageError} from "../../components/ShowPageError";
import {deviceName} from "../../helpers/formatters";

const DevicesEdit = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const device = useAppSelector((state: RootState) => state.devices.item);
  const error = useAppSelector((state: RootState) => state.devices.error);
  const [loaded, setLoaded] = useState(false)
  const {id} = useParams();

  useEffect(() => {
    dispatch(view(id!, {}, () => setLoaded(true)));
  }, [id, dispatch]);

  if (isPageError(error)) {
    return <ShowPageError error={error!}/>
  }

  return <>
    <PageHeader title={`Edit Device ${deviceName(device)}`}
                onBack={() => navigate('/devices')}
    />
    {loaded ?
      <DeviceForm device={device}/> :
      <Skeleton active={true}/>
    }
  </>;
}

export default () => {
  const {id} = useParams();
  return <AuthorizePage subject='Device' subjectId={id} action='update'>
    <DevicesEdit/>
  </AuthorizePage>
}

