import {authGet} from "../client";
import {Organization} from "../models";

// todo: move all other organization related api calls to this module

type OrganizationResponse = {
  organization: Organization
}

export async function getOrganization(id: string) {
  const {data}: { data: OrganizationResponse } = await authGet(`organizations/${id}`);
  return data.organization;
}
