import { Dispatch } from "redux";
import { authDelete, authGet, authPost, authPut } from "./client";
import { usersActions } from "./usersSlice";
import processError from "./processError";
import { Pagination, User } from "./models";

type UsersResponse = {
  users: User[];
  pagination: Pagination;
};

type UserResponse = {
  user: User;
};

type SaveData = {
  id?: string;
  name: string;
  surname: string;
  email: string;
};

export const index = (pageIndex: number, pageSize: number, organizationId?: string, facilityId?: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(usersActions.loading());
    const { data }: { data: UsersResponse } = await authGet("users", { pageIndex, pageSize, organizationId, facilityId });
    dispatch(usersActions.index({ users: data.users, pagination: data.pagination }));
  } catch (error: any) {
    processError(error, dispatch, usersActions);
  } finally {
    dispatch(usersActions.loaded());
  }
};

type SearchParams = {
  email?: string;
};

export const search = (params: SearchParams, onSuccess?: (users: User[]) => void) => async (dispatch: Dispatch) => {
  try {
    dispatch(usersActions.loading());
    const { data }: { data: UsersResponse } = await authGet("users/search", params);
    // todo: is it worth to extract search to different reducer?
    dispatch(usersActions.index({ users: data.users, pagination: { index: 0, total: data.users.length } }));
    onSuccess && onSuccess(data.users);
  } catch (error: any) {
    processError(error, dispatch, usersActions);
  } finally {
    dispatch(usersActions.loaded());
  }
};

export const view = (id: string, onSuccess?: (user: User) => void) => async (dispatch: Dispatch) => {
  try {
    dispatch(usersActions.loading());
    const { data }: { data: UserResponse } = await authGet(`users/${id}`);
    dispatch(usersActions.view(data.user));
    onSuccess && onSuccess(data.user);
  } catch (error: any) {
    processError(error, dispatch, usersActions);
  } finally {
    dispatch(usersActions.loaded());
  }
};

export const save = (user: SaveData, onSuccess: (user: User) => void) => async (dispatch: Dispatch) => {
  try {
    dispatch(usersActions.loading());
    const { id, ...attributes } = user;
    const { data }: { data: UserResponse } = id ? await authPut(`users/${id}`, { user: attributes }) : await authPost("users", { user: attributes });
    onSuccess(data.user);
  } catch (error: any) {
    processError(error, dispatch, usersActions);
  } finally {
    dispatch(usersActions.loaded());
  }
};

export const destroy = (id: string, onSuccess?: () => void) => async (dispatch: Dispatch) => {
  try {
    dispatch(usersActions.loading());
    await authDelete(`users/${id}`);
    onSuccess && onSuccess();
  } catch (error: any) {
    processError(error, dispatch, usersActions);
  } finally {
    dispatch(usersActions.loaded());
  }
};

export const updatePassword = (id: string, newPassword: string, onSuccess: () => void) => async (dispatch: Dispatch) => {
  try {
    dispatch(usersActions.loading());
    await authPut(`users/${id}/password`, { password: { newPassword } });
    onSuccess();
  } catch (error: any) {
    processError(error, dispatch, usersActions);
  } finally {
    dispatch(usersActions.loaded());
  }
};
