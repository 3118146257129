import {DeviceStatus} from "../../store/models";
import {PageHeader, Typography} from "antd";

const {Paragraph} = Typography;

const CustomDeviceStatus = ({status}: { status: DeviceStatus }) => {
  return <>
    <PageHeader title={`${status.component}`}/>
    <Paragraph>
      <pre>{JSON.stringify(status.status, null, 2)}</pre>
    </Paragraph>
  </>
}

export default CustomDeviceStatus;
