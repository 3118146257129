import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppError, DeviceConfiguration} from "./models";

type DeviceCongigurationsState = {
  loading: boolean
  error?: AppError
  list?: DeviceConfiguration[]
}

const initialState: DeviceCongigurationsState = {
  loading: false,
  error: undefined,
  list: undefined,
}

const deviceConfigurationsSlice = createSlice({
  name: 'deviceConfigurations',
  initialState,
  reducers: {
    loading(state) {
      state.loading = true;
    },
    loaded(state) {
      state.loading = false;
      state.error = undefined;
    },
    error(state, action: PayloadAction<AppError>) {
      state.loading = false;
      state.error = action.payload
    },
    index(state, action: PayloadAction<{ deviceConfigurations: DeviceConfiguration[] }>) {
      state.list = action.payload.deviceConfigurations;
    },
  }
});

export const deviceConfigurationsActions = deviceConfigurationsSlice.actions;
export default deviceConfigurationsSlice;
