import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { AppDispatch, RootState } from "../../store";
import { index } from "../../store/facilitiesActions";
import { Empty, PageHeader, Skeleton, Space, Table, Input, Typography } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { Link, useSearchParams } from "react-router-dom";
import { Facility } from "../../store/models";
import AuthorizePage from "../../components/authorize/AuthorizePage";
import OrganizationNotSelected from "../../components/OrganizationNotSelected";
import { selectFacility } from "../../store/selectionActions";
import { notifySuccess } from "../../components/ui/notifications";
import IconButton from "../../components/ui/IconButton";
import CreateButton from "../../components/buttons/CreateButton";
import UpdateButton from "../../components/buttons/UpdateButton";

type Row = { id: string; name: string; notes: string; organizationId: string };
type DataSource = Row[];

const PAGE_SIZE = 10;

function buildColumns(dispatch: AppDispatch) {
  return [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 200,
      render: (text: string, facility: Facility) => <Link to={facility.id}>{text}</Link>,
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      render: (notes: string) => <div className="PreWrap">{notes}</div>,
    },
    {
      title: "Actions",
      key: "action",
      width: 100,
      render: (_: any, facility: Facility) => (
        <Space size="middle">
          <UpdateButton
            subject="Facility"
            subjectId={facility.id}
            parentId={facility.organizationId}
            subjectName={facility.name}
            routePrefix={`${facility.id}/`}
          />
          <IconButton
            onClick={() => {
              dispatch(selectFacility(facility));
              notifySuccess(`Facility ${facility.name} is selected`);
            }}
            icon={<RightOutlined />}
            title="Select facility"
          />
        </Space>
      ),
    },
  ];
}

const FacilitiesIndex = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentOrganization = useAppSelector((state: RootState) => state.selection.currentOrganization);
  const facilities = useAppSelector((state: RootState) => state.facilities.list);
  const pagination = useAppSelector((state: RootState) => state.facilities.pagination);
  const loading = useAppSelector((state: RootState) => state.facilities.loading);
  const [page, setPage] = useState(Number(searchParams.get("page")) || 1);
  const [search, setSearch] = useState("");
  let dataSource: DataSource = [];
  if (!loading && facilities) {
    dataSource = facilities.map(({ id, name, notes, organizationId }: Row) => {
      return { key: id, id, name, notes, organizationId };
    });
  }

  useEffect(() => {
    if (currentOrganization) {
      dispatch(index(page - 1, PAGE_SIZE, currentOrganization.id, search));
    }
  }, [page, currentOrganization, dispatch, search]);

  if (!currentOrganization) {
    return <OrganizationNotSelected />;
  }

  const pageChangeHandler = (page: number) => {
    setPage(page);
    setSearchParams({ page: page.toString() });
  };

  const columns = buildColumns(dispatch);
  const { Search } = Input;
  const { Paragraph } = Typography;
  const onSearch = (value: string) => {
    setSearch(value);
  };
  return (
    <>
      <PageHeader title={`Facilities (${pagination.total})`} extra={[<CreateButton key="c" subject="Facility" parentId={currentOrganization.id} />]} />
      <Paragraph>
        <Search placeholder="Search" onSearch={onSearch} style={{ width: 200 }} />
      </Paragraph>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={{
          current: page,
          total: pagination.total,
          defaultPageSize: PAGE_SIZE,
          onChange: pageChangeHandler,
        }}
        locale={{ emptyText: loading ? <Skeleton active={true} /> : <Empty /> }}
      />
    </>
  );
};

export default () => {
  const currentOrganization = useAppSelector((state: RootState) => state.selection.currentOrganization);
  return (
    <AuthorizePage subject="Facility" parentId={currentOrganization?.id} action="read">
      <FacilitiesIndex />
    </AuthorizePage>
  );
};
