import { Form, Input, Button } from "antd";
import { useAppDispatch, useAppSelector } from "../hooks";
import { login } from "../store/authActions";
import { RootState } from "../store";

type LoginData = {
  email: string;
  password: string;
};

const LoginForm = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state: RootState) => state.auth.loading);
  const error = useAppSelector((state: RootState) => state.auth.error);
  const onSubmitHandler = (data: LoginData) => {
    dispatch(login(data.email, data.password));
  };
  const emailStatus = loading ? "validating" : error ? "error" : "success";
  const emailHelp = emailStatus === "error" ? "Incorrect email or password" : "";
  return (
    <Form name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} autoComplete="off" onFinish={onSubmitHandler}>
      <Form.Item label="Email" name="email" validateStatus={emailStatus} help={emailHelp} rules={[{ required: true, message: "Please input your email!" }]}>
        <Input autoComplete="username" />
      </Form.Item>

      <Form.Item label="Password" name="password" rules={[{ required: true, message: "Please input your password!" }]}>
        <Input.Password autoComplete="current-password" />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Log in
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginForm;
