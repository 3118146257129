import AuthorizeButton from "../authorize/AuthorizeButton";
import {LinkOutlined} from "@ant-design/icons";
import {Device} from "../../store/models";

type Props = {
  device: Device
}
const DeviceLinkButton = ({device}: Props) => {
  return (
    <AuthorizeButton action="link" subject="Device" parentId={device.organizationId}
                     icon={<LinkOutlined/>} to={`/devices/${device.id}/link`} title='Link to facility'/>
  )
}
export default DeviceLinkButton;
