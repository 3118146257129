import {PageHeader, Typography} from "antd";

const {Text} = Typography;

const Unauthorized = () => {
  return <>
    <PageHeader title="Forbidden"/>
    <Text>You are not permitted to view this page, ask your organization administrators if you need access</Text>
  </>
}

export default Unauthorized;
