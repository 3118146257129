import { Link } from "react-router-dom";
import { Space } from "../../store/models";

type ModelWitSpace = {
  spaceId?: string;
  space?: Space;
};

const SpaceLink = ({ model, withModelName }: { model: ModelWitSpace; withModelName?: boolean }) => {
  if (!model.spaceId) {
    return null;
  }
  return (
    <Link to={`/spaces/${model.spaceId}`}>
      {withModelName ? "Space " : ""}
      {model.space?.name ?? model.spaceId}
    </Link>
  );
};

export default SpaceLink;
