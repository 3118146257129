import { useState } from "react";
import { PageHeader, Skeleton } from "antd";
import { useEffect } from "react";
import { view } from "../../store/mapsActions";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../store";
import AuthorizePage from "../../components/authorize/AuthorizePage";
import ShowPageError, { isPageError } from "../../components/ShowPageError";
import MapForm from "../../components/maps/MapForm";
import FacilityNotSelected from "../../components/FacilityNotSelected";

const MapsEdit = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const map = useAppSelector((state: RootState) => state.maps.item);
  const error = useAppSelector((state: RootState) => state.maps.error);
  const currentFacility = useAppSelector((state: RootState) => state.selection.currentFacility);
  const [loaded, setLoaded] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    dispatch(view(id!, {}, () => setLoaded(true)));
  }, [id, dispatch]);

  if (!currentFacility) {
    return <FacilityNotSelected />;
  }

  if (isPageError(error)) {
    return <ShowPageError error={error!} />;
  }

  return (
    <>
      <PageHeader title={`Edit Map ${loaded && map ? map.name : ""}`} onBack={() => navigate(`/maps/${id}`)} />
      {loaded ? <MapForm map={map} /> : <Skeleton active={true} />}
    </>
  );
};

export default () => {
  const { id } = useParams();
  const currentFacility = useAppSelector((state: RootState) => state.selection.currentFacility);
  return (
    <AuthorizePage subject="Map" subjectId={id} parentId={currentFacility?.id} action="update">
      <MapsEdit />
    </AuthorizePage>
  );
};
