import {Form, Input} from "antd";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {useNavigate} from "react-router-dom";
import {save} from "../../store/deviceConfigurationsActions";
import {RootState} from "../../store";
import {notifySuccess} from "../ui/notifications";
import ModelForm from "../ui/ModelForm";
import {DeviceConfiguration} from "../../store/models";

const {TextArea} = Input;

type FormData = {
  component: string
  configuration: string
}

type Prop = {
  deviceConfiguration?: DeviceConfiguration,
  deviceId: string
}

const DeviceConfigurationForm = ({deviceConfiguration, deviceId}: Prop) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate();
  const loading = useAppSelector((state: RootState) => state.deviceConfigurations.loading);
  const error = useAppSelector((state: RootState) => state.deviceConfigurations.error);

  const onFinishHandler = (data: FormData) => {
    const deviceConfiguration = {component: data.component, configuration: JSON.parse(data.configuration)}
    dispatch(save(deviceId, [deviceConfiguration], () => {
      navigate(`/devices/${deviceId}/configurations`);
      notifySuccess(`${data.component} configuration for ${deviceId} is updated`);
    }));
  };
  const isNew = !deviceConfiguration;

  const initialValues = {
    ...deviceConfiguration,
    configuration: deviceConfiguration?.configuration ?
      JSON.stringify(deviceConfiguration.configuration, null, 2) : ''
  }

  return <ModelForm isNew={isNew} fullWidthdata loading={loading} initialValues={initialValues}
                    onFinish={onFinishHandler} error={error}>
    <Form.Item label="Component" name="component" rules={[{required: true}]} hidden={!isNew}>
      <Input/>
    </Form.Item>
    <Form.Item label="Configuration" name="configuration" rules={[{required: true},
      () => ({
        validator(_, value) {
          try {
            JSON.parse(value);
            return Promise.resolve();
          } catch (error: any) {
            return Promise.reject(error);
          }
        },
      }),
    ]}>
      <TextArea className="code" autoSize={{minRows: 5}} style={{"fontFamily": "monospace"}}/>
    </Form.Item>
  </ModelForm>
}

export default DeviceConfigurationForm;
