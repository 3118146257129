import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {RootState} from "../../../store";
import {view as viewUser} from '../../../store/usersActions';
import {index, destroy} from '../../../store/permissionsActions';
import {Empty, PageHeader, Skeleton, Space, Table} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {Permission, Role} from "../../../store/models";
import {notifySuccess} from "../../../components/ui/notifications";
import AuthorizePage from "../../../components/authorize/AuthorizePage";
import {isSystemAdmin} from "../../../lib/permissions";
import OrganizationNotSelected from "../../../components/OrganizationNotSelected";
import FacilityLink from "../../../components/links/FacilityLink";
import OrganizationLink from "../../../components/links/OrganizationLink";
import CreateButton from "../../../components/buttons/CreateButton";
import DeleteButton from "../../../components/buttons/DeleteButton";

type Row = { key: string } & Permission
type DataSource = Row[]

const SubjectCell = ({permission}: { permission: Permission }) => {
  if (permission.role === Role.SystemAdmin) {
    return <>Universe</>;
  }

  if (permission.role === Role.Manufacturer) {
    return <>All machines</>;
  }

  if (permission.facilityId) {
    return <FacilityLink model={permission} withModelName/>
  }

  return <OrganizationLink model={permission} withModelName/>
}

const DeletePermissionButton = ({permission}: { permission: Permission }) => {
  const dispatch = useAppDispatch();
  const {userId} = useParams();
  const currentOrganization = useAppSelector((state: RootState) => state.selection.currentOrganization);

  let subject;
  if (permission.role === Role.Manufacturer) {
    subject = 'machines';
  } else if (permission.facilityId) {
    subject = `facility ${permission.facility?.name ?? permission.facilityId}`;
  } else {
    subject = `organization ${permission.organization?.name ?? permission.organizationId}`;
  }
  const title = `${permission.role} for ${subject}`;
  const confirm = () => {
    dispatch(destroy(permission.id, () => {
      dispatch(index(userId!));
      notifySuccess(`Permission ${title} is deleted`);
    }));
  };
  return <DeleteButton subject="Permission" subjectId={permission.id} parentId={currentOrganization?.id}
                       subjectName={title} onConfirm={confirm}/>
}

function buildColumns() {
  return [
    {
      title: 'Subject', dataIndex: 'subject', key: 'subject', width: 200,
      render: (text: string, permission: Permission) => <SubjectCell permission={permission}/>
    },
    {
      title: 'Role', dataIndex: 'role', key: 'role', width: 50
    },
    {
      title: 'Actions', key: 'action', width: 100,
      render: (_: any, permission: Permission) => (
        <Space size="middle">
          <DeletePermissionButton permission={permission}/>
        </Space>
      ),
    },
  ];
}

const PermissionsIndex = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {userId} = useParams();
  const user = useAppSelector((state: RootState) => state.users.item);
  const userLoading = useAppSelector((state: RootState) => state.permissions.loading);
  const currentOrganization = useAppSelector((state: RootState) => state.selection.currentOrganization);

  const permissions = useAppSelector((state: RootState) => state.permissions.list);
  const loading = useAppSelector((state: RootState) => state.permissions.loading);

  let dataSource: DataSource = [];
  if (!userLoading && !loading && permissions) {
    dataSource = permissions.map((permission: Permission) => {
      return {key: permission.id, ...permission}
    });
  }

  useEffect(() => {
    dispatch(viewUser(userId!));
  }, [userId, dispatch]);

  useEffect(() => {
    if (user) {
      dispatch(index(user.id, {include: 'organizations,facilities'}));
    }
  }, [user, dispatch]);

  if (!isSystemAdmin() && !currentOrganization) {
    return <OrganizationNotSelected/>
  }

  const columns = buildColumns();
  return <>
    <PageHeader title={`User ${user ? `${user.name} ${user.surname}` : ''} permissions`}
                onBack={() => navigate('/users')}
                extra={[
                  <CreateButton key='c' subject="Permission" parentId={currentOrganization?.id}/>,
                ]}
    />
    <Table dataSource={dataSource} columns={columns}
           locale={{emptyText: (userLoading || loading) ? <Skeleton active={true}/> : <Empty/>}}
    />
  </>
}

export default () => {
  return <AuthorizePage subject='Permission' action='read'>
    <PermissionsIndex/>
  </AuthorizePage>
}

