import {useState} from "react";
import {PageHeader, Skeleton} from "antd";
import MachineForm from "../../components/machines/MachineForm";
import {useEffect} from "react";
import {view} from "../../store/machinesActions";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {useNavigate, useParams} from "react-router-dom";
import {RootState} from "../../store";
import AuthorizePage from "../../components/authorize/AuthorizePage";
import ShowPageError, {isPageError} from "../../components/ShowPageError";

const MachinesEdit = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const machine = useAppSelector((state: RootState) => state.machines.item);
  const error = useAppSelector((state: RootState) => state.machines.error);
  const [loaded, setLoaded] = useState(false)
  const {id} = useParams();

  useEffect(() => {
    dispatch(view(id!, () => setLoaded(true)));
  }, [id, dispatch]);

  if (isPageError(error)) {
    return <ShowPageError error={error!}/>
  }

  return <>
    <PageHeader title={`Edit Machine ${loaded && machine ? machine.model : ''}`}
                onBack={() => navigate('/machines')}
    />
    {loaded ?
      <MachineForm machine={machine}/> :
      <Skeleton active={true}/>
    }
  </>;
}

export default () => {
  const {id} = useParams();
  return <AuthorizePage subject='Machine' subjectId={id} action='update'>
    <MachinesEdit/>
  </AuthorizePage>
}
