import axios, { AxiosError } from "axios";
import { Dispatch } from "redux";
import { AppError } from "./models";
import { systemActions } from "./systemSlice";
import { authActions } from "./authSlice";

type ErrorBody = {
  message: string;
  code: number;
};

type ErrorResponseBody = {
  error: ErrorBody;
};

/*
Response example:
{
  "type": "https://tools.ietf.org/html/rfc7231#section-6.5.1",
  "title": "One or more validation errors occurred.",
  "status": 400,
  "traceId": "00-71d9735b69f447cfaefa5999b0bf1a5d-52109265660d2761-00",
  "errors": {
    "Facility.Notes": [
      "The Notes field is required."
    ]
  }
}
*/
type AspErrorResponseBody = {
  type: string;
  title: string;
  status: number;
  traceId: string;
  errors: {
    [key: string]: string[];
  };
};

function inspectError(error: AxiosError) {
  const config = error.config;
  let response = error.response;
  return `${error.message}\n` + `Request: ${config.method} /${config.url}: ${config.data}\n` + `Response: ${response ? JSON.stringify(response.data) : "n/a"}`;
}

export default function processError(error: Error | AxiosError, dispatch: Dispatch, reducer?: any) {
  // token expired
  if (error.message.includes("401")) {
    dispatch(authActions.logout());
    return;
  }
  if (!axios.isAxiosError(error)) {
    dispatch(
      systemActions.error({
        code: -1,
        message: `Something went wrong - ${error.name}, ${error.message}`,
        fromBackend: false,
      } as AppError)
    );
    reducer && dispatch(reducer.loaded());
    return;
  }

  const response = error.response;
  if (!response) {
    dispatch(
      systemActions.error({
        code: -1,
        message: `Something went wrong - no response. ${inspectError(error)}`,
        fromBackend: false,
      } as AppError)
    );
    reducer && dispatch(reducer.loaded());
    return;
  }

  const data: Object = response.data as Object;

  if (response.status === 500) {
    dispatch(
      systemActions.error({
        code: -1,
        message: `Something went wrong - status ${response.status}. ${inspectError(error)}`,
        fromBackend: false,
      } as AppError)
    );
    reducer && dispatch(reducer.loaded());
    return;
  }

  if (!data) {
    dispatch(
      systemActions.error({
        code: -1,
        message: `Invalid response - no data. ${inspectError(error)}`,
        fromBackend: false,
      } as AppError)
    );
    reducer && dispatch(reducer.loaded());
    return;
  }

  let errorBody: ErrorBody = (data as ErrorResponseBody).error;
  if (!errorBody) {
    const aspErrorsBody = data as AspErrorResponseBody;
    if (!aspErrorsBody) {
      dispatch(
        systemActions.error({
          code: -1,
          message: `Invalid response. ${inspectError(error)}}`,
          fromBackend: false,
        } as AppError)
      );
      reducer && dispatch(reducer.loaded());
      return;
    }
    errorBody = {
      code: response.status,
      message: Object.values(aspErrorsBody.errors).join("\n"),
    };
  }

  if (errorBody.code === 403) {
    // todo: add warning message, maybe also change title
    dispatch(
      systemActions.error({
        code: -1,
        message: `You are not allowed. Please contact your administrator if you need access`,
        fromBackend: false,
      } as AppError)
    );
    reducer && dispatch(reducer.loaded());
    return;
  }

  if (reducer) {
    dispatch(
      systemActions.error({
        code: -1,
        message: `Something went wrong - no reducer. ${inspectError(error)}`,
        fromBackend: true,
      } as AppError)
    );
  } else {
    dispatch(reducer.error({ ...errorBody, fromBackend: true }));
  }
}

export function isNotFoundError(error: any): boolean {
  return !!(axios.isAxiosError(error) && error.response && error.response.status === 404);
}

export function isPermissionError(error: any): boolean {
  return !!(axios.isAxiosError(error) && error.response && error.response.status === 403);
}
