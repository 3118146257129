/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Empty, Image, Skeleton, Table } from "antd";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { RootState } from "../../store";
import { getCleaningEpisodeImage } from "../../store/historyActions";
import { useState } from "react";

ChartJS.register(ArcElement, Tooltip, Legend);

type Row = {
  id: string;
  startTimestamp: string;
  endTimestamp: string;
  totalTime: number;
  cleanedOverlappingArea: number;
  heatmapBase64: string;
  visible: boolean;
};
type DataSource = Row[];

const CleaningEpisodes = () => {
  const dispatch = useAppDispatch();
  const episodes = useAppSelector((state: RootState) => state.deviceHistory.cleaningEpisodes);
  const loading = useAppSelector((state: RootState) => state.deviceHistory.loadingEpisode);
  const [detailedImageVisible, setDetailedImageVisible] = useState(false);
  const [detailedImageBase64, setDetailedImageBase64] = useState("");
  const hasEpisodes = () => {
    return episodes !== undefined && episodes.length !== 0;
  };

  let dataSource: DataSource = [];
  if (!loading && episodes) {
    dataSource = episodes.map((ep) => {
      return {
        key: ep.id,
        id: ep.id,
        cleanedOverlappingArea: ep.cleanedAreaOverlapping,
        drivenDistance: ep.drivenDistance,
        heatmapBase64: ep.heatmapBase64,
        endTimestamp: ep.endTimestamp,
        startTimestamp: ep.startTimestamp,
        totalTime: ep.totalTime,
        visible: false,
      };
    });
  }

  const setImageVisible = (id: string, visible: boolean) => {
    var ep = episodes.find((x) => x.id === id);
    if (ep !== undefined) {
      setDetailedImageVisible(true);
      if (ep.heatmapBase64 === undefined || ep.heatmapBase64 === "") {
        dispatch(
          getCleaningEpisodeImage(ep.id, (base64) => {
            setDetailedImageBase64(base64);
          })
        );
      } else {
        setDetailedImageBase64(ep.heatmapBase64);
      }
    }
  };

  function buildColumns() {
    return [
      {
        title: "Start Time",
        dataIndex: "startTimestamp",
        key: "startTimestamp",
        render: (time: string) => <div className="PreWrap">{dayjs(`${time}`).format("DD.MM.YYYY HH:mm")}</div>,
      },
      {
        title: "End Time",
        dataIndex: "endTimestamp",
        key: "endTimestamp",
        render: (time: string) => <div className="PreWrap">{dayjs(`${time}`).format("DD.MM.YYYY HH:mm")}</div>,
      },
      {
        title: "Clean Time (HH:MM)",
        dataIndex: "totalTime",
        key: "totalTime",
        render: (time: number) => <div className="PreWrap">{secondsToHoursMinutes(time / 1000)}</div>,
      },
      {
        title: (
          <div>
            Cleaned area (m<sup>2</sup>)
          </div>
        ),
        dataIndex: "cleanedOverlappingArea",
        key: "cleanedOverlappingArea",
        render: (area: number) => <div className="PreWrap">{area.toFixed(2)}</div>,
      },
      {
        title: "Image",
        dataIndex: "heatmapBase64",
        key: "heatmapBase64",
        render: (image: string, row: Row) => (
          <div>
            <Button type="primary" onClick={() => setImageVisible(row.id, true)}>
              View
            </Button>
          </div>
        ),
      },
    ];
  }
  const columns = buildColumns();

  function secondsToHoursMinutes(seconds: number): string {
    if (isNaN(seconds) || seconds < 0) {
      throw new Error("Invalid input. Seconds must be a non-negative number.");
    }

    const hours = Math.floor(seconds / 3600); // 3600 seconds in an hour (60 seconds/minute * 60 minutes/hour)
    const remainingSeconds = seconds % 3600;
    const minutes = Math.floor(remainingSeconds / 60);
    const remainingMinutes = remainingSeconds % 60;

    const HH = hours.toString().padStart(2, "0");
    const MM = minutes.toString().padStart(2, "0");

    return `${HH}:${MM}`;
  }

  return (
    <>
      {hasEpisodes() && (
        <>
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            locale={{
              emptyText: loading ? <Skeleton active={true} /> : <Empty />,
            }}
          />
          {detailedImageBase64 === "" && (
            <Image
              width={200}
              style={{ display: "none" }}
              preview={{
                visible: detailedImageVisible,
                src: "/img/loading.gif",
                onVisibleChange: (value) => {
                  if (!value) {
                    setDetailedImageVisible(false);
                  }
                },
              }}
            />
          )}
          {detailedImageBase64 !== "" && (
            <Image
              width={200}
              style={{ display: "none" }}
              preview={{
                visible: detailedImageVisible,
                src: `data:image/png;base64, ${detailedImageBase64}`,
                onVisibleChange: (value) => {
                  if (!value) {
                    setDetailedImageVisible(false);
                    setDetailedImageBase64("");
                  }
                },
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default CleaningEpisodes;
