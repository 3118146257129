import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppError, Device, DeviceState, Pagination } from "./models";

type DevicesState = {
  loading: boolean;
  error?: AppError;
  list?: Device[];
  pagination: Pagination;
  item?: Device;
  states?: DeviceState[];
};

const initialState: DevicesState = {
  loading: false,
  error: undefined,
  list: undefined,
  item: undefined,
  states: undefined,
  pagination: { index: 0, total: 0 },
};

const devicesSlice = createSlice({
  name: "devices",
  initialState,
  reducers: {
    loading(state) {
      state.loading = true;
    },
    loaded(state) {
      state.loading = false;
      state.error = undefined;
    },
    error(state, action: PayloadAction<AppError>) {
      state.loading = false;
      state.error = action.payload;
    },
    index(state, action: PayloadAction<{ devices: Device[]; pagination: Pagination }>) {
      state.list = action.payload.devices;
      state.pagination = action.payload.pagination;
    },
    states(state, action: PayloadAction<{ states: DeviceState[] }>) {
      state.states = action.payload.states;
    },

    view(state, action: PayloadAction<Device>) {
      state.item = action.payload;
    },
  },
});

export const devicesActions = devicesSlice.actions;
export default devicesSlice;
