import { Dispatch } from "redux";
import { selectionActions } from "./selectionSlice";
import { Facility, Organization, Floor, Space, Map } from "./models";
import {
  lcCurrentFacilityId,
  lcCurrentOrganizationId,
  lcCurrentFloorId,
  lcRemoveCurrentFacilityId,
  lcRemoveCurrentOrganizationId,
  lcRemoveCurrentFloorId,
  lcRemoveCurrentSpaceId,
  lcSetCurrentFacilityId,
  lcSetCurrentOrganizationId,
  lcSetCurrentFloorId,
  lcSetCurrentSpaceId,
  lcCurrentSpaceId,
  lcSetCurrentMapId,
} from "../lib/storage";
import { getOrganization } from "./api/organization";
import { getFacility } from "./api/facility";
import processError, { isNotFoundError, isPermissionError } from "./processError";
import { getFloor } from "./api/floor";
import { getSpace } from "./api/space";

export const selectOrganization = (organization: Organization) => (dispatch: Dispatch) => selectOrganizationAction(dispatch, organization);

function selectOrganizationAction(dispatch: Dispatch, organization: Organization) {
  lcSetCurrentOrganizationId(organization.id);
  lcRemoveCurrentFacilityId();
  lcRemoveCurrentFloorId();
  lcRemoveCurrentSpaceId();
  dispatch(selectionActions.selectOrganization(organization));
}

export const selectOrganizationId = (id: string, onComplete?: (organization: Organization) => void) => async (dispatch: Dispatch) => {
  const organization = await getOrganization(id);
  selectOrganizationAction(dispatch, organization);
  onComplete && onComplete(organization);
};

export const reloadCurrent = (onComplete?: (organization?: Organization, facility?: Facility) => void) => async (dispatch: Dispatch) => {
  const organizationId = lcCurrentOrganizationId();
  const facilityId = lcCurrentFacilityId();
  const floorId = lcCurrentFloorId();
  const spaceId = lcCurrentSpaceId();
  try {
    const promises = [];
    if (organizationId) {
      promises.push(getOrganization(organizationId));
      if (facilityId) {
        promises.push(getFacility(facilityId));
      }
      if (floorId) {
        promises.push(getFloor(floorId));
        if (spaceId) {
          promises.push(getSpace(spaceId));
        }
      }
    }
    const [organization, facility, floor, space] = await Promise.all(promises);

    if (organization) {
      selectOrganizationAction(dispatch, organization);
    } else if (organizationId) {
      clearAction(dispatch);
    }

    if (facility) {
      selectFacilityAction(dispatch, facility as Facility);
    } else if (facilityId) {
      clearFacilityAction(dispatch);
    }

    if (floor) {
      selectFloorAction(dispatch, floor as Floor);
    } else if (floorId) {
      clearFloorAction(dispatch);
    }

    if (space) {
      selectSpaceAction(dispatch, space as Space);
    } else if (spaceId) {
      clearFloorAction(dispatch);
    }

    onComplete && onComplete(organization, facility as Facility);
  } catch (error: any) {
    if (isPermissionError(error) || isNotFoundError(error)) {
      clearAction(dispatch);
      onComplete && onComplete(undefined, undefined);
      return;
    }
    processError(error, dispatch);
  }
};

export const selectFacility = (facility: Facility) => (dispatch: Dispatch) => selectFacilityAction(dispatch, facility);

function selectFacilityAction(dispatch: Dispatch, facility: Facility) {
  lcSetCurrentFacilityId(facility.id);
  lcRemoveCurrentFloorId();
  lcRemoveCurrentSpaceId();
  dispatch(selectionActions.selectFacility(facility));
}

export const selectFacilityId = (id: string) => async (dispatch: Dispatch) => {
  const facility = await getFacility(id);
  lcSetCurrentFacilityId(facility.id);
  dispatch(selectionActions.selectFacility(facility));
};

export const selectFloor = (floor: Floor) => (dispatch: Dispatch) => selectFloorAction(dispatch, floor);

function selectFloorAction(dispatch: Dispatch, floor: Floor) {
  lcSetCurrentFloorId(floor.id);
  lcRemoveCurrentSpaceId();
  dispatch(selectionActions.selectFloor(floor));
}

export const selectFloorId = (id: string) => async (dispatch: Dispatch) => {
  const floor = await getFloor(id);
  lcSetCurrentFloorId(floor.id);
  dispatch(selectionActions.selectFloor(floor));
};

export const selectSpace = (space: Space) => (dispatch: Dispatch) => selectSpaceAction(dispatch, space);

function selectSpaceAction(dispatch: Dispatch, space: Space) {
  lcSetCurrentSpaceId(space.id);
  dispatch(selectionActions.selectSpace(space));
}

export const selectSpaceId = (id: string) => async (dispatch: Dispatch) => {
  const space = await getSpace(id);
  lcSetCurrentSpaceId(space.id);
  dispatch(selectionActions.selectSpace(space));
};

export const selectMap = (map: Map) => (dispatch: Dispatch) => selectMapAction(dispatch, map);

function selectMapAction(dispatch: Dispatch, map: Map) {
  lcSetCurrentMapId(map.id);
  dispatch(selectionActions.selectMap(map));
}

export const clear = () => (dispatch: Dispatch) => clearAction(dispatch);

function clearAction(dispatch: Dispatch) {
  lcRemoveCurrentOrganizationId();
  lcRemoveCurrentFacilityId();
  lcRemoveCurrentFloorId();
  dispatch(selectionActions.clearOrganization());
}

function clearFacilityAction(dispatch: Dispatch) {
  lcRemoveCurrentFacilityId();
  dispatch(selectionActions.clearFacility());
}

function clearFloorAction(dispatch: Dispatch) {
  lcRemoveCurrentFloorId();
  dispatch(selectionActions.clearFloor());
}

function clearSpaceAction(dispatch: Dispatch) {
  lcRemoveCurrentSpaceId();
  dispatch(selectionActions.clearSpace());
}
