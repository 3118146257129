import { DatePicker, Form, Input, Select } from "antd";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { save } from "../../store/devicesActions";
import { RootState } from "../../store";
import { notifySuccess } from "../ui/notifications";
import ModelForm from "../ui/ModelForm";
import { Device } from "../../store/models";
import moment from "moment";
import { Store } from "rc-field-form/lib/interface";
import { deviceName } from "../../helpers/formatters";

const { Option } = Select;

type DeviceData = {
  id?: string;
  alias: string;
  firmwareVersion: string;
  softwareVersion: string;
  machineId: string;
  organizationId?: string;
  facilityId?: string;
};

const DeviceForm = ({ device }: { device?: Device }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading = useAppSelector((state: RootState) => state.devices.loading);
  const error = useAppSelector((state: RootState) => state.devices.error);

  const onFinishHandler = (data: DeviceData) => {
    const action = device?.id ? "updated" : "created";
    dispatch(
      save(data, device?.id, (device) => {
        navigate(`/devices/${device.id}`);
        notifySuccess(`Device ${deviceName(device)} is ${action}`);
      })
    );
  };

  const isNew = !device?.id;
  const initialValues: Store = { ...device };
  if (device?.bootstrappingTime) {
    initialValues.bootstrappingTime = moment(device.bootstrappingTime);
  }
  return (
    <ModelForm isNew={isNew} loading={loading} initialValues={initialValues} onFinish={onFinishHandler} error={error}>
      {isNew && (
        <Form.Item name="id" label="ID">
          <Input />
        </Form.Item>
      )}
      <Form.Item name="alias" label="Alias">
        <Input />
      </Form.Item>
      <Form.Item name="firmwareVersion" label="Firmware version">
        <Input />
      </Form.Item>
      <Form.Item name="softwareVersion" label="Software version">
        <Input />
      </Form.Item>
      <Form.Item name="machineId" label="Machine ID">
        <Input />
      </Form.Item>
      <Form.Item name="organizationId" label="Organization ID">
        <Input />
      </Form.Item>
      <Form.Item name="facilityId" label="Facility ID">
        <Input />
      </Form.Item>
    </ModelForm>
  );
};

export default DeviceForm;
