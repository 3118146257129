import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { RootState, AppDispatch } from "../../store";
import { view, destroy } from "../../store/mapsActions";
import { index, destroy as destroyFile, download } from "../../store/mapFilesActions";
import { Descriptions, PageHeader, Skeleton, Table, Empty, Space, Spin, Progress } from "antd";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { notifySuccess } from "../../components/ui/notifications";
import MultiLineText from "../../components/ui/MultiLineText";
import { Map, MapType, MapFile, MapFileType } from "../../store/models";
import AuthorizePage from "../../components/authorize/AuthorizePage";
import ShowPageError, { isPageError } from "../../components/ShowPageError";
import ModelDescriptions from "../../components/ui/ModelDescriptions";
import DeleteButton from "../../components/buttons/DeleteButton";
import CreateButton from "../../components/buttons/CreateButton";
import UpdateButton from "../../components/buttons/UpdateButton";
import DownloadButton from "../../components/buttons/DownloadButton";
import SpaceLink from "../../components/links/SpaceLink";
import {  selectMap } from "../../store/selectionActions";
import { isSystemAdmin } from "../../lib/permissions";


const DeleteMapButton = ({ map }: { map: Map }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentFacility = useAppSelector((state: RootState) => state.selection.currentFacility);
  const confirm = () => {
    dispatch(
      destroy(map.id, () => {
        navigate("/Maps");
        notifySuccess(`Map ${map.name} is deleted`);
      })
    );
  };
  return <DeleteButton subject="Map" parentId={currentFacility?.id} onConfirm={confirm} subjectName={map.name} />;
};

const DeleteMapFileButton = ({ file, onSuccess }: { file: MapFile; onSuccess?: () => void }) => {
  const dispatch = useAppDispatch();
  const currentFacility = useAppSelector((state: RootState) => state.selection.currentFacility);
  const confirm = () => {
    dispatch(
      destroyFile(file.mapId, file.id, () => {
        notifySuccess(`File  is deleted`);
        onSuccess && onSuccess();
      })
    );
  };
  return <DeleteButton subject="File" parentId={currentFacility?.id} onConfirm={confirm} subjectName={""} />;
};

const FilesIndex = ({ map }: { map: Map }) => {
  type Row = { id: string; subId?: string; mapId: string; type: MapFileType; extension: string; size: number};
  type DataSource = Row[];
  const PAGE_SIZE = 10;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const files = useAppSelector((state: RootState) => state.mapFiles.list);
  const pagination = useAppSelector((state: RootState) => state.mapFiles.pagination);
  const loading = useAppSelector((state: RootState) => state.mapFiles.loading);
  const [page, setPage] = useState(Number(searchParams.get("page")) || 1);
  const [downloadingFileId, setDownloadingFileId] = useState("")
  const [progress, setProgress]= useState(0)
  let dataSource: DataSource = [];
  if (!loading && files) {
    dataSource = files.map((file) => {
      return { key: file.id, id: file.id, subId: file.subId, mapId: file.mapId, type: file.type, extension: file.extension, size: file.size ,downloading:false};
    });
  }

  useEffect(() => {
    if (map) {
      dispatch(index(page - 1, PAGE_SIZE, map.id));
    }
  }, [page, map, dispatch]);

  const pageChangeHandler = (page: number) => {
    setPage(page);
    setSearchParams({ page: page.toString() });
  };

  const getTypeName = (type: MapFileType) => {
    switch (type) {
      case MapFileType.Image:
        return "Image";
      case MapFileType.Ivisio:
        return "Ivisio";
      case MapFileType.Kudan:
        return "Kudan";
      case MapFileType.Yaml:
        return "Yaml";
      case MapFileType.Pgm:
        return "PGM";
      case MapFileType.Cartographer:
        return "Cartographer";
      default:
        return "Unknown";
    }
  };

  const downloadMapFile = (selectedFile: MapFile) => {
    setDownloadingFileId(selectedFile.id)
    dispatch(download(selectedFile.id, map.id, (file) => {
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = fileURL;
      link.setAttribute('download', `${map.name}.${selectedFile.extension}`); 
      document.body.appendChild(link);
      link.click();
      // @ts-ignore
      link.parentNode.removeChild(link);
      setDownloadingFileId("")
      setProgress(0)
    }, (progress => {
      setProgress(progress)
    })))
  }

  type DownloadSectionProps = {
    file:MapFile
  }
  const DownloadSection = ({file}:DownloadSectionProps) => {
    
    if (downloadingFileId === file.id && (progress === 0 || progress === 100)) {
      return <Spin/>
    }
    else if (downloadingFileId === file.id && progress !== 0) {
      return <Progress  percent={progress}/>
    }
    else {
      return <DownloadButton key="r"  subject="File" disabled={downloadingFileId===""?undefined:true} subjectId={map.id} parentId={map?.spaceId} onClick={() => {
        downloadMapFile(file)
      }} />
    }
  }

  function buildColumns(dispatch: AppDispatch) {
    return [
      {
        title: "Id",
        dataIndex: "id",
        key: "id",
        render: (id: string) => <div className="PreWrap">{id}</div>,
      },
      {
        title: "Sub Id",
        dataIndex: "subId",
        key: "subId",
        render: (id: string) => <div className="PreWrap">{id}</div>,
      },
      {
        title: "Extension",
        dataIndex: "extension",
        key: "extension",
        render: (extension: string) => <div className="PreWrap">{extension}</div>,
      },
      {
        title: "Size (kb)",
        dataIndex: "size",
        key: "size",
        render: (size: number) => <div className="PreWrap">{size}</div>,
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        render: (type: MapFileType) => <div className="PreWrap">{getTypeName(type)}</div>,
      },
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        render: (_: any, file: Row) => (
          <Space size="middle">
            <DownloadSection file={file}/>
            
            <UpdateButton key="u" to={`/maps/${map.id}/files/${file.id}/edit`} subject="File" subjectId={map.id} parentId={map?.spaceId} subjectName={""} />

            <DeleteMapFileButton
              file={file}
              onSuccess={() => {
                navigate(`maps`)
              }}
            />
          </Space>
        ),
      },
    ];
  }

  const columns = buildColumns(dispatch);

  return (
    <>
      <PageHeader title={`Files (${pagination.total})`} extra={[<CreateButton key="c" to={`/maps/${map.id}/files/new`} subject="Map" parentId={map.id} />]} />

      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={{
          current: page,
          total: pagination.total,
          defaultPageSize: PAGE_SIZE,
          onChange: pageChangeHandler,
        }}
        locale={{ emptyText: loading ? <Skeleton active={true} /> : <Empty /> }}
      />
    </>
  );
};

const MapsView = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const map = useAppSelector((state: RootState) => state.maps.item);
  const error = useAppSelector((state: RootState) => state.maps.error);
  const loading = useAppSelector((state: RootState) => state.maps.loading) || !map;

  useEffect(() => {
    dispatch(view(id!, { include: "spaces" }));
  }, [id, dispatch]);

  useEffect(() => {
    if (map !== undefined) {
      dispatch(selectMap(map));
    }
  }, [map]);
  if (isPageError(error)) {
    return <ShowPageError error={error!} />;
  }

  const getTypeName = (type: MapType | undefined) => {
    if (type === undefined) {
      return "Unknown";
    }
    switch (type) {
      case MapType.Ivisio:
        return "Ivisio";
      case MapType.Kudan:
        return "Kudan";
      case MapType.Cartographer:
          return "Cartographer";
      default:
        return "Unknown";
    }
  };

  return (
    <>
      <PageHeader
        title={`Map ${!loading && map ? map.name : ""}`}
        onBack={() => navigate("/maps")}
        extra={
          map
            ? [<UpdateButton key="u" subject="Map" subjectId={map.id} parentId={map?.spaceId} subjectName={map.name} />, <DeleteMapButton key="d" map={map} />]
            : []
        }
      />
      {loading ? (
        <Skeleton active={true} />
      ) : (
        <>
          <ModelDescriptions title="Details">
            <Descriptions.Item label="Name">{map!.name}</Descriptions.Item>
            <Descriptions.Item label="Space">
              <SpaceLink model={map} />
            </Descriptions.Item>
            <Descriptions.Item label="Type">
              <MultiLineText text={getTypeName(map?.type)} />
            </Descriptions.Item>
          </ModelDescriptions>
          {isSystemAdmin() && <FilesIndex key="files" map={map} />}
        </>
      )}
    </>
  );
};

export default () => {
  const { id } = useParams();
  const currentFacility = useAppSelector((state: RootState) => state.selection.currentFacility);
  return (
    <AuthorizePage subject="Map" subjectId={id} parentId={currentFacility?.id} action="read">
      <MapsView />
    </AuthorizePage>
  );
};
