import {Component, ReactNode, ErrorInfo} from 'react'
import Dev from "./development/Dev";

type Props = {
  children: ReactNode
}

type State = {
  hasError: boolean
  error?: Error,
  errorInfo?: ErrorInfo
}

class AppErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {hasError: false, error: undefined, errorInfo: undefined}
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({hasError: true, error: error, errorInfo: errorInfo})
  }

  render() {
    const {props, state} = this;
    const {error, errorInfo} = state;

    if (!state.hasError) {
      return props.children
    }

    return <>
      <h1>Page Couldn't Load</h1>
      <p>Something went wrong</p>
      <Dev>
        <h2>Stack</h2>
        <pre>{error!.stack}</pre>
        <h2>Component Stack</h2>
        <pre>{errorInfo!.componentStack}</pre>
      </Dev>
    </>
  }
}

export default AppErrorBoundary
