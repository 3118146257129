import {PageHeader} from "antd";
import UserForm from "../../components/users/UserForm";
import {useNavigate} from "react-router-dom";
import AuthorizePage from "../../components/authorize/AuthorizePage";

const UsersNew = () => {
  const navigate = useNavigate();

  return <>
    <PageHeader title="New User" onBack={() => navigate('/users')}/>
    <UserForm/>
  </>;
}

export default () => {
  return <AuthorizePage subject='User' action='create'>
    <UsersNew/>
  </AuthorizePage>
}
