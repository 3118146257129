import { Link } from "react-router-dom";
import { Map } from "../../store/models";

type ModelWithMap = {
  currentMapId?: string;
  currentMap?: Map;
};

const FloorLink = ({ model, withModelName }: { model: ModelWithMap; withModelName?: boolean }) => {
  if (!model.currentMapId) {
    return null;
  }
  return (
    <Link to={`/maps/${model.currentMapId}`}>
      {withModelName ? "Map " : ""}
      {model.currentMap?.name ?? model.currentMapId}
    </Link>
  );
};

export default FloorLink;
