import {isDevelopment} from "../../development/development";
import './Dev.less';

type Props = {
  children: JSX.Element[] | JSX.Element
  forIcon?: boolean
}

const Dev = ({children, forIcon} : Props) => {
  if (!isDevelopment()) {
    return null;
  }

  return <div className={`Dev ${forIcon ? "Dev-withIcon" : ""}`}>
    {children}
  </div>
}

export default Dev;
