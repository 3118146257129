import { Form, Input } from "antd";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { save } from "../../store/floorsActions";
import { RootState } from "../../store";
import { notifySuccess } from "../ui/notifications";
import ModelForm from "../ui/ModelForm";
import { Floor } from "../../store/models";

type FloorData = {
  name: string;
  notes: string;
};

const FloorForm = ({ floor }: { floor?: Floor }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading = useAppSelector((state: RootState) => state.floors.loading);
  const error = useAppSelector((state: RootState) => state.floors.error);
  const facilityId = useAppSelector((state: RootState) => state.selection.currentFacility!.id);

  const onFinishHandler = (data: FloorData) => {
    const action = floor?.id ? "updated" : "created";
    dispatch(
      save({ ...data, id: floor?.id, facilityId }, (floor) => {
        navigate(`/floors/${floor.id}`);
        notifySuccess(`Floor ${floor!.name} is ${action}`);
      })
    );
  };

  const newFloor = !floor?.id;
  return (
    <ModelForm isNew={newFloor} loading={loading} initialValues={{ ...floor }} onFinish={onFinishHandler} error={error}>
      <Form.Item name="name" label="Name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="notes" label="Notes" rules={[{ required: true }]}>
        <Input.TextArea />
      </Form.Item>
    </ModelForm>
  );
};

export default FloorForm;
