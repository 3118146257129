import { PageHeader } from "antd";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks";
import { RootState } from "../../store";
import AuthorizePage from "../../components/authorize/AuthorizePage";
import FloorNotSelected from "../../components/FloorNotSelected";
import SpaceForm from "../../components/spaces/SpaceForm";

const SpacesNew = () => {
  const navigate = useNavigate();
  const currentFloor = useAppSelector((state: RootState) => state.selection.currentFloor);

  if (!currentFloor) {
    return <FloorNotSelected />;
  }

  return (
    <>
      <PageHeader title="New Space" onBack={() => navigate("/spaces")} />
      <SpaceForm />
    </>
  );
};

export default () => {
  const currentFacility = useAppSelector((state: RootState) => state.selection.currentFacility);
  return (
    <AuthorizePage subject="Space" parentId={currentFacility?.id} action="create">
      <SpacesNew />
    </AuthorizePage>
  );
};
