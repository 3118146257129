import {QuestionCircleOutlined} from "@ant-design/icons";
import {Button, Popconfirm} from "antd";

type Props = {
  title: string
  prompt: string
  onConfirm: (e?: React.MouseEvent<HTMLElement>) => void
  icon?: JSX.Element
  danger?: boolean
}
const Confirmation = ({title, prompt, onConfirm, icon, danger}: Props) => {
  const popconfirmStyle: { color?: string } = {};
  if (danger) {
    popconfirmStyle.color = "red"
  }

  return <Popconfirm title={prompt} icon={<QuestionCircleOutlined style={popconfirmStyle}/>}
                     onConfirm={onConfirm}
                     okText="Yes" okButtonProps={{danger: danger}} cancelText="No"
  >
    <Button danger={danger} shape="circle" icon={icon} title={title}/>
  </Popconfirm>
}

export default Confirmation;
