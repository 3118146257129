import AuthorizeButton from "../authorize/AuthorizeButton";
import {Subjects} from "../../lib/permissions";
import {humanizeSubject} from "../../helpers/formatters";
import { DownloadOutlined  } from "@ant-design/icons";
type Props = {
  subject: Subjects
  subjectId?: string
  parentId?: string
  icon?: JSX.Element
  title?: string
  plural?: boolean
  disabled?: boolean
  onClick?: React.MouseEventHandler<HTMLElement>
}
const DownloadButton = ({subject, subjectId, parentId, title, icon, plural,onClick,disabled}: Props) => {
  title = `Download ${humanizeSubject(subject, {plural})}`

  return (
    <AuthorizeButton action='read' subject={subject} disabled={disabled} subjectId={subjectId} parentId={parentId} 
                     icon={<DownloadOutlined />} title={title} onClick={onClick}/>
  )
}

export default DownloadButton;
