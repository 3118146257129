import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { RootState } from "../../store";
import { view, destroy } from "../../store/spacesActions";
import { Descriptions, PageHeader, Skeleton } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { notifySuccess } from "../../components/ui/notifications";
import MultiLineText from "../../components/ui/MultiLineText";
import { Space } from "../../store/models";
import AuthorizePage from "../../components/authorize/AuthorizePage";
import ShowPageError, { isPageError } from "../../components/ShowPageError";
import ModelDescriptions from "../../components/ui/ModelDescriptions";
import DeleteButton from "../../components/buttons/DeleteButton";
import UpdateButton from "../../components/buttons/UpdateButton";
import FloorLink from "../../components/links/FloorLink";
import MapLink from "../../components/links/MapLink";

const DeleteSpaceButton = ({ space }: { space: Space }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentFacility = useAppSelector((state: RootState) => state.selection.currentFacility);
  const confirm = () => {
    dispatch(
      destroy(space.id, () => {
        navigate("/spaces");
        notifySuccess(`Space ${space.name} is deleted`);
      })
    );
  };
  return <DeleteButton subject="Space" parentId={currentFacility?.id} onConfirm={confirm} subjectName={space.name} />;
};

const SpacesView = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const space = useAppSelector((state: RootState) => state.spaces.item);
  const error = useAppSelector((state: RootState) => state.spaces.error);
  const loading = useAppSelector((state: RootState) => state.spaces.loading) || !space;
  const currentFacility = useAppSelector((state: RootState) => state.selection.currentFacility);
  useEffect(() => {
    dispatch(view(id!, { include: "floors,maps" }));
  }, [id, dispatch]);

  if (isPageError(error)) {
    return <ShowPageError error={error!} />;
  }

  return (
    <>
      <PageHeader
        title={`Space ${!loading && space ? space.name : ""}`}
        onBack={() => navigate("/spaces")}
        extra={
          space
            ? [
                <UpdateButton key="u" subject="Space" subjectId={space.id} parentId={currentFacility?.id} subjectName={space.name} />,
                <DeleteSpaceButton key="d" space={space} />,
              ]
            : []
        }
      />
      {loading ? (
        <Skeleton active={true} />
      ) : (
        <ModelDescriptions title="Details">
          <Descriptions.Item label="Name">{space!.name}</Descriptions.Item>
          <Descriptions.Item label="Floor">
            <FloorLink model={space} />
          </Descriptions.Item>
          <Descriptions.Item label="Map">
            <MapLink model={space!} />
          </Descriptions.Item>
          <Descriptions.Item label="Notes">
            <MultiLineText text={space!.notes} />
          </Descriptions.Item>
        </ModelDescriptions>
      )}
    </>
  );
};

export default () => {
  const { id } = useParams();
  const currentFacility = useAppSelector((state: RootState) => state.selection.currentFacility);
  return (
    <AuthorizePage subject="Floor" subjectId={id} parentId={currentFacility?.id} action="read">
      <SpacesView />
    </AuthorizePage>
  );
};
