import {Link} from "react-router-dom";
import {Machine} from "../../store/models";

type ModelWithMachine = {
  machineId?: string
  machine?: Machine
}

const MachineLink = ({model}: { model: ModelWithMachine }) => {
  if (!model.machineId) {
    return null
  }
  let manufacturer = model.machine?.manufacturer;
  return <Link to={`/machines/${model.machineId}`}>
    {model.machine?.model ?? model.machineId}
    {manufacturer && ` - ${manufacturer}`}
  </Link>
}

export default MachineLink;
