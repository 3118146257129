import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {RootState} from "../../store";
import {view, destroy} from '../../store/facilitiesActions';
import {Descriptions, PageHeader, Skeleton} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {notifySuccess} from "../../components/ui/notifications";
import MultiLineText from "../../components/ui/MultiLineText";
import {Facility} from "../../store/models";
import AuthorizePage from "../../components/authorize/AuthorizePage";
import ShowPageError, {isPageError} from "../../components/ShowPageError";
import ModelDescriptions from "../../components/ui/ModelDescriptions";
import OrganizationLink from "../../components/links/OrganizationLink";
import DeleteButton from "../../components/buttons/DeleteButton";
import UpdateButton from "../../components/buttons/UpdateButton";

const DeleteFacilityButton = ({facility}: { facility: Facility }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentOrganization = useAppSelector((state: RootState) => state.selection.currentOrganization);
  const confirm = () => {
    dispatch(destroy(facility.id, () => {
      navigate('/facilities');
      notifySuccess(`Facility ${facility.name} is deleted`);
    }));
  };
  return <DeleteButton subject="Facility" parentId={currentOrganization?.id} onConfirm={confirm}
                       subjectName={facility.name}/>
}

const FacilitiesView = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {id} = useParams();
  const facility = useAppSelector((state: RootState) => state.facilities.item);
  const error = useAppSelector((state: RootState) => state.facilities.error);
  const loading = useAppSelector((state: RootState) => state.facilities.loading) || !facility;

  useEffect(() => {
    dispatch(view(id!, {include: 'organizations'}));
  }, [id, dispatch]);

  if (isPageError(error)) {
    return <ShowPageError error={error!}/>
  }

  return <>
    <PageHeader title={`Facility ${!loading && facility ? facility.name : ''}`}
                onBack={() => navigate('/facilities')}
                extra={facility ? [
                  <UpdateButton key="u" subject="Facility" subjectId={facility.id} parentId={facility.organizationId}
                                subjectName={facility.name}/>,
                  <DeleteFacilityButton key="d" facility={facility}/>
                ] : []}
    />
    {loading ?
      <Skeleton active={true}/> :
      <ModelDescriptions title="Details">
        <Descriptions.Item label="Name">{facility!.name}</Descriptions.Item>
        <Descriptions.Item label="Organization">
          <OrganizationLink model={facility}/>
        </Descriptions.Item>
        <Descriptions.Item label="Notes">
          <MultiLineText text={facility!.notes}/>
        </Descriptions.Item>
      </ModelDescriptions>
    }
  </>
}

export default () => {
  const {id} = useParams();
  const currentOrganization = useAppSelector((state: RootState) => state.selection.currentOrganization);
  return <AuthorizePage subject='Facility' subjectId={id} parentId={currentOrganization?.id} action='read'>
    <FacilitiesView/>
  </AuthorizePage>
}
