import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppError} from "./models";


type meState = {
  loading: boolean
  error?: AppError
}

const initialState: meState = {
  loading: false,
  error: undefined,
}

const meSlice = createSlice({
  name: 'me',
  initialState,
  reducers: {
    loading(state) {
      state.loading = true;
    },
    loaded(state) {
      state.loading = false;
      state.error = undefined;
    },
    error(state, action: PayloadAction<AppError>) {
      state.loading = false;
      state.error = action.payload;
    },
  }
});

export const meActions = meSlice.actions;
export default meSlice;
