import {useEffect, useState} from "react";
import {useParams, useSearchParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {RootState} from "../../../store";
import {index, destroy} from '../../../store/deviceCommandsActions';
import {Empty, PageHeader, Skeleton, Space, Table} from "antd";
import {useNavigate} from "react-router-dom";
import AuthorizePage from "../../../components/authorize/AuthorizePage";
import {view} from "../../../store/devicesActions";
import ShowPageError, {isPageError} from "../../../components/ShowPageError";
import moment from "moment";
import {Device, DeviceCommand} from "../../../store/models";
import {notifySuccess} from "../../../components/ui/notifications";
import CreateButton from "../../../components/buttons/CreateButton";
import DeleteButton from "../../../components/buttons/DeleteButton";

type Row = DeviceCommand
type DataSource = Row[]

const PAGE_SIZE = 10;

function buildColumns(device: Device, page: number) {
  return [
    {
      title: 'Name', dataIndex: 'name', key: 'name', width: 200
    },
    {
      title: 'Added', dataIndex: 'addedTime', key: 'addedTime',
      render: (text: string) => text ? moment(text).toString() : 'n/a',
    },
    {
      title: 'Sent', dataIndex: 'sentTime', key: 'sentTime',
      render: (text: string) => text ? moment(text).toString() : 'n/a',
    },
    {
      title: 'Confirmed', dataIndex: 'confirmedTime', key: 'confirmedTime',
      render: (text: string) => text ? moment(text).toString() : 'n/a',
    },
    {
      title: 'Parameters',
      dataIndex: 'parameters',
      key: 'parameters',
      render: (parameters: { [name: string]: any }) => (
        <code className="PreWrap">
          {JSON.stringify(parameters)}
        </code>
      ),
    },
    {
      title: 'Response', dataIndex: 'responseCode', key: 'responseCode', width: 200
    },
    {
      title: 'Message', dataIndex: 'responseMessage', key: 'responseMessage', width: 200
    },
    {
      title: 'Actions', key: 'action', width: 100,
      render: (_: any, deviceCommand: Row) => (
        <Space size="middle">
          <DeleteCommandButton deviceCommand={deviceCommand} device={device} page={page}/>
        </Space>
      ),
    },
  ];
}

const DeleteCommandButton = ({deviceCommand, device, page}: {
  deviceCommand: DeviceCommand, device: Device, page: number
}) => {
  const dispatch = useAppDispatch();
  const confirm = () => {
    dispatch(destroy(deviceCommand.id, () => {
      notifySuccess(`Device command ${deviceCommand.name} is deleted`);
      dispatch(index(page - 1, PAGE_SIZE, device.id));
    }));
  };
  return <DeleteButton subject="DeviceCommand" parentId={device.organizationId}
                       onConfirm={confirm} subjectName={deviceCommand.name}/>
}

const DeviceCommandsContent = () => {
  const {deviceId} = useParams()
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const device = useAppSelector((state: RootState) => state.devices.item);
  const deviceCommands = useAppSelector((state: RootState) => state.deviceCommands.list);
  const pagination = useAppSelector((state: RootState) => state.deviceCommands.pagination);
  const loading = useAppSelector((state: RootState) => state.deviceCommands.loading);
  const [page, setPage] = useState(Number(searchParams.get('page')) || 1)

  useEffect(() => {
    if (deviceId) {
      dispatch(index(page - 1, PAGE_SIZE, deviceId));
    }
  }, [page, deviceId, dispatch]);

  let dataSource: DataSource = [];
  if (!loading && deviceCommands) {
    dataSource = deviceCommands.map((command: DeviceCommand) => {
      return {key: command.id, ...command}
    });
  }

  const pageChangeHandler = (page: number) => {
    setPage(page);
    setSearchParams({page: page.toString()})
  }

  const columns = buildColumns(device!, page);

  return <>
    <PageHeader title={`Device commands for ${deviceId}`}
                onBack={() => navigate(`/devices/${deviceId}`)}
                extra={[
                  <CreateButton key='c' subject="DeviceCommand" parentId={device?.organizationId}/>,
                ]}
    />
    <Table dataSource={dataSource} columns={columns}
           pagination={{
             current: page,
             total: pagination.total,
             defaultPageSize: PAGE_SIZE,
             onChange: pageChangeHandler,
           }}
           locale={{emptyText: loading ? <Skeleton active={true}/> : <Empty/>}}

    />
  </>
}

const DeviceCommandsIndex = () => {
  const dispatch = useAppDispatch();
  const {deviceId} = useParams();
  const device = useAppSelector((state: RootState) => state.devices.item);
  const loading = useAppSelector((state: RootState) => state.devices.loading) || !device;
  const error = useAppSelector((state: RootState) => state.devices.error);

  useEffect(() => {
    dispatch(view(deviceId!));
  }, [deviceId, dispatch]);

  if (isPageError(error)) {
    return <ShowPageError error={error!}/>
  }

  return <AuthorizePage subject='DeviceCommand' parentId={device?.organizationId} action='read' loading={loading}>
    <DeviceCommandsContent/>
  </AuthorizePage>
}

export default DeviceCommandsIndex;
