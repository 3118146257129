import { useState } from "react";
import { PageHeader, Skeleton } from "antd";
import { useEffect } from "react";
import { view } from "../../store/devicesActions";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../store";
import AuthorizePage from "../../components/authorize/AuthorizePage";
import ShowPageError, { isPageError } from "../../components/ShowPageError";
import DeviceLinkForm from "../../components/devices/DeviceLinkForm";
import { isSystemAdmin } from "../../lib/permissions";
import OrganizationNotSelected from "../../components/OrganizationNotSelected";
import { deviceName } from "../../helpers/formatters";

const DevicesLinkContent = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const device = useAppSelector((state: RootState) => state.devices.item);
  const error = useAppSelector((state: RootState) => state.devices.error);
  const [loaded, setLoaded] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    dispatch(view(id!, {}, () => setLoaded(true)));
  }, [id, dispatch]);

  if (isPageError(error)) {
    return <ShowPageError error={error!} />;
  }

  return (
    <>
      <PageHeader title={`Link Device ${deviceName(device)}`} onBack={() => navigate(`/devices/${device?.id}`)} />
      {loaded && device ? <DeviceLinkForm device={device} /> : <Skeleton active={true} />}
    </>
  );
};

const DeviceLink = () => {
  const currentOrganization = useAppSelector((state: RootState) => state.selection.currentOrganization);
  if (!isSystemAdmin() && !currentOrganization) {
    return <OrganizationNotSelected />;
  }

  return (
    <AuthorizePage subject="Device" parentId={currentOrganization?.id} action="link">
      <DevicesLinkContent />
    </AuthorizePage>
  );
};

export default DeviceLink;
