import AuthorizeButton from "../authorize/AuthorizeButton";
import {PlusOutlined} from "@ant-design/icons";
import {Subjects} from "../../lib/permissions";
import {humanizeSubject} from "../../helpers/formatters";
import {useNavigate} from "react-router-dom";

type Props = {
  to?: string
  subject: Subjects
  parentId?: string
  icon?: JSX.Element,
  title?: string
}
const CreateButton = ({to, subject, parentId, title, icon}: Props) => {
  const navigate = useNavigate()
  title ||= `Add new ${humanizeSubject(subject)}`
  return (
    <AuthorizeButton action="create" subject={subject} parentId={parentId}
                     onClick={() => navigate(to ?? 'new')}
                     icon={icon ?? <PlusOutlined/>} title={title}/>
  )
}

export default CreateButton;
